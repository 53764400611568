import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { URLPreview } from '..'

import { UrlPreviewSchema } from './schemas'

const knownErrors = ['URL_FORBIDDEN_TO_PREVIEW', 'URL_RETURNED_ERROR_CODE_FOR_PREVIEW'] as const

const fetchUrlPreview = ({ url }: { url: string }): Promise<URLPreview> =>
	axiosInstance
		.get<JsonApiResponseSingle>(`/url-preview/get?url=${url}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return UrlPreviewSchema.parse(result.data)
		})

export const useUrlPreview = (
	url: string,
	options: Omit<
		UseQueryOptions<URLPreview, ParsedError<(typeof knownErrors)[number]>, URLPreview>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		urlPreviewQuery: useQuery<URLPreview, ParsedError<(typeof knownErrors)[number]>, URLPreview>(
			['url-preview', url],
			() => fetchUrlPreview({ url }),
			options,
		),
	}
}
