import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import type { Moment } from 'moment'

import { Skeleton } from '@prostpost/uikit'
import { notReachable } from '@prostpost/utils'

import { useFeed } from 'app/domains/Post/api'
import { usePostsStore } from 'app/domains/Post/store'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import type { DraftScheduled } from 'app/domains/Draft'
import type { FeedPost } from 'app/domains/Post'

import { ErrorWidget, NoPostsPlaceholder } from '../../components'

type Msg = { type: 'on_open_editor'; channelName: string }

type Props = {
	range?: [Moment, Moment?]
	children: (args: { feed: DraftScheduled[] }) => React.ReactNode
	onMsg: (msg: Msg) => void
}

export const DataLoader = observer(function DataLoader({ range, children, onMsg }: Props) {
	const postsStore = usePostsStore()
	const channels = useChannelsStore()
	const { preferences } = useOnboardedUserStore()

	const { feedQuery } = useFeed(
		undefined,
		{
			range,
			pageParam: undefined,
			// TODO: Proper infinite loading
			// Problem is that we have a scrollable content within the same scroller that has a list which
			// is not supported by virtualized solutions like react-virtual so here we load just 100 posts
			// that should be more than enough for scheduled posts, if we have problems in future we can extend that
			// limit or change UI so that scroller has only list inside so we can migrate to InfiniteScroll
			perPage: 100,
			showHiddenPosts: preferences.feed.isHiddenPostsVisible,
			showOnlySupportedPostTypes: !preferences.feed.isUnsupportedPostsShown,
		},
		{ enabled: channels.activeList.length > 0 },
	)

	useEffect(() => {
		switch (feedQuery.status) {
			case 'idle':
			case 'loading':
			case 'error':
				postsStore.setPosts([])
				break
			case 'success':
				postsStore.setPosts(feedQuery.data.pages.flatMap(page => page.data))
				break
			default:
				notReachable(feedQuery)
		}
	}, [feedQuery.status])

	// Do not proceed if user has no active channels
	if (channels.activeList.length === 0) {
		return <NoPostsPlaceholder type="MULTI_CHANNEL" onMsg={onMsg} />
	}

	switch (feedQuery.status) {
		case 'idle':
		case 'loading':
			return <Skeleton.List variant="loading" />
		case 'error':
			switch (feedQuery.error.code) {
				case 'UNKNOWN_ERROR':
				case 'NOT_VALID_ERROR':
					return <ErrorWidget error={feedQuery.error} onClickTryAgain={feedQuery.refetch} />
				case 'UNABLE_TO_GET_FEED':
					return (
						<ErrorWidget
							type="KNOWN_EXPECTED"
							error={feedQuery.error}
							onClickTryAgain={feedQuery.refetch}
						/>
					)
				default:
					return notReachable(feedQuery.error.code)
			}
		case 'success': {
			if (!postsStore.posts.length) {
				return <NoPostsPlaceholder type="MULTI_CHANNEL" onMsg={onMsg} />
			}

			const posts = children({
				feed: postsStore.posts.filter((p: FeedPost): p is DraftScheduled => p.type === 'SCHEDULED'),
			})

			return <>{posts}</>
		}
		default:
			return notReachable(feedQuery)
	}
})
