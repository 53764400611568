import { memo } from 'react'
import styled from '@emotion/styled'

import { VStack, HStack, Paragraph, Image } from '@prostpost/uikit'
import { truncate, notReachable, useOpenWindow } from '@prostpost/utils'

import { useUrlPreview } from 'app/domains/Media/api'

import { AttachmentContainer } from '../../components'

import { Skeleton, SkeletonError, Thumbnail } from './components'

type Props = {
	url: string
} & (
	| {
			isRemovable: true
			onClickRemove: () => void
	  }
	| {
			isRemovable: false
			onClickRemove?: never
	  }
)

const arePropsEqual = (prevProps: Props, nextProps: Props) => prevProps.url === nextProps.url

const LinkTitle = styled(Paragraph)`
	display: -webkit-box;

	font-weight: 500;
	cursor: pointer;

	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;

	&:hover {
		text-decoration: underline;
	}
`

export const LinkPreview = memo(function LinkPreview({ url, ...props }: Props) {
	const openInNewWindow = useOpenWindow()
	const { urlPreviewQuery } = useUrlPreview(url)

	switch (urlPreviewQuery.status) {
		case 'idle':
		case 'loading':
			return (
				<AttachmentContainer {...props}>
					<Skeleton />
				</AttachmentContainer>
			)
		case 'error':
			return (
				<AttachmentContainer {...props}>
					<SkeletonError />
				</AttachmentContainer>
			)
		case 'success': {
			const { image, url, site, title, desc, favicon } = urlPreviewQuery.data
			const siteName = site || url.replace(/(^\w+:|^)\/\//, '').split('/')[0] || ''
			return (
				<AttachmentContainer {...props}>
					<HStack space={3} w="100%" align="center">
						<Thumbnail image={image} />
						<VStack w="95%">
							<HStack space={1} mb={2} align="center">
								{favicon ? <Image src={favicon} width="16px" alt={siteName} /> : null}
								<Paragraph size={14} lines={1} color="blue_40">
									{truncate(url, 50)}
								</Paragraph>
							</HStack>
							<LinkTitle size={14} lines={1} color="black_80" onClick={() => openInNewWindow(url)}>
								{truncate(title || '', 65)}
							</LinkTitle>
							<Paragraph size={12} lines={1} color="blue_40">
								{truncate(desc || '', 125)}
							</Paragraph>
						</VStack>
					</HStack>
				</AttachmentContainer>
			)
		}

		default:
			return notReachable(urlPreviewQuery)
	}
}, arePropsEqual)
