import z from 'zod'
import { useInfiniteQuery } from 'react-query'
import type { Moment } from 'moment'
import type { AxiosError } from 'axios'

import { datetime, notReachable } from '@prostpost/utils'
import { parseFirstError, parseList, stringifyQueryParams } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseList, ParsedError, ListParams } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { DraftScheduledSchema } from 'app/domains/Draft/api/schemas'
import { PostHistorySchema, PostInternalSchema } from 'app/domains/Post/api/schemas'
import type { FeedPost } from 'app/domains/Post'
import type { DraftScheduled } from 'app/domains/Draft'

export const knownErrors = ['UNABLE_TO_GET_FEED'] as const

type Params = ListParams & {
	perPage: number
	showHiddenPosts: boolean
	showOnlySupportedPostTypes: boolean
	pageParam?: string // offsetDate
	range?: [Moment, Moment?]
}

export const FeedSchema: z.ZodSchema<FeedPost, z.ZodTypeDef, unknown> = z.union([
	PostHistorySchema,
	DraftScheduledSchema,
	PostInternalSchema,
])

type Result = {
	data: FeedPost[] | DraftScheduled[]
}

// we need to pass "page" as a last standalone parameter to make infinite loading work
// https://github.com/TanStack/query/issues/307

async function fetchFeed(channelName: string, params: Params): Promise<{ data: FeedPost[] }>
async function fetchFeed(channelName: false, params: Params): Promise<{ data: DraftScheduled[] }>

// eslint-disable-next-line jsdoc/require-jsdoc
async function fetchFeed(channelName: string | false, params: Params): Promise<Result> {
	const startDate = params.range?.[0] ? datetime(params.range?.[0], true, { to: 'utc', time: 'dayStart' }) : undefined
	const parameters: Record<string, unknown> = {
		expired: true, // we want to show scheduled posts that passed publish date and failed to publish
		supported: params.showOnlySupportedPostTypes,
		hidden: params.showHiddenPosts,
		limit: params.perPage,
		offsetDate: params.pageParam,
		endDate: params.range?.[1] ? datetime(params.range?.[1], true, { to: 'utc', time: 'dayEnd' }) : startDate,
		startDate,
		channelName: channelName || undefined,
	}

	try {
		const response = await axiosInstance.get<JsonApiResponseList>(
			`/drafts/feed?${stringifyQueryParams(parameters)}`,
		)

		// With channel context
		if (channelName) {
			const result = parseList(response.data)
			return {
				data: (result.data || []).map(post => FeedSchema.parse(post)),
			}
		}

		// No channel context
		const result = parseList(response.data)
		return {
			data: (result.data || []).map(post => DraftScheduledSchema.parse(post)),
		}
	} catch (e) {
		console.error(e)
		throw parseFirstError(e as AxiosError<JsonApiErrorResponse>, knownErrors)
	}
}

type QueryOptions = { enabled?: boolean }
type R<T> = {
	feedQuery: ReturnType<
		typeof useInfiniteQuery<{ data: T[] }, ParsedError<(typeof knownErrors)[number]>, { data: T[] }>
	>
}

export function useFeed(channelName: string, params: Params, options?: QueryOptions): R<FeedPost>
export function useFeed(channelName: undefined, params: Params, options?: QueryOptions): R<DraftScheduled>

// eslint-disable-next-line jsdoc/require-jsdoc
export function useFeed(channelName: string | undefined, params: Params, options = {}) {
	const queryKeyCommonPart = [params.hidden, params.supported, params.range]

	if (channelName) {
		return {
			feedQuery: useInfiniteQuery<
				{ data: FeedPost[] },
				ParsedError<(typeof knownErrors)[number]>,
				{ data: FeedPost[] }
			>({
				queryKey: ['feed', channelName, ...queryKeyCommonPart],

				queryFn: ({ pageParam }: { pageParam?: string }) => {
					const paramsWithPage = { ...params, pageParam: pageParam || params.pageParam }
					return fetchFeed(channelName, paramsWithPage)
				},

				getNextPageParam: lastResult => {
					if (lastResult.data.length === 0) return undefined
					const lastPost = lastResult.data[lastResult.data.length - 1]
					switch (lastPost.type) {
						case 'SCHEDULED':
							return lastPost.publishAt
						case 'INTERNAL':
						case 'HISTORY':
						case 'INTERNAL_PUBLISHING':
							return lastPost.publishedAt
						default:
							return notReachable(lastPost)
					}
				},
				...options,
			}),
		}
	}

	return {
		feedQuery: useInfiniteQuery<
			{ data: DraftScheduled[] },
			ParsedError<(typeof knownErrors)[number]>,
			{ data: DraftScheduled[] }
		>({
			queryKey: ['feed', ...queryKeyCommonPart],

			queryFn: ({ pageParam }: { pageParam?: string }) => {
				const paramsWithPage = { ...params, pageParam: pageParam || params.pageParam }
				return fetchFeed(false, paramsWithPage)
			},

			getNextPageParam: lastResult => {
				if (lastResult.data.length === 0) return undefined
				const lastPost = lastResult.data[lastResult.data.length - 1]
				return lastPost.publishAt
			},
			...options,
		}),
	}
}
