import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import * as Icons from 'react-feather'

import { Text, Button, PricingPlanTile } from '@prostpost/uikit'

import { useCurrency } from 'app/domains/PricingPlan/hooks'
import type { ChannelActive } from 'app/domains/Channel'
import type { PricingPlanLimited } from 'app/domains/PricingPlan'

export const ProPlanTile = observer(
	({ plan, channels, isActive }: { plan: PricingPlanLimited; channels: ChannelActive[]; isActive: boolean }) => {
		const { t } = useTranslation()
		// Probably in future we will support more currencies and they will be added to BE plans
		// but now it's only USD now
		const { sign: currencySign } = useCurrency('USD')

		return (
			<PricingPlanTile w="100%" variant={isActive ? 'primary' : 'secondary'}>
				<PricingPlanTile.Title>{t('userSettings.plans.proTitle2', 'Pro Creator')}</PricingPlanTile.Title>
				<PricingPlanTile.Price
					period={t('userSettings.plans.periodMonth', 'month')}
					currencySign={currencySign}
					value={plan.price}
					hint={
						plan.limits.memberPrice
							? t('userSettings.plans.proHint', '+ ${{count}} per user', {
									count: plan.limits.memberPrice,
								})
							: undefined
					}
				/>
				<PricingPlanTile.Features>
					<PricingPlanTile.Feature
						variant="iconed"
						icon={Icons.Check}
						iconColor={channels.length === plan.limits.channels ? 'green_100' : 'blue_40'}
					>
						{t('userSettings.plans.channelFeature2', 'Up to {{count}} channels', {
							count: plan.limits.channels,
						})}
					</PricingPlanTile.Feature>
					<PricingPlanTile.Feature variant="iconed" icon={Icons.Calendar} iconColor="blue_40">
						{t('userSettings.plans.unlimitedPostsFeature', 'Unlimited posts')}
					</PricingPlanTile.Feature>
					<PricingPlanTile.Feature variant="iconed" icon={Icons.User} iconColor="blue_40">
						{t('userSettings.plans.teamFeature', '{{members}}/{{count}} Team members', {
							members: 0, // TODO: Set counter when members introduced
							count: plan.limits.members,
						})}
					</PricingPlanTile.Feature>
				</PricingPlanTile.Features>
				<PricingPlanTile.Side>
					{isActive ? (
						<Text size={14} color="black_80">
							{t('userSettings.plans.current', 'Current')}
						</Text>
					) : (
						<Button.Action
							variant="primary"
							size="small"
							onClick={() => console.log('Upgrade plan clicked')}
						>
							{t('userSettings.plans.upgradeAction', 'Upgrade')}
						</Button.Action>
					)}
				</PricingPlanTile.Side>
			</PricingPlanTile>
		)
	},
)
