import { observer } from 'mobx-react-lite'

import { notReachable } from '@prostpost/utils'

import { config } from 'app/config'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useBotStore } from 'app/domains/Bot/store'

import { BotNotConnected } from './BotNotConnected'
import { ProcessingImages } from './ProcessingImages'

const useBanners = () => {
	const botStore = useBotStore()
	const { preferences } = useOnboardedUserStore()
	if (!botStore.link) return 'BOT_NOT_CONNECTED'
	if (
		preferences.feedHistoryImagesNotLoaded &&
		config.localStorage.getItem('imagesProcessingWarning') === 'visible'
	) {
		return 'PROCESSING_IMAGES'
	}
	return 'NO_BANNER'
}

export const Banners = observer(function Banners() {
	const banner = useBanners()
	switch (banner) {
		case 'BOT_NOT_CONNECTED':
			return <BotNotConnected />
		case 'PROCESSING_IMAGES':
			return <ProcessingImages onClose={() => config.localStorage.setItem('imagesProcessingWarning', 'hidden')} />
		case 'NO_BANNER':
			return null
		default:
			notReachable(banner)
	}
})
