import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import type { Editor } from '@tiptap/core'

import { ListItem, ListContainer } from './components'

export type Props = {
	query: string
	items: string[]
	editor: Editor
	command: (opt: { id: string; confirmKey?: 'ENTER' | 'SPACE' }) => void
}

export const RecentTagsDropdown = forwardRef<unknown, Props>(function Tags(props, ref) {
	const [selectedIndex, setSelectedIndex] = useState(0)

	const selectItem = (index: number) => {
		const item = props.items[index]
		if (item) {
			props.command({ id: item })
		}
	}

	useEffect(() => setSelectedIndex(0), [props.items])

	useImperativeHandle(ref, () => ({
		onKeyDown: ({ event }: { event: KeyboardEvent }) => {
			if (event.key === 'ArrowUp') {
				setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
				return true
			}

			if (event.key === 'ArrowDown') {
				setSelectedIndex((selectedIndex + 1) % props.items.length)
				return true
			}

			if (event.key === 'Enter') {
				if (selectedIndex != null) {
					selectItem(selectedIndex)
				} else {
					// create tag mark for unknown (new) tag on Enter click if no existing tag is selected
					props.command({ id: props.query, confirmKey: 'ENTER' })
					props.editor.commands.enter()
				}
				return true
			}

			// create tag mark for unknown (new) tag on Space click
			if (event.key === ' ') {
				props.command({ id: props.query, confirmKey: 'SPACE' })
				return true
			}

			return false
		},
	}))

	if (!props.items.length) return null

	return (
		<ListContainer>
			{props.items.map((item, index) => (
				<ListItem key={item} isSelected={selectedIndex === index} onClick={() => selectItem(index)}>
					{`#${item}`}
				</ListItem>
			))}
		</ListContainer>
	)
})
