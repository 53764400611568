import styled from '@emotion/styled'

import { Layout } from '@prostpost/uikit'
import { getSpace } from '@prostpost/css'

export const Container = styled(Layout.Content)`
	display: flex;
	justify-content: center;

	width: 100%;
	max-width: 100%;

	> div {
		max-width: 100%;
	}
`

export const Content = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	padding: 29px 0 ${getSpace(3)} 0;
`
