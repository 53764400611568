import { useTranslation } from 'react-i18next'
import { Observer } from 'mobx-react-lite'

import { notReachable } from '@prostpost/utils'
import { Flex, Group } from '@prostpost/uikit'

import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { usePricingPlans } from 'app/domains/PricingPlan/api'
import { ErrorWidget } from 'app/domains/Error/components'

import { BloggerPlanTile, ProPlanTile, CustomPlanTile, PlansLoading } from './components'

export const PricingPlansTilesGroup = () => {
	const { t } = useTranslation()

	const channels = useChannelsStore()
	const { preferences } = useOnboardedUserStore()
	const { pricingPlansQuery } = usePricingPlans()

	switch (pricingPlansQuery.status) {
		case 'idle':
		case 'loading':
			return <PlansLoading />

		case 'success': {
			const { data } = pricingPlansQuery
			return (
				<Observer>
					{() => (
						<>
							<Group.Item>
								<Group.ItemContent>
									<BloggerPlanTile
										plan={data.STARTER}
										channels={channels.activeList}
										isActive={preferences.plan.name === 'STARTER'}
									/>
								</Group.ItemContent>
							</Group.Item>
							<Group.Item>
								<Group.ItemContent>
									<ProPlanTile
										plan={data.PRO}
										channels={channels.activeList}
										isActive={preferences.plan.name === 'PRO'}
									/>
								</Group.ItemContent>
							</Group.Item>
							<Group.Item>
								<Group.ItemContent>
									<CustomPlanTile
										isActive={preferences.plan.name === 'CUSTOM'}
										isPrimaryAction={preferences.plan.name === 'PRO'}
									/>
								</Group.ItemContent>
							</Group.Item>
						</>
					)}
				</Observer>
			)
		}

		case 'error':
			return (
				// height: <height of plan tile> * <count of plans> + <margin between tiles> * <count of plans - 1>
				<Flex align="center" just="center" w="100%" h={`${145 * 3 + 16 * 2}px`}>
					<ErrorWidget
						size="mid"
						error={pricingPlansQuery.error}
						summary={t('userSettings.plans.fetchFailed', 'Unable to load pricing plans')}
						onTryAgain={pricingPlansQuery.refetch}
					/>
				</Flex>
			)

		default:
			return notReachable(pricingPlansQuery)
	}
}
