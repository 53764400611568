import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { ContextMenu } from '@prostpost/uikit'

export type Msg = { type: 'on_click_edit' }

type Props = {
	onMsg: (msg: Msg) => void
}

export const Edit = ({ onMsg }: Props) => {
	const { t } = useTranslation()

	return (
		<ContextMenu.Item icon={Icons.Edit2} onClick={() => onMsg({ type: 'on_click_edit' })}>
			{t('labels.edit', 'Edit')}
		</ContextMenu.Item>
	)
}
