import { notReachable } from '@prostpost/utils'

import { LinkBotToUserModal } from './Modal'
import { LinkBotToUserPlain } from './Plain'
import { LinkBotToUserTile } from './Tile'
import type { Interface as InterfaceTile, Msg as MsgTile } from './Tile'
import type { Interface as InterfacePlain, Msg as MsgPlain } from './Plain'
import type { Interface as InterfaceModal, Msg as MsgModal } from './Modal'

type Props =
	| ({ variant: 'tile' } & InterfaceTile & { onMsg: (msg: MsgTile) => void })
	| ({ variant: 'plain' } & InterfacePlain & { onMsg: (msg: MsgPlain) => void })
	| ({ variant: 'modal' } & InterfaceModal & { onMsg: (msg: MsgModal) => void })

export const LinkBotToUserVariants = (props: Props) => {
	switch (props.variant) {
		case 'tile':
			return <LinkBotToUserTile {...props} />
		case 'plain':
			return <LinkBotToUserPlain {...props} />
		case 'modal':
			return <LinkBotToUserModal {...props} />
		default:
			return notReachable(props)
	}
}
