import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseList, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseList } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { PricingPlansList } from '..'

import { PricingPlanSchema } from './schemas'

const knownErrors = ['UNABLE_TO_GET_PLANS'] as const

const fetchPricingPlans = (): Promise<PricingPlansList> =>
	axiosInstance
		.get<JsonApiResponseList>('/auth/plans')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseList(response.data)
			return (result.data || [])
				.map(ch => PricingPlanSchema.parse(ch))
				.reduce((result, curr) => ({ [curr.name]: curr, ...result }), {} as PricingPlansList)
		})

export const usePricingPlans = () => {
	return {
		pricingPlansQuery: useQuery<PricingPlansList, ParsedError<(typeof knownErrors)[number]>, PricingPlansList>(
			'pricing_plans',
			fetchPricingPlans,
		),
	}
}
