import { z } from 'zod'

import type {
	PostInternal,
	PostHistory,
	PostHistoryForwardedFrom,
	PostHistoryAudioMeta,
	PostHistoryDocumentMeta,
	PostHistoryGalleryMeta,
	PostHistoryImageMeta,
	PostHistoryLocationMeta,
	PostHistoryPollMeta,
	PostHistoryStickerMeta,
	PostHistoryTextMeta,
	PostHistoryVideoMeta,
	PostHistoryVideoNoteMeta,
	PostHistoryVoiceMeta,
	PostHistoryUnknownMeta,
	PostHistoryMeta,
	PostBackup,
} from '..'

export const PostScheduledBackupSchema: z.ZodSchema<
	Extract<PostBackup, { type: 'SCHEDULED' }>,
	z.ZodTypeDef,
	unknown
> = z
	.object({
		uuid: z.string(),
		userUuid: z.string(),
		draftUuid: z.string(),
		text: z.optional(z.string()),
		mdText: z.optional(z.string()),
		webPage: z.optional(z.string()),
		images: z.optional(z.array(z.string())),
		tags: z.optional(z.array(z.string())),
		createdAt: z.string(),
		updatedAt: z.optional(z.string()),
		ad: z.optional(z.boolean()),
		silent: z.boolean(),
		colorTag: z.optional(z.string()),
		channelUuid: z.string(),
		publishAt: z.string(),
		withGallery: z.optional(z.boolean()),
		externalLink: z.optional(
			z.object({
				url: z.string().url(),
				title: z.optional(z.string()),
			}),
		),
	})
	.transform(response => ({
		type: 'SCHEDULED',
		images: response.images || [],
		tags: response.tags || [],
		ad: response.ad || false,
		withGallery: response.withGallery || false,
		...response,
	}))

export const PostInternalBackupSchema: z.ZodSchema<Extract<PostBackup, { type: 'INTERNAL' }>, z.ZodTypeDef, unknown> = z
	.object({
		uuid: z.string(),
		userUuid: z.string(),
		draftUuid: z.string(),
		text: z.optional(z.string()),
		mdText: z.optional(z.string()),
		webPage: z.optional(z.string()),
		images: z.optional(z.array(z.string())),
		tags: z.optional(z.array(z.string())),
		createdAt: z.string(),
		updatedAt: z.optional(z.string()),
		ad: z.optional(z.boolean()),
		silent: z.boolean(),
		colorTag: z.optional(z.string()),
		channelUuid: z.string(),
		publishAt: z.optional(z.string()),
		publishedAt: z.string(),
		withGallery: z.optional(z.boolean()),
		reactions: z.optional(z.record(z.string(), z.number())),
		externalLink: z.optional(
			z.object({
				url: z.string().url(),
				title: z.optional(z.string()),
			}),
		),
	})
	.transform(response => ({
		type: 'INTERNAL',
		images: response.images || [],
		tags: response.tags || [],
		ad: response.ad || false,
		withGallery: response.withGallery || false,
		...response,
	}))

export const PostBackupSchema: z.ZodSchema<PostBackup, z.ZodTypeDef, unknown> = z.union([
	PostInternalBackupSchema,
	PostScheduledBackupSchema,
])

const PostHistoryForwardedFromSchema: z.ZodSchema<PostHistoryForwardedFrom, z.ZodTypeDef, unknown> = z.object({
	uuid: z.string().uuid(),
	username: z.string(),
	isBot: z.boolean(),
	createdAt: z.string(),
	title: z.optional(z.string()),
	firstName: z.optional(z.string()),
	lastName: z.optional(z.string()),
})

const PostHistoryAudioMetaSchema: z.ZodSchema<PostHistoryAudioMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('audio'),
		duration: z.number(),
		fileName: z.string(),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryDocumentMetaSchema: z.ZodSchema<PostHistoryDocumentMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('document'),
		fileName: z.string(),
		fileSize: z.number(),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryGalleryMetaSchema: z.ZodSchema<PostHistoryGalleryMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('gallery'),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryImageMetaSchema: z.ZodSchema<PostHistoryImageMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('image'),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryLocationMetaSchema: z.ZodSchema<PostHistoryLocationMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('location'),
		longitude: z.number(),
		latitude: z.number(),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryPollMetaSchema: z.ZodSchema<PostHistoryPollMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('poll'),
		question: z.string(),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryStickerMetaSchema: z.ZodSchema<PostHistoryStickerMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('sticker'),
		setName: z.string(),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryTextMetaSchema: z.ZodSchema<PostHistoryTextMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('text'),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryVideoMetaSchema: z.ZodSchema<PostHistoryVideoMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('video'),
		duration: z.number(),
		fileName: z.string(),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryVideoNoteMetaSchema: z.ZodSchema<PostHistoryVideoNoteMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('video_note'),
		duration: z.number(),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryVoiceMetaSchema: z.ZodSchema<PostHistoryVoiceMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('voice'),
		duration: z.number(),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryUnknownMetaSchema: z.ZodSchema<PostHistoryUnknownMeta, z.ZodTypeDef, unknown> = z
	.object({
		postType: z.literal('unknown'),
	})
	.transform(({ postType, ...response }) => ({
		type: postType,
		...response,
	}))

const PostHistoryMetaSchema: z.ZodSchema<PostHistoryMeta, z.ZodTypeDef, unknown> = z.union([
	PostHistoryAudioMetaSchema,
	PostHistoryDocumentMetaSchema,
	PostHistoryGalleryMetaSchema,
	PostHistoryImageMetaSchema,
	PostHistoryLocationMetaSchema,
	PostHistoryPollMetaSchema,
	PostHistoryStickerMetaSchema,
	PostHistoryTextMetaSchema,
	PostHistoryVideoMetaSchema,
	PostHistoryVideoNoteMetaSchema,
	PostHistoryVoiceMetaSchema,
	PostHistoryUnknownMetaSchema,
])

export const PostHistorySchema: z.ZodSchema<PostHistory, z.ZodTypeDef, unknown> = z
	.object({
		history: z.literal(true),
		tgId: z.number(),
		uuid: z.string().uuid(),
		text: z.optional(z.string()),
		webPage: z.optional(z.string()),
		images: z.optional(z.array(z.string())),
		imagesToLoad: z.number(),
		tags: z.array(z.string()),
		silent: z.optional(z.boolean()),
		scheduled: z.optional(z.boolean()),
		views: z.optional(z.number()),
		editedAt: z.optional(z.string()),
		publishedAt: z.string(),
		channelUuid: z.string(),
		createdAt: z.string(),
		forwardedFrom: z.optional(PostHistoryForwardedFromSchema),
		reactions: z.optional(z.record(z.string(), z.number())),
		postMeta: z.optional(PostHistoryMetaSchema),
	})
	.transform(({ postMeta, images, silent, scheduled, channelUuid, views, ...response }) => ({
		type: 'HISTORY',
		meta: postMeta,
		views: views || 0,
		images: images || [],
		silent: silent || false,
		scheduled: scheduled || false,
		channelUuid,
		ad: false,
		...response,
	}))

export const PostInternalSchema: z.ZodSchema<PostInternal, z.ZodTypeDef, unknown> = z
	.object({
		history: z.optional(z.literal(false)),
		ad: z.boolean(),
		tgId: z.number(),
		uuid: z.string().uuid(),
		text: z.optional(z.string()),
		webPage: z.optional(z.string()),
		images: z.optional(z.array(z.string())),
		tags: z.array(z.string()),
		silent: z.optional(z.boolean()),
		scheduled: z.optional(z.boolean()),
		views: z.optional(z.number()),
		editedAt: z.optional(z.string()),
		unpublishedVersion: z.optional(z.string()),
		reactions: z.optional(z.record(z.string(), z.number())),
		publishedAt: z.string(),
		channelUuid: z.string(),
		createdAt: z.string(),
	})
	.transform(({ images, silent, scheduled, channelUuid, views, ...response }) => ({
		type: 'INTERNAL',
		history: false,
		views: views || 0,
		images: images || [],
		silent: silent || false,
		scheduled: scheduled || false,
		channelUuid,
		...response,
	}))
