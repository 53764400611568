import { useMutation } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseMutationOptions } from 'react-query'

import { parseFirstError } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

const knownErrors = ['UNABLE_TO_DELETE_DRAFT', 'UNABLE_TO_GET_DRAFT', 'DRAFT_NOT_FOUND'] as const

type Data = {
	uuid: string
}

const deleteBackup = ({ uuid }: Data): Promise<void> =>
	axiosInstance
		.delete<JsonApiResponseSingle>(`/drafts/backup/${uuid}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(() => {
			return
		})

export const useDeleteBackup = (
	options: Omit<
		UseMutationOptions<void, ParsedError<(typeof knownErrors)[number]>, Data>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		deleteBackupMutation: useMutation<void, ParsedError<(typeof knownErrors)[number]>, Data>({
			mutationKey: 'backup',
			mutationFn: ({ uuid }: Data) => deleteBackup({ uuid }),
			...options,
		}),
	}
}
