import { useEffect } from 'react'
import type { Scrollbar } from 'react-scrollbars-custom'

import { notReachable } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'
import { Main, VStack, Box } from '@prostpost/uikit'

import type { ChannelActive } from 'app/domains/Channel'

type Props = {
	isVisible: boolean
	onToggle: (action: 'show' | 'hide' | 'toggle') => void
} & (
	| {
			withScroller: true
			children: (args: { scroller: Scrollbar }) => React.ReactNode | React.ReactNode[] | null
	  }
	| {
			withScroller: false
			children: React.ReactNode | React.ReactNode[] | null
	  }
) &
	(
		| {
				variant: 'CHANNEL'
				channel: ChannelActive
		  }
		| {
				variant: 'NO_CHANNEL'
		  }
	)

export const FloatingAside = ({ isVisible, onToggle, ...props }: Props) => {
	const MEDIA = useCurrentMedia()

	// Hide sidebar by default if resolution changed
	useEffect(() => {
		onToggle('hide')
	}, [MEDIA])

	return (
		<Main.Aside variant="floating" isVisible={isVisible}>
			{props.withScroller ? (
				<Main.AsideContent withScroller>
					{scroller => {
						switch (props.variant) {
							case 'CHANNEL':
								return (
									<Box w="100%" h="100%" pt={4}>
										{props.children({ scroller })}
									</Box>
								)
							case 'NO_CHANNEL':
								return (
									<VStack space={4} pt={4} just="center" w="100%">
										{props.children({ scroller })}
									</VStack>
								)
							default:
								return notReachable(props)
						}
					}}
				</Main.AsideContent>
			) : (
				<Main.AsideContent withScroller={false}>
					{(() => {
						switch (props.variant) {
							case 'CHANNEL':
								return (
									<Box w="100%" h="100%" pt={4}>
										{props.children}
									</Box>
								)
							case 'NO_CHANNEL':
								return (
									<VStack space={4} pt={4} just="center" w="100%">
										{props.children}
									</VStack>
								)
							default:
								return notReachable(props)
						}
					})()}
				</Main.AsideContent>
			)}
		</Main.Aside>
	)
}
