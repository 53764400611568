import { configure } from 'mobx'
import { configureLogger } from 'mobx-log'

// https://mobx.js.org/configuration.html#linting-options
export const init = () =>
	configure({
		enforceActions: 'always',
		computedRequiresReaction: true,
		reactionRequiresObservable: true,
		observableRequiresReaction: true,
		disableErrorBoundaries: false,
	})

// https://github.com/kubk/mobx-log
configureLogger({
	debug: false,
	storeConsoleAccess: false,
	filters: {
		actions: true,
		computeds: true,
		observables: false,
	},
})
