import { notReachable } from '@prostpost/utils'

import { ChannelTemplatesListPopup, SharedTemplatesListPopup } from './Popup'
import type { Interface as InterfaceChannel, Msg as MsgChannel } from './Popup/ChannelTemplates'
import type { Interface as InterfaceShared, Msg as MsgShared } from './Popup/SharedTemplates'

type Props = { variant: 'popup' } & (
	| (InterfaceChannel & { type: 'channel' } & { onMsg: (msg: MsgChannel) => void })
	| (InterfaceShared & { type: 'inbox' } & { onMsg: (msg: MsgShared) => void })
)

export const TemplatesListVariants = (props: Props) => {
	switch (props.variant) {
		case 'popup':
			switch (props.type) {
				case 'inbox':
					return <SharedTemplatesListPopup {...props} />
				case 'channel':
					return <ChannelTemplatesListPopup {...props} />
				default:
					return notReachable(props)
			}
		default:
			return notReachable(props.variant)
	}
}
