import { makeLoggable } from 'mobx-log'
import type { User as SupabaseUser } from '@supabase/supabase-js'

import type { NotOnboardedUser } from 'app/domains/User'
import type { UserPreferences } from 'app/domains/UserPreferences'

import { UserStore } from '../../store'

type InitArgsNotOnboarded = NotOnboardedUser & {
	supabaseUser: SupabaseUser
	preferences: UserPreferences
}

export class NotOnboardedUserStore extends UserStore {
	init(args: InitArgsNotOnboarded) {
		super.init(args)
	}

	constructor(args: InitArgsNotOnboarded) {
		super(args)
		this.init(args)
		makeLoggable(this)
	}

	getUser(): NotOnboardedUser & { supabaseUser: SupabaseUser } {
		return {
			type: 'NOT_ONBOARDED',
			ava: this.ava,
			uuid: this.uuid,
			email: this.email,
			lastName: this.lastName,
			fullName: this.fullName,
			firstName: this.firstName,
			supabaseUser: this.supabaseUser,
			roles: [],
		}
	}
}
