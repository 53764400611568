import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import * as Icons from 'react-feather'

import { Box } from '@prostpost/uikit'
import { getBorderRadius } from '@prostpost/css'
import type { CSSPositionProps } from '@prostpost/css'

type Props = CSSPositionProps & {
	colorTag: string | undefined
}

const Container = styled(Box)<Props>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 20px;
	height: 20px;

	background: ${({ colorTag }) => `${colorTag}30`};
	border-radius: ${getBorderRadius('tiny')};
`

export const ColorTagLabel = (props: Props) => {
	const theme = useTheme()
	const color = props.colorTag || theme.colors.blue_40
	return (
		<Container {...props} colorTag={color}>
			<Icons.MessageSquare size={12} strokeWidth={3} color={color} />
		</Container>
	)
}
