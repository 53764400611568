import { useCurrentMedia, MEDIA } from '@prostpost/css'
import { Skeleton, VStack, HStack, Null } from '@prostpost/uikit'

export const RouteSkeleton = () => {
	const currentMedia = useCurrentMedia() || MEDIA.DESKTOP
	const isTitleVisible = currentMedia !== 'MOBILE'
	return (
		<VStack space={5} pb={7} pt={7} w="100%">
			{isTitleVisible ? <Skeleton height={34} width="180px" /> : <Null />}
			<HStack.Responsive
				space={5}
				w="100%"
				responsive={{ TABLET: { rule: 'full-width' }, MOBILE: { rule: 'full-width' } }}
			>
				<Skeleton height={64} width="30%" />
				{!['TABLET', 'MOBILE'].includes(currentMedia) ? <Skeleton height={64} width="70%" /> : <Null />}
			</HStack.Responsive>
		</VStack>
	)
}
