import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { JsonApiErrorResponse, ParsedError, JsonApiResponseSingle } from '@prostpost/jsonapi'
import { parseFirstError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

const knownErrors = ['UNABLE_TO_DELETE_USER'] as const

const deleteUser = (): Promise<null> =>
	axiosInstance
		.delete<JsonApiResponseSingle>('/auth/me')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(() => null)

export const useDeleteUser = () => {
	return {
		deleteUserMutation: useMutation<null, ParsedError<(typeof knownErrors)[number]>>(deleteUser),
	}
}
