import { observer } from 'mobx-react-lite'
import { runInAction } from 'mobx'

import { notReachable } from '@prostpost/utils'
import { Null, ContextMenu as UIContextMenu } from '@prostpost/uikit'

import { useBotStore } from 'app/domains/Bot/store'
import { useIsAllowedToUnpublishOrEdit } from 'app/domains/Post/hooks'
import type { DraftNotScheduled } from 'app/domains/Draft'
import type { PostInternal } from 'app/domains/Post'

import { ContextMenuWrapper } from '../../../components'

import { Edit } from './Edit'
import { Open } from './Open'
import { Unpublish } from './Unpublish'
import { CopyAsDraft } from './CopyAsDraft'
import { OpenInTelegram } from './OpenInTelegram'
import { UnpublishedVersion } from './UnpublishedVersion'

type Msg =
	| { type: 'on_unpublished' }
	| { type: 'on_click_unpublish' }
	| { type: 'on_unpublish_failed' }
	| { type: 'on_click_edit' }
	| { type: 'on_click_open_modal_editor' }
	| { type: 'on_context_menu_toggle'; isOpen: boolean }
	| { type: 'on_click_load_unpublished_version'; backupUuid: string }
	| { type: 'on_click_open_in_telegram' }
	| { type: 'on_click_copy_as_draft'; draft: DraftNotScheduled }

type Props = {
	post: PostInternal
	onMsg: (msg: Msg) => void
}

export const ContextMenu = observer(function ContextMenu({ post, onMsg }: Props) {
	const canPostBeEdited = useIsAllowedToUnpublishOrEdit(post)
	const botStore = useBotStore()
	return (
		<ContextMenuWrapper iconColor="blue_40" onToggle={isOpen => onMsg({ type: 'on_context_menu_toggle', isOpen })}>
			<Open onMsg={onMsg} />
			{post.unpublishedVersion && canPostBeEdited ? (
				<UnpublishedVersion
					onMsg={msg => {
						switch (msg.type) {
							case 'on_click_load_unpublished_version':
								runInAction(() => {
									onMsg({
										type: 'on_click_load_unpublished_version',
										backupUuid: post.unpublishedVersion as string,
									})
								})
								break
							default:
								notReachable(msg.type)
						}
					}}
				/>
			) : canPostBeEdited ? (
				<Edit isDisabled={!botStore.link} onMsg={onMsg} />
			) : (
				<Null />
			)}

			<CopyAsDraft postUuid={post.uuid} onMsg={onMsg} />

			<UIContextMenu.Divider />
			<OpenInTelegram onMsg={onMsg} />

			{canPostBeEdited ? <Unpublish isDisabled={!botStore.link} post={post} onMsg={onMsg} /> : <Null />}
		</ContextMenuWrapper>
	)
})
