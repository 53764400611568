import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@emotion/react'
import * as Icons from 'react-feather'

import { Box, Skeleton, Flex, Paragraph } from '@prostpost/uikit'
import { notReachable } from '@prostpost/utils'

import { useTemplatesList } from 'app/domains/Template/api'
import { useTemplatesStore } from 'app/domains/Template/store'
import { ErrorWidget } from 'app/domains/Error/components'
import type { TemplateShortInfo } from 'app/domains/Template'

type Props = {
	channelUuid?: string
	children: (args: { firstPrivateIndex: number; data: TemplateShortInfo[] }) => React.ReactNode | React.ReactNode[]
} & (
	| {
			channelUuid?: undefined
			isShowShared?: never
	  }
	| {
			channelUuid: string
			isShowShared: boolean
	  }
)

export const DataLoader = ({ channelUuid, isShowShared, children }: Props) => {
	const { t } = useTranslation()

	const templatesStore = useTemplatesStore()

	const { templatesQuery } = useTemplatesList({
		limit: 100, // do not use pagination and virtual-scroll, we don't expect a lot of templates
		pageParam: 1,
		showShared: isShowShared,
		channelUuid,
	})

	useEffect(() => {
		switch (templatesQuery.status) {
			case 'idle':
			case 'loading':
				templatesStore.setTemplatesList([])
				break
			case 'error':
			case 'success':
				if (!templatesQuery.data) {
					templatesStore.setTemplatesList([])
				} else {
					templatesStore.setTemplatesList(
						templatesQuery.data.pages.reduce(
							(acc: TemplateShortInfo[], curr) => [...acc, ...curr.data],
							[],
						),
					)
				}
				break
			default:
				notReachable(templatesQuery)
		}
	}, [templatesQuery.status])

	switch (templatesQuery.status) {
		case 'idle':
		case 'loading':
			return <Skeleton.List variant="loading" bars={4} height={58} />
		case 'error':
			return (
				<Box w="100%" mt={6}>
					<ErrorWidget
						size="mid"
						type="UNKNOWN"
						error={templatesQuery.error}
						isFullScreen={false}
						title={t('labels.error', 'Error')}
						summary={t('templates.list.error', 'Unable to get templates')}
						onTryAgain={templatesQuery.refetch}
					/>
				</Box>
			)

		case 'success':
			return <TemplatesLoaded>{children}</TemplatesLoaded>

		default:
			return notReachable(templatesQuery)
	}
}

type TemplatesLoadedProps = {
	children: (args: { firstPrivateIndex: number; data: TemplateShortInfo[] }) => React.ReactNode | React.ReactNode[]
}

const TemplatesLoaded = observer(function TemplatesLoaded({ children }: TemplatesLoadedProps) {
	const theme = useTheme()
	const { t } = useTranslation()

	const templatesStore = useTemplatesStore()

	return templatesStore.templates.length === 0 ? (
		<Box w="100%" mt={8}>
			<Flex dir="column" just="center" align="center" w="100%">
				<Icons.FileText size={44} strokeWidth={1.5} color={theme.colors.blue_20} />
				<Paragraph centered mt={4} size={14} color="blue_40">
					{t(
						'content:templates.list.noTemplates',
						'You have no saved templates yet. To add one create a new draft and save it as a template.',
					)}
				</Paragraph>
			</Flex>
		</Box>
	) : (
		<>
			{children({
				data: templatesStore.templates,
				firstPrivateIndex: templatesStore.templates.findIndex(template => !template.channelUuid),
			})}
		</>
	)
})
