import { useMutation } from 'react-query'
import type { UseMutationOptions } from 'react-query'
import type { AxiosError } from 'axios'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { PostBackup } from 'app/domains/Post'

import { PostBackupSchema } from './schemas'

const knownErrors = [
	'UNABLE_TO_CREATE_DRAFT_BACKUP',
	'FORWARDED_POST_CANT_BE_EDITED',
	'DRAFT_IS_INVALID_TO_CREATE_BACKUP',
] as const

type Data = {
	uuid: string
}

const createPostBackup = (uuid: string): Promise<PostBackup> =>
	axiosInstance
		.post<JsonApiResponseSingle>(`/drafts/backup/${uuid}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return PostBackupSchema.parse(result.data)
		})

export const useCreateBackup = (
	options: Omit<
		UseMutationOptions<PostBackup, ParsedError<(typeof knownErrors)[number]>, Data>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		createPostBackupMutation: useMutation<PostBackup, ParsedError<(typeof knownErrors)[number]>, Data>({
			mutationKey: 'backup',
			mutationFn: ({ uuid }: Data) => createPostBackup(uuid),
			...options,
		}),
	}
}
