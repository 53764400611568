import { useState } from 'react'

import type { DraftScheduled } from 'app/domains/Draft'
import type { PostInternal, PostInternalPublishing } from 'app/domains/Post'

type SelectedPost = {
	type: 'INTERNAL' | 'SCHEDULED'
	uuid: string
	mode: 'editing' | 'readonly'
}

const getInternalPostSelectionMode = (post: PostInternal | PostInternalPublishing, selected: SelectedPost | null) => {
	if (!selected) return 'none'
	const { type, uuid, mode } = selected
	return type === 'INTERNAL' && uuid === post.uuid ? mode : 'none'
}

const getScheduledPostSelectionMode = (post: DraftScheduled, selected: SelectedPost | null) => {
	if (!selected) return 'none'
	const { type, uuid, mode } = selected
	return type === 'SCHEDULED' && uuid === post.uuid ? mode : 'none'
}

export const useSelectedPost = () => {
	const [selectedPost, setSelectedPost] = useState<SelectedPost | null>(null)

	return {
		selectedPost,
		setSelectedPost,
		clearSelection: () => setSelectedPost(null),
		getSelectionMode: {
			internal: getInternalPostSelectionMode,
			scheduled: getScheduledPostSelectionMode,
		},
	}
}
