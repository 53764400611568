import { useMutation } from 'react-query'
import type { AxiosError } from 'axios'

import { constant } from '@prostpost/utils'
import { parseFirstError } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

const knownErrors = ['UNABLE_TO_DELETE_CHAT'] as const

const removeBotLink = (): Promise<null> =>
	axiosInstance
		.delete('/bot/link')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(constant(null))

export const useRemoveBotLink = () => {
	return {
		removeBotLinkMutation: useMutation<null, ParsedError<(typeof knownErrors)[number]>, void>(
			'bot_link',
			removeBotLink,
		),
	}
}
