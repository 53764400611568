import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Button, Item, Group } from '@prostpost/uikit'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'

import { useCommonLocalizations } from 'app/shared/hooks'

type Props = {
	onClick: () => void
}

export const ChangeEmailTrigger = ({ onClick }: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()
	const { loadingMessage } = useCommonLocalizations(t)

	const user = useOnboardedUserStore()

	return (
		<Group.Item>
			<Group.ItemContent>
				<Item variant="labeled">
					<Item.Title>{t('userSettings.security.emailLabel', 'Email')}</Item.Title>
					<Item.Description>{user.supabaseUser.email ?? loadingMessage}</Item.Description>
				</Item>
			</Group.ItemContent>
			<Group.ItemSide>
				<Button.Action
					variant="secondary"
					size="mid"
					icon={<Icons.MailCheck size={16} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={onClick}
				>
					{t('userSettings.security.emailUpdateAction', 'Change email')}
				</Button.Action>
			</Group.ItemSide>
		</Group.Item>
	)
}
