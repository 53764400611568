import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { useCurrentMedia } from '@prostpost/css'
import { Stat, HStack, VStack, Heading, Paragraph, Hr } from '@prostpost/uikit'

import { useUserPostsLimit, useUserChannelsLimit, useLimitsResetDate } from 'app/domains/UserPreferences/hooks'

const LimitsTrial = ({ postsLimit }: { postsLimit: number }) => {
	const { t } = useTranslation()
	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'

	const limitsResetDate = useLimitsResetDate()
	const formattedLimitsResetDate = limitsResetDate.format('MMMM D')

	const { postsLimitUsed, postsLimitReached } = useUserPostsLimit()
	const { channelsLimitTotal, channelsLimitUsed, channelsLimitReached } = useUserChannelsLimit()

	return (
		<VStack space={1}>
			<Heading h={5}>{t('userSettings.plans.limitsTitle', 'Current plan usage')}</Heading>
			<Paragraph size={14} color="blue_40">
				{formattedLimitsResetDate
					? t('userSettings.plans.limitsDescTrial', 'Trial period ends on {{formattedLimitsResetDate}}', {
							formattedLimitsResetDate,
						})
					: t('userSettings.plans.limitsDescNoDateTrial', 'You are currently in trial period')}
			</Paragraph>

			<Hr mt={2} mb={5} />

			<HStack space={isMobile ? 6 : 8} mb={2}>
				<Stat variant="dimmed" size="big" useIcon={Icons.Radio}>
					<Stat.Value
						color={channelsLimitReached ? 'red_100' : 'black_80'}
					>{`${channelsLimitUsed} of ${channelsLimitTotal}`}</Stat.Value>
					<Stat.Label>
						{t('userSettings.plans.channelsLimits', 'Active channels', {
							count: channelsLimitTotal || 1,
						})}
					</Stat.Label>
				</Stat>

				<Stat size="big" variant="dimmed" useIcon={Icons.MessageSquare}>
					<Stat.Value color={postsLimitReached ? 'red_100' : 'black_80'}>
						<HStack space={2} align="center">
							{postsLimitUsed} {'of'}
							{` ${postsLimit}`}
						</HStack>
					</Stat.Value>
					<Stat.Label>
						{t('userSettings.plans.postsLimitsTrial', 'Delivered posts (during trial)')}
					</Stat.Label>
				</Stat>
			</HStack>
		</VStack>
	)
}

const LimitsPlan = () => {
	const { t } = useTranslation()
	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'

	const limitsResetDate = useLimitsResetDate()
	const formattedLimitsResetDate = limitsResetDate.format('MMMM D')

	const { postsLimitUsed, postsLimitReached } = useUserPostsLimit()
	const { channelsLimitTotal, channelsLimitUsed, channelsLimitReached } = useUserChannelsLimit()

	return (
		<VStack space={1}>
			<Heading h={5}>{t('userSettings.plans.limitsTitle', 'Current plan usage')}</Heading>
			<Paragraph size={14} color="blue_40">
				{formattedLimitsResetDate
					? t(
							'userSettings.plans.limitsDesc2',
							'Your subscription will be renewed on {{formattedLimitsResetDate}}',
							{ formattedLimitsResetDate },
						)
					: t('userSettings.plans.limitsDescNoDate2', 'You have an active subscription')}
			</Paragraph>

			<Hr mt={2} mb={5} />

			<HStack space={isMobile ? 6 : 8} mb={2}>
				<Stat variant="dimmed" size="big" useIcon={Icons.Radio}>
					<Stat.Value
						color={channelsLimitReached ? 'red_100' : 'black_80'}
					>{`${channelsLimitUsed} of ${channelsLimitTotal}`}</Stat.Value>
					<Stat.Label>
						{t('userSettings.plans.channelsLimits', 'Active channels', {
							count: channelsLimitTotal || 1,
						})}
					</Stat.Label>
				</Stat>

				<Stat size="big" variant="dimmed" useIcon={Icons.MessageSquare}>
					<Stat.Value color={postsLimitReached ? 'red_100' : 'black_80'}>
						<HStack space={2} align="center">
							{postsLimitUsed} {'of'}
							<Icons.Infinity size={24} strokeWidth={2.5} style={{ marginTop: '2px' }} />
						</HStack>
					</Stat.Value>
					<Stat.Label>{t('userSettings.plans.postsLimits', 'Delivered posts (monthly)')}</Stat.Label>
				</Stat>
			</HStack>
		</VStack>
	)
}

export const Limits = {
	Trial: LimitsTrial,
	Plan: LimitsPlan,
}
