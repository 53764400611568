import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { ChannelActive } from '..'

import { ActiveChannelSchema, ActiveChannelMetaSchema } from './schemas'

const knownErrors = [] as const

const fetchActiveChannelByName = (channelName: string): Promise<ChannelActive> =>
	axiosInstance
		.get<JsonApiResponseSingle>(`/drafts/channel/${channelName}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			const meta = ActiveChannelMetaSchema.parse(result.meta)
			const data = ActiveChannelSchema.parse(result.data)
			return {
				...data,
				membersDelta: meta.membersDelta,
			}
		})

export const useActiveChannel = (
	channelName: string,
	options: Omit<UseQueryOptions<ChannelActive, ParsedError<never>, ChannelActive>, 'queryKey' | 'queryFn'> = {},
) => {
	return {
		activeChannelQuery: useQuery<ChannelActive, ParsedError<(typeof knownErrors)[number]>, ChannelActive>(
			['channel', channelName],
			() => fetchActiveChannelByName(channelName),
			{ enabled: !!channelName, ...options },
		),
	}
}
