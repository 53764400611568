import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { isUrl, notReachable } from '@prostpost/utils'
import { HStack, Paragraph, VStack } from '@prostpost/uikit'

import { MEDIA_URL } from 'app/domains/Media/constants'
import { usePostTextPreview } from 'app/domains/Post/hooks'
import type { DraftScheduled } from 'app/domains/Draft'
import type { PostInternal, PostInternalPublishing, PostHistory } from 'app/domains/Post'

import { Images, ImagesNotLoaded, ImagesHistory } from './Images'

type Props = {
	isImageVisible: boolean
	post: DraftScheduled | PostInternal | PostInternalPublishing | PostHistory
}

const getImageUrl = (image: string) => (isUrl(image) ? image : MEDIA_URL(image))
const postHasImages = (post: Props['post']) => {
	switch (post.type) {
		case 'HISTORY':
			return post.imagesToLoad || post.images?.length || 0
		case 'SCHEDULED':
		case 'INTERNAL':
		case 'INTERNAL_PUBLISHING':
			return post.images?.length || 0
		default:
			return notReachable(post)
	}
}

const PostImages = observer(function PostImages({ post }: { post: Props['post'] }) {
	switch (post.type) {
		case 'HISTORY':
			if (post.imagesToLoad > 0 && !post.images?.length) {
				return <ImagesNotLoaded imagesCount={post.imagesToLoad} />
			} else if (post.images?.length && post.imagesToLoad) {
				return <ImagesHistory image={getImageUrl(toJS(post.images)[0])} more={post.imagesToLoad - 1} />
			}
			return null
		case 'SCHEDULED':
		case 'INTERNAL':
		case 'INTERNAL_PUBLISHING':
			return <Images images={toJS(post.images).map(getImageUrl)} />

		default:
			return notReachable(post)
	}
})

export const TextAndImages = observer(function TextAndImages({ post, isImageVisible }: Props) {
	const text = usePostTextPreview(post, { trimMd: true })
	return postHasImages(post) === 1 ? (
		<HStack space={2} mt={2}>
			{isImageVisible ? <PostImages post={post} /> : null}
			{post.text ? (
				<Paragraph breakWords mt={1} size={14} color="blue_40">
					{text}
				</Paragraph>
			) : null}
		</HStack>
	) : (
		<VStack space={2}>
			{post.text ? (
				<Paragraph breakWords mt={1} size={14} color="blue_40">
					{text}
				</Paragraph>
			) : null}

			{postHasImages(post) && isImageVisible ? <PostImages post={post} /> : null}
		</VStack>
	)
})
