import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { useToast } from '@prostpost/toast'
import { ContextMenu } from '@prostpost/uikit'

import { useUnschedulePost } from 'app/domains/Post/api'
import type { DraftScheduled } from 'app/domains/Draft'

export type Msg =
	| { type: 'on_unschedule'; post: DraftScheduled }
	| { type: 'on_click_unschedule'; post: DraftScheduled }

type Props = {
	variant: 'context-menu-item'
	post: DraftScheduled
	onMsg: (msg: Msg) => void
}

const useToasts = () => {
	const { t } = useTranslation()

	const unscheduleSucceed = useToast({
		type: 'success',
		text: t('notify:post.unscheduled.success', 'Post successfully unscheduled'),
	})

	const unscheduleFailed = useToast({
		type: 'error',
		text: t('notify:post.unscheduled.error', 'Failed to unschedule post'),
	})

	return { unscheduleFailed, unscheduleSucceed }
}

export const UnschedulePost = observer(function UnschedulePost({ post, onMsg }: Props) {
	const toasts = useToasts()
	const { t } = useTranslation()

	const { unschedulePostMutation } = useUnschedulePost({ uuid: post.uuid })

	return (
		<ContextMenu.Item
			variant="danger"
			icon={Icons.CornerUpLeft}
			onClick={() => {
				unschedulePostMutation
					// we must use mutateAsync and not useEffect for ContextMenu.Item actions
					// because ContextMenu will be closed and unmount so useEffect won't trigger
					.mutateAsync()
					.then(() => {
						onMsg({ type: 'on_unschedule', post })
						toasts.unscheduleSucceed.show()
						return null
					})
					.catch(error => {
						console.error(error)
						toasts.unscheduleFailed.show()
					})
				onMsg({ type: 'on_click_unschedule', post })
			}}
		>
			{t('labels.unschedule', 'Unschedule')}
		</ContextMenu.Item>
	)
})
