import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import { parseFirstError, parseList, stringifyQueryParams } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseList, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { ChannelNotArchived } from '..'

import { NotArchivedChannelSchema, ActiveChannelsMetaSchema } from './schemas'

const knownErrors = [] as const

const fetchNotArchivedChannels = (): Promise<ChannelNotArchived[]> =>
	axiosInstance
		.get<JsonApiResponseList>(
			`/drafts/channel?${stringifyQueryParams({
				sort: ['createdAt'],
				filter: [{ name: 'archived_at', op: 'isnull', val: null }],
			})}`,
		)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			console.error(e)
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseList(response.data, ['membersDelta'])
			const meta = ActiveChannelsMetaSchema.parse(result.meta)
			return (result.data || []).map(ch =>
				NotArchivedChannelSchema.parse({
					...ch,
					membersDelta: meta.membersDelta[ch.uuid as string],
				}),
			)
		})

export const useNotArchivedChannels = (
	options: Omit<
		UseQueryOptions<ChannelNotArchived[], ParsedError<(typeof knownErrors)[number]>, ChannelNotArchived[]>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		notArchivedChannelsQuery: useQuery<
			ChannelNotArchived[],
			ParsedError<(typeof knownErrors)[number]>,
			ChannelNotArchived[]
		>('not_archived_channels', fetchNotArchivedChannels, options),
	}
}
