import { createContext, useContext } from 'react'

import type { StoreChannels } from '../../store'

export const ChannelsStoreContext = createContext<StoreChannels | undefined>(undefined)

export const useChannelsStore = (): StoreChannels => {
	const store = useContext(ChannelsStoreContext)
	if (store === undefined) {
		throw Error('useChannelsStore can be used only within ChannelsStoreContext')
	}
	return store
}
