import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { JsonApiErrorResponse, ParsedError, JsonApiResponseSingle } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { User } from '..'

import { UserSchema } from './schemas'

type UpdateUserData = {
	ava?: string
	firstName?: string
	lastName?: string
}

const knownErrors = ['EMAIL_ALREADY_IN_USE'] as const

const updateUser = (data: UpdateUserData): Promise<User> =>
	axiosInstance
		.patch<JsonApiResponseSingle>('/auth/me', data)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return UserSchema.parse(result.data)
		})

export const useUpdateUser = () => {
	return {
		updateUserMutation: useMutation<User, ParsedError<(typeof knownErrors)[number]>, UpdateUserData>(updateUser),
	}
}
