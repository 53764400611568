import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useBotStore } from 'app/domains/Bot/store'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useHasFeature } from 'app/domains/Feature/hooks'
import { useSignout } from 'app/domains/User/hooks'

import { routesUrls } from 'app/routes/urls'

type OnboardingSteps = 'email' | 'channel' | 'bot' | 'beta' | 'payments' | 'success'

type CurrentStep = {
	index: number
	name: OnboardingSteps
}

type StepWithNavigation = {
	name: OnboardingSteps
	isEnabled: boolean
	navigate: () => void
}

export const WithOnboarding = observer(function WithOnboarding() {
	const navigate = useNavigate()
	const { signOut } = useSignout({ redirectTo: routesUrls.auth.signin })

	const bot = useBotStore()
	const channels = useChannelsStore()
	const isBetaFeatureEnabled = useHasFeature('BETA_ACCESS')

	const totalSteps = isBetaFeatureEnabled ? 4 : 3
	const [currentStep, setCurrentStep] = useState<CurrentStep>({ index: 1, name: 'email' })

	const steps: StepWithNavigation[] = [
		{
			name: 'channel',
			isEnabled: channels.activeList.length === 0,
			navigate: () => {
				setCurrentStep({ index: 1, name: 'channel' })
				navigate(routesUrls.onboarding.addChannel)
			},
		},
		{
			name: 'bot',
			isEnabled: bot.link === false,
			navigate: () => {
				setCurrentStep({ index: 2, name: 'bot' })
				navigate(routesUrls.onboarding.linkBot)
			},
		},
		{
			name: 'beta',
			isEnabled: isBetaFeatureEnabled,
			navigate: () => {
				setCurrentStep({ index: 3, name: 'beta' })
				navigate(routesUrls.onboarding.beta)
			},
		},
		{
			name: 'success',
			isEnabled: true,
			navigate: () => {
				setCurrentStep({ index: isBetaFeatureEnabled ? 4 : 3, name: 'success' })
				navigate(routesUrls.onboarding.success)
			},
		},
	]

	const getNextStep = () => {
		const currentStepIndexOverAllExistingSteps = steps.findIndex(step => step.name === currentStep.name)
		return (
			steps.slice(currentStepIndexOverAllExistingSteps + 1).find(step => step.isEnabled) ||
			steps[steps.length - 1]
		)
	}

	const onNextStep = () => {
		const nextStep = getNextStep()
		nextStep.navigate()
	}

	// Set default step on page loading and redirect to the correct URL
	useEffect(() => {
		const initialStep = steps.find(step => step.isEnabled) || steps[steps.length - 1]
		initialStep.navigate()
	}, [])

	return (
		<Outlet
			context={{
				signOut,
				totalSteps,
				currentStepIndex: currentStep.index,
				onNextStep,
			}}
		/>
	)
})
