import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { notReachable } from '@prostpost/utils'
import { useToast } from '@prostpost/toast'

import { useCopyDraft as useCopyDraftApi } from 'app/domains/Draft/api'
import type { DraftNotScheduled } from 'app/domains/Draft'

type Args = {
	uuid: string
	onError?: () => void
	onSuccess?: (draft: DraftNotScheduled) => void
}

export const useCopyDraft = ({ uuid, onError, onSuccess }: Args) => {
	const { t } = useTranslation()

	const copyError = useToast({
		type: 'error',
		text: t('post.copyDraft.error', 'Unable to copy post'),
	})

	const copySuccess = useToast({
		type: 'success',
		text: t('post.copyDraft.success', 'Draft created from post'),
	})

	const { copyDraftMutation } = useCopyDraftApi(uuid)

	useEffect(() => {
		switch (copyDraftMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				copyError.show()
				onError?.()
				break
			case 'success':
				copySuccess.show()
				onSuccess?.(copyDraftMutation.data)
				break
			default:
				notReachable(copyDraftMutation)
		}
	}, [copyDraftMutation.status])

	return { copyDraft: copyDraftMutation.mutate, isCreatingDraftCopy: copyDraftMutation.isLoading }
}
