import { useState, useEffect } from 'react'

import { notReachable } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'

export const useFloatingAside = (): [boolean, (action: 'show' | 'hide' | 'toggle') => void] => {
	const MEDIA = useCurrentMedia()

	const [isVisible, setIsVisible] = useState(false)

	// Hide sidebar by default if resolution changed
	useEffect(() => {
		setIsVisible(false)
	}, [MEDIA])

	const onToggle = (action: 'show' | 'hide' | 'toggle') => {
		switch (action) {
			case 'hide':
				setIsVisible(false)
				break
			case 'show':
				setIsVisible(true)
				break
			case 'toggle':
				setIsVisible(prev => !prev)
				break
			default:
				notReachable(action)
		}
	}

	return [isVisible, onToggle]
}
