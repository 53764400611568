import { Trans } from 'react-i18next'

import { useOpenWindow } from '@prostpost/utils'
import { TextLink, Paragraph } from '@prostpost/uikit'

import { useTelegramBotLink, useTelegramBotName } from 'app/domains/Telegram/hooks'

export const LinkBotToUserDescription = () => {
	const openNewWindow = useOpenWindow()
	const telegramBotName = useTelegramBotName()
	return (
		<Paragraph my={3} size={14} color="blue_40">
			<Trans i18nKey="content:bot.linkBotToUser.modal.description2" values={{ botName: `@${telegramBotName}` }}>
				{'Chat with '}
				<TextLink isExternal variant="emphasized" onClick={() => openNewWindow(useTelegramBotLink())}>
					{'{{botName}}'}
				</TextLink>
				{' and use '}
				<Paragraph inline as="span" size={16} color="blue_100">
					{'/link'}
				</Paragraph>
				{' command to receive a confirmation code. Paste that code from chat in the field below.'}
			</Trans>
		</Paragraph>
	)
}
