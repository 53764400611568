import { Trans, useTranslation } from 'react-i18next'

import { useOpenWindow } from '@prostpost/utils'
import { VStack, Paragraph, TextLink, TextList, Heading } from '@prostpost/uikit'

import { useTelegramBotLink, useTelegramBotName } from 'app/domains/Telegram/hooks'

export const LinkBotToUserInstruction = () => {
	const { t } = useTranslation()
	const openNewWindow = useOpenWindow()
	const telegramBotName = useTelegramBotName()
	return (
		<VStack space={3} w="100%">
			<Heading h={3}>{t('content:bot.linkBotToUser.heading', 'Connect your Telegram account')}</Heading>
			<Paragraph size={14} color="blue_40">
				{t(
					'content:bot.linkBotToUser.instruction.description',
					'Bot provide access to preview post functions, notifications straight in Telegram and more.',
				)}
			</Paragraph>
			<TextList variant="numbered" startWithZero={false} size={14} padding={1}>
				<TextList.Item>
					<Trans
						i18nKey="content:bot.linkBotToUser.instruction.link2"
						values={{ botName: `@${telegramBotName}` }}
					>
						{'Chat with '}
						<TextLink isExternal variant="emphasized" onClick={() => openNewWindow(useTelegramBotLink())}>
							{'{{botName}}'}
						</TextLink>
						{' and use '}
						<Paragraph inline as="span" size={16} color="blue_100">
							{'/link'}
						</Paragraph>
						{' command'}
					</Trans>
				</TextList.Item>
				<TextList.Item>
					{t(
						'content:bot.linkBotToUser.instruction.code',
						'Paste code you received from chat in the field below',
					)}
				</TextList.Item>
			</TextList>
		</VStack>
	)
}
