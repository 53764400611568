import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { canUseDOM } from '@prostpost/utils'
import { Button, Logo, Nav } from '@prostpost/uikit'

import { useIsUserSignedIn, useSignout } from 'app/domains/User/hooks'

import { PublicHeader } from 'app/shared/components'

import { routesUrls } from 'app/routes/urls'

type Props = {
	children: React.ReactElement | React.ReactElement[]
	routes: {
		signIn: string
		signUp: string
		redirectKnownUserTo: string
	}
}

export const WithAuthNav = ({ routes, children }: Props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const isUserSignedIn = useIsUserSignedIn()

	const { signOut } = useSignout({ redirectTo: routesUrls.auth.signin })

	return (
		<>
			<PublicHeader>
				<Logo variant="dark" mt={-3} onClick={() => navigate('/')} />

				{isUserSignedIn ? (
					<Nav variant="light">
						<Nav.Item variant="nav">
							<Button.Nav title={t('pp.nav.logout', 'Log out')} onClick={signOut} />
						</Nav.Item>
						<Nav.Item variant="cta" mediaHideOnRes="mobile">
							<Button.Nav
								title={t('pp.nav.goToApp', 'Continue to Inbox')}
								onClick={() => {
									canUseDOM() && window.location.assign(routes.redirectKnownUserTo)
								}}
							/>
						</Nav.Item>
					</Nav>
				) : (
					<Nav>
						<Nav.Item variant="nav">
							<Button.Nav
								title={t('pp.nav.signin', 'Sign in')}
								to={routes.signIn}
								onClick={() => console.log('TODO: Track event')}
							/>
						</Nav.Item>
						<Nav.Item variant="cta" mediaHideOnRes="mobile">
							<Button.Nav
								title={t('pp.nav.getStarted', 'Get Started')}
								to={routes.signUp}
								onClick={() => console.log('TODO: Track event')}
							/>
						</Nav.Item>
					</Nav>
				)}
			</PublicHeader>

			<>{children}</>
		</>
	)
}

export default WithAuthNav
