import { memo } from 'react'
import { useTheme } from '@emotion/react'
import * as Icons from 'react-feather'

import { Flex, Text } from '@prostpost/uikit'
import { shortNumber } from '@prostpost/utils'

type Props = {
	views: number
}

const arePropsEqual = (prevProps: Props, nextProps: Props) => {
	return prevProps.views === nextProps.views
}

export const Views = memo(function Views({ views = 0 }: Props) {
	const theme = useTheme()
	return (
		<Flex align="center">
			<Icons.Eye size={14} strokeWidth={2} color={theme.colors.blue_40} />
			<Text ml={1} size={13} color="blue_40">
				{shortNumber(views)}
			</Text>
		</Flex>
	)
}, arePropsEqual)
