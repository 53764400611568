import type { MotionProps } from 'framer-motion'

export const actionTilesAnimation: MotionProps = {
	layout: true,
	animate: 'open',
	exit: 'collapsed',
	initial: 'collapsed',
	transition: { ease: 'easeInOut', duration: 0.6 },
	style: {
		width: '100%',
		overflow: 'hidden',
		height: 'auto',
		position: 'relative',
	},
	variants: {
		// 1. we need animate height for smooth movement of tiles below
		//    so they smoothly come on top and it should be set to an exact number - not auto
		// 2. marginBottom is needed here to remove VStack margin which is set to 24px to
		//    avoid jumping at the end of animation. we use Box to simulate it along with Tile component
		open: { y: 0, opacity: 1, height: 'auto' },
		collapsed: { y: -60, opacity: 0, height: 0 },
	},
}
