import { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation, Trans } from 'react-i18next'

import { notReachable } from '@prostpost/utils'
import { Image, Box, Heading, Paragraph, Button, HStack, Tile } from '@prostpost/uikit'

import { FeedbackForm } from 'app/domains/Support/features/FeedbackForm'

import { Images } from 'app/resources'

const StyledTile = styled(Tile.VStack)`
	padding-bottom: 0 !important;
`

export const BetaInfoTile = () => {
	const { t } = useTranslation()

	const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false)

	return (
		<>
			<StyledTile space={2} h="167px" bg="white_100" borderRadius="mid">
				<HStack space={0} align="flex-start" just="space-between" w="100%">
					<Heading h={4}>{t('dashboard.earlyAccess.title', 'Early access!')}</Heading>
					{/* set height to avoid layout jump while image is loading */}
					<Box h="52px">
						<Image
							width="60px"
							src={Images.TwoPlanesColor}
							alt={t('dashboard.betaCard.alt', 'Early access!')}
						/>
					</Box>
				</HStack>
				<Paragraph size={14} mt="-29px" color="blue_40">
					<Trans i18nKey="dashboard.betaCard.description4">
						{'We actively test and improve'}
						<br />
						{'our app right now. Enjoy unlimited'}
						<br />
						{'amount of posts for your channel'}
					</Trans>
				</Paragraph>
				<Button.Inline
					ml={-3}
					mt="-4px"
					size="small"
					color="blue_100"
					onClick={() => setIsFeedbackPopupOpen(true)}
				>
					{t('dashboard.betaCard.feedbackAction', 'Leave us feedback')}
				</Button.Inline>
			</StyledTile>

			<FeedbackForm
				variant="modal"
				isOpen={isFeedbackPopupOpen}
				onMsg={msg => {
					switch (msg.type) {
						case 'on_submit_feedback':
						case 'on_close_feedback_modal':
							setIsFeedbackPopupOpen(false)
							break
						default:
							notReachable(msg)
					}
				}}
			/>
		</>
	)
}
