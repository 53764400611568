import { useEffect, useState } from 'react'
import { captureException } from '@sentry/react'
import { useTranslation } from 'react-i18next'

import { notReachable } from '@prostpost/utils'

import { useBotStore } from 'app/domains/Bot/store'
import { useUpdateBotLink } from 'app/domains/Bot/api'

type Status = { type: 'success' | 'error'; message: string } | { type: 'loading' | 'idle' }

export const useSetBotLink = () => {
	const { t } = useTranslation()

	const bot = useBotStore()
	const { updateBotLinkMutation } = useUpdateBotLink()
	const [status, setStatus] = useState<Status>({ type: 'idle' })

	useEffect(() => {
		switch (updateBotLinkMutation.status) {
			case 'idle':
				setStatus({ type: 'idle' })
				break
			case 'loading':
				setStatus({ type: 'loading' })
				break
			case 'error':
				switch (updateBotLinkMutation.error.code) {
					case 'UNKNOWN_ERROR':
					case 'NOT_VALID_ERROR':
						captureException(updateBotLinkMutation.error)
						setStatus({
							type: 'error',
							message: t('notify:bot.botLink.unknown.error', 'Unable to get channel from Telegram'),
						})
						break
					case 'BOT_LINK_FAILED_INVALID_CODE':
						setStatus({
							type: 'error',
							message: t('notify:bot.botLink.notChannel.error', 'This is not a channel'),
						})
						break
					case 'BOT_CHAT_ALREADY_LINKED_TO_ANOTHER_ACCOUNT':
						setStatus({
							type: 'error',
							message: t(
								'notify:bot.botLink.botNotAdmin.error2',
								"Bot is either not added to channel's admins or Telegram user is already linked to another Prostpost account",
							),
						})
						break
					case 'CONFIRMATION_CODE_NOT_FOUND':
						setStatus({
							type: 'error',
							message: t('notify:bot.botLink.codeNotFound.error', 'Code is invalid or expired'),
						})
						break
					default:
						notReachable(updateBotLinkMutation.error.code)
				}
				break

			case 'success':
				bot.setLink(updateBotLinkMutation.data)
				setStatus({
					type: 'success',
					message: t('notify:bot.botLink.linked.success', 'Bot was successfully linked to your account'),
				})
				break

			default:
				notReachable(updateBotLinkMutation)
		}
	}, [updateBotLinkMutation.status])

	return {
		botLinkStatus: status,
		setBotLink: updateBotLinkMutation.mutate,
		botLinkError: updateBotLinkMutation.error?.code,
	}
}
