import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import * as Icons from 'react-feather'

import { Flex, Tooltip } from '@prostpost/uikit'

export const SilentMark = () => {
	const theme = useTheme()
	const { t } = useTranslation()
	return (
		<Flex align="center">
			<Tooltip
				placement="bottom-end"
				showDelay={1200}
				content={t('content:post.silentMark', 'Published without notification')}
			>
				<Icons.BellOff size={14} strokeWidth={2} color={theme.colors.blue_40} />
			</Tooltip>
		</Flex>
	)
}
