import { toJS, runInAction } from 'mobx'

import { useHasFeature } from 'app/domains/Feature/hooks'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { BETA_POSTS_LIMIT } from 'app/domains/Feature/constants'

type Return = {
	postsLimitUsed: number
	postsLimitTotal: number | null
	postsLimitReached: boolean
}

export const useUserPostsLimit = (): Return => {
	const user = useOnboardedUserStore()
	const isBetaFeatureEnabled = useHasFeature('BETA_ACCESS')

	const { postsLimitUsed, postsLimitTotal } = runInAction(() => {
		const postsLimitUsed = user.preferences.limits.monthlyPublishedPosts
		const postsLimitTotal = user.preferences.limits.posts
		return toJS({ postsLimitUsed, postsLimitTotal })
	})

	const postsLimitReached = isBetaFeatureEnabled
		? postsLimitUsed >= BETA_POSTS_LIMIT
		: postsLimitTotal && postsLimitUsed >= postsLimitTotal

	return {
		postsLimitUsed,
		postsLimitReached: !!postsLimitReached,
		postsLimitTotal: isBetaFeatureEnabled ? BETA_POSTS_LIMIT : postsLimitTotal || 0,
	}
}
