import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

import { Textarea } from '@prostpost/uikit-next'
import { Flex, Heading, Modal, Field, VStack, Rating } from '@prostpost/uikit'

import type { Category } from 'app/domains/Support'

import { useCommonLocalizations } from 'app/shared/hooks/useCommonLocalizations'

import { ReactModal } from 'app/modals'

import { useFeedbackForm } from '../hooks'
import { FEEDBACK_CATEGORIES } from '../constants'

export type Msg = { type: 'on_submit_feedback' } | { type: 'on_close_feedback_modal' }
type MsgProp = {
	onMsg: (msg: Msg) => void
}

export type Interface = {
	isOpen: boolean
}

export const FeedbackFormModal = ({ isOpen, onMsg }: Interface & MsgProp) => {
	const { t } = useTranslation()
	const { actionCancel } = useCommonLocalizations(t)

	const { status, fields, control, setValue, clearErrors, onSubmit } = useFeedbackForm({
		onSubmit: () => onMsg({ type: 'on_submit_feedback' }),
	})

	return (
		<ReactModal shouldCloseOnEsc shouldCloseOnOverlayClick isOpen={!!isOpen}>
			<Modal variant="confirm">
				<Modal.Close onClick={() => onMsg({ type: 'on_close_feedback_modal' })} />
				<Modal.Content>
					<Heading h={4}>{t('dashboard.feedback.rateUs', 'Rate us')}</Heading>
					<VStack space={2} position="static" w="100%">
						<Flex w="100%" just="center" py={4}>
							<Rating name="rating" defaultRating={0} onChange={rating => setValue('rating', rating)} />
						</Flex>
						<Field.Dropdown
							isSearchEnabled={false}
							options={FEEDBACK_CATEGORIES}
							value={FEEDBACK_CATEGORIES[0]}
							onSelect={option => setValue('category', (option?.value as Category) || 'review')}
						/>
						<Controller
							name="message"
							control={control}
							render={({ field }) => (
								<Textarea
									size="xs"
									variant={fields.message.error ? 'error' : 'normal'}
									message={fields.message.error}
									placeholder={t(
										'content:support.feedback.commentPlaceholder',
										'Please tell us what you think :)',
									)}
									{...field}
									onBlur={() => {
										clearErrors()
										field.onBlur()
									}}
								/>
							)}
						/>
					</VStack>
				</Modal.Content>
				<Modal.ConfirmSecondaryAction onClick={() => onMsg({ type: 'on_close_feedback_modal' })}>
					{actionCancel}
				</Modal.ConfirmSecondaryAction>
				<Modal.ConfirmPrimaryAction state={status === 'loading' ? 'pending' : 'normal'} onClick={onSubmit}>
					{t('dashboard.feedback.submit', 'Submit feedback')}
				</Modal.ConfirmPrimaryAction>
			</Modal>
		</ReactModal>
	)
}
