import { useEffect } from 'react'

const appHeight = (): void => {
	const doc = document.documentElement
	doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

const useViewportConfig = () => {
	useEffect(() => {
		appHeight()
		window.addEventListener('resize', appHeight)
		window.addEventListener('orientationchange', appHeight)
		return () => {
			window.removeEventListener('resize', appHeight)
			window.removeEventListener('orientationchange', appHeight)
		}
	}, [])
}

export const init = () => {
	const appContainerTag = document.getElementById('app') as HTMLDivElement
	appContainerTag.style.overflow = 'hidden'
	return { appContainerTag, useViewportConfig }
}
