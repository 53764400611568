import { makeLoggable } from 'mobx-log'
import { createContext, useContext } from 'react'
import { action, observable, makeObservable } from 'mobx'

import type { Feature } from '.'

type InitArgs = {
	features: Feature[]
}

export class StoreFeatures {
	declare data: Feature[]

	init(args: InitArgs) {
		this.data = args.features
	}

	constructor(args: InitArgs) {
		this.init(args)

		makeObservable(this, {
			data: observable,

			init: action,
			hasFeature: action,
		})

		makeLoggable(this)
	}

	hasFeature(feature: Feature) {
		return this.data.includes(feature)
	}
}

export const FeaturesStoreContext = createContext<StoreFeatures | undefined>(undefined)
export const useFeaturesStore = () => {
	const store = useContext(FeaturesStoreContext)
	if (store === undefined) {
		throw Error('useFeaturesStore can be used only within FeaturesStoreContext')
	}
	return store
}
