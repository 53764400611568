import { z } from 'zod'

import type { PricingPlan, PricingPlanLimited, PricingPlanCustom } from '..'

const PricingPlanLimitedSchema: z.ZodSchema<PricingPlanLimited, z.ZodTypeDef, unknown> = z
	.object({
		id: z.number(),
		price: z.number(),
		name: z.union([z.literal('TRIAL'), z.literal('STARTER'), z.literal('PRO')]),
		details: z.object({
			postsLimit: z.number().min(0),
			membersLimit: z.number().min(0),
			channelsLimit: z.number().min(0),
			memberPrice: z.optional(z.number().min(0)),
		}),
	})
	.transform(response => ({
		id: response.id,
		price: response.price,
		name: response.name,
		limits: {
			posts: response.details.postsLimit,
			members: response.details.membersLimit,
			channels: response.details.channelsLimit,
			memberPrice: response.details.memberPrice,
		},
	}))

const PricingPlanCustomSchema: z.ZodSchema<PricingPlanCustom, z.ZodTypeDef, unknown> = z.object({
	id: z.number(),
	name: z.literal('CUSTOM'),
})

export const PricingPlanSchema: z.ZodSchema<PricingPlan, z.ZodTypeDef, unknown> = z.union([
	PricingPlanLimitedSchema,
	PricingPlanCustomSchema,
])
