import { useRef } from 'react'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { notReachable, useElementSize } from '@prostpost/utils'
import { Button, Image, Box, Flex } from '@prostpost/uikit'

import { Images } from 'app/resources'

import { IMAGE_WIDTH } from '../constants'
import { AddChannelPlain } from '../Plain'
import type { Interface as InterfacePlain, Msg as MsgPlain } from '../Plain'

import { AddChannelTileWrapper } from './components'

export type MsgAnimated = MsgPlain | { type: 'on_close_add_channel_tile' }
export type MsgStatic = MsgPlain

export type Interface = InterfacePlain &
	(
		| {
				type: 'static'
				onMsg: (msg: MsgStatic) => void
		  }
		| {
				type: 'animated'
				isOpen: boolean
				onMsg: (msg: MsgAnimated) => void
		  }
	)

export const AddChannelTile = (props: Interface) => {
	const theme = useTheme()
	const { t } = useTranslation()

	const ref = useRef<HTMLDivElement>(null)
	const { width } = useElementSize(ref)

	return (
		// we need ref outside of AnimatePresence to be able to calculate width before
		// we start showing a tile
		<Box ref={ref} w="100%">
			<AddChannelTileWrapper {...props}>
				{(() => {
					switch (props.type) {
						case 'static':
							return null
						case 'animated':
							return (
								<Box position="absolute" w="auto" zIndex={1} top={4} right={4}>
									<Button.Icon
										size="small"
										bg="blue_10"
										onClick={() => {
											props.onMsg({ type: 'on_close_add_channel_tile' })
										}}
									>
										<Icons.X color={theme.colors.blue_20} />
									</Button.Icon>
								</Box>
							)
						default:
							notReachable(props)
					}
				})()}

				<Flex dir={width && width < 600 ? 'column' : 'row'}>
					{/* we need Box because image takes some time to load and motion doesn't know it's dimensions what ruins animation */}
					<Box w={IMAGE_WIDTH} h={IMAGE_WIDTH} mr={6} mb={6}>
						<Image
							width={IMAGE_WIDTH}
							height={IMAGE_WIDTH}
							alt={t('dashboard.addNewChannel.alt', 'Add new channel')}
							src={Images.AddNewChannel}
						/>
					</Box>
					<AddChannelPlain
						size={props.size}
						title={props.title}
						submitButton={props.submitButton}
						isFullWidth={!!(width && width < 600)}
						onMsg={msg => {
							switch (msg.type) {
								case 'on_new_channel_added':
									props.onMsg(msg)
									break
								default:
									notReachable(msg.type)
							}
						}}
					/>
				</Flex>
			</AddChannelTileWrapper>
		</Box>
	)
}
