import { useEffect } from 'react'

import { notReachable } from '@prostpost/utils'

import { useActiveChannel } from 'app/domains/Channel/api'
import { useChannelsStore } from 'app/domains/Channel/store'

export const useReloadChannel = (channelName: string | undefined) => {
	const channels = useChannelsStore()

	// 3. Load channel's details from the server
	const { activeChannelQuery } = useActiveChannel(channelName || '', {
		enabled:
			(!!channelName && channels.current?.name !== channelName) ||
			// if we archived a channel do not try to reload it
			!!channels.activeList.find(channel => channel.name === channelName),
	})

	useEffect(() => {
		switch (activeChannelQuery.status) {
			case 'idle':
				break
			case 'error':
			case 'loading':
				// 4. Clean currently active channel while loading the next one
				channels.resetCurrent()
				break
			case 'success':
				// 5. Set current channel to the store
				channels.setCurrent(activeChannelQuery.data)
				break
			default:
				notReachable(activeChannelQuery)
		}
	}, [channelName, activeChannelQuery.status])

	return { activeChannelQuery }
}
