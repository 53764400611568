import { motion, AnimatePresence } from 'framer-motion'

import { Tile, Box } from '@prostpost/uikit'
import { useCurrentMedia } from '@prostpost/css'

import { actionTilesAnimation } from '../constants'

type Props = { children: React.ReactNode } & ({ type: 'animated'; isOpen: boolean } | { type: 'static' })

export const AddChannelTileWrapper = ({ children, ...props }: Props) => {
	const isMobile = useCurrentMedia() === 'MOBILE'
	switch (props.type) {
		case 'static':
			return <Tile w="100%">{children}</Tile>
		case 'animated':
			return (
				<AnimatePresence>
					{props.isOpen ? (
						<motion.div key={1} {...actionTilesAnimation}>
							<Tile>{children}</Tile>
							<Box w="100%" h={isMobile ? '12px' : '24px'} bg="white_100" />
						</motion.div>
					) : null}
				</AnimatePresence>
			)
	}
}
