import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

const knownErrors = ['UNABLE_TO_DELETE_TEMPLATE', 'UNABLE_TO_GET_TEMPLATE', 'TEMPLATE_NOT_FOUND'] as const

const deleteTemplate = ({ uuid }: { uuid: string }): Promise<void> =>
	axiosInstance
		.delete<JsonApiResponseSingle>(`/drafts/template/${uuid}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(() => {
			return
		})

export const useDeleteTemplate = (uuid: string) => {
	return {
		deleteTemplateMutation: useMutation<void, ParsedError<(typeof knownErrors)[number]>, void | undefined>(
			'template',
			() => deleteTemplate({ uuid }),
		),
	}
}
