import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { UserPreferences } from '..'

import { PreferencesSchema } from './schemas'

const knownErrors = [] as const

const fetchUserPreferences = (): Promise<UserPreferences> =>
	axiosInstance
		.get<JsonApiResponseSingle>('/auth/preferences')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data, ['data.notifications'])
			return PreferencesSchema.parse(result.data)
		})

export const useUserPreferences = () => {
	return {
		userPreferencesQuery: useQuery<UserPreferences, ParsedError<(typeof knownErrors)[number]>, UserPreferences>(
			'user_preferences',
			fetchUserPreferences,
		),
	}
}
