import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { captureException } from '@sentry/react'

import { ErrorWidget as BaseErrorWidget } from '@prostpost/uikit'
import type { ParsedError } from '@prostpost/jsonapi'

import { useCommonLocalizations } from 'app/shared/hooks'

import type { ErrorType } from 'app/domains/Error'

type Props = {
	size: 'big' | 'mid'
	type?: ErrorType
	error: ParsedError<unknown>
	title?: string
	summary?: string
	isFullScreen?: boolean
	children?: React.ReactNode
	extras?: Record<string, unknown>
	onTryAgain: () => void
}

const Container = ({
	type,
	size,
	error,
	title,
	summary,
	isFullScreen = false,
	extras = {},
	children,
	onTryAgain,
}: Props) => {
	const { t } = useTranslation('common')
	const { tryAgainMessage, somethingWentWrongMessage, pleaseContactSupportMessage } = useCommonLocalizations(t)

	const errorType = (() => {
		if (!error.code) return 'NOT_VALID'
		switch (error.code) {
			case 'UNKNOWN_ERROR':
				return 'UNKNOWN'
			case 'NOT_VALID_ERROR':
				return 'NOT_VALID'
			default:
				return type || 'UNKNOWN'
		}
	})()

	useEffect(() => {
		if (type !== 'VALID_BUSINESS_LOGIC') {
			captureException(error, { extra: { place: 'ErrorBoundary', type: errorType, ...extras } })
		}
	}, [error])

	return (
		<BaseErrorWidget isFullScreen={isFullScreen} error={error} size={size}>
			<BaseErrorWidget.Title>{title || somethingWentWrongMessage}</BaseErrorWidget.Title>
			<BaseErrorWidget.Summary>{summary || pleaseContactSupportMessage}</BaseErrorWidget.Summary>
			<BaseErrorWidget.TryAgain onClick={onTryAgain}>{tryAgainMessage}</BaseErrorWidget.TryAgain>
			{children}
		</BaseErrorWidget>
	)
}

export const ErrorWidget = Object.assign(Container, {
	Action: BaseErrorWidget.SecondaryAction,
})
