import { z } from 'zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'

import { useCreateFeedbackMessage } from 'app/domains/Support/api'
import type { Category, FeedbackMessage } from 'app/domains/Support'

const COMMENT_FIELD_NAME = 'message'

type FormData = {
	rating: number
	category: Category
	message?: string
}

export const useFeedbackForm = ({ onSubmit }: { onSubmit: (fd: FeedbackMessage) => void }) => {
	const { t } = useTranslation()

	const feedbackSent = useToast({
		type: 'success',
		text: t('notify:feedback.create.success', 'Feedback sent successfully'),
	})

	const feedbackFailed = useToast({
		type: 'success',
		text: t('notify:feedback.create.error', 'Failed to send your feedback'),
	})

	const { useCreateFeedbackMutation } = useCreateFeedbackMessage()

	useEffect(() => {
		switch (useCreateFeedbackMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				console.error(useCreateFeedbackMutation.error)
				feedbackFailed.show()
				break
			case 'success':
				onSubmit(useCreateFeedbackMutation.data)
				feedbackSent.show()
				break
			default:
				notReachable(useCreateFeedbackMutation)
		}
	}, [useCreateFeedbackMutation.status])

	const formSchema: z.ZodSchema<FormData> = z.object({
		rating: z.number(),
		category: z.union([z.literal('review'), z.literal('feature_request'), z.literal('bug')]),
		comment: z.optional(
			z.string().max(500, t('notify:support.feedback.commentMaxLength', 'Maximum 500 characters allowed')),
		),
	})

	const {
		control,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: zodResolver(formSchema),
		defaultValues: {
			message: '',
			category: 'review',
			rating: 0,
		},
	})

	return {
		control,
		setValue,
		clearErrors,
		status: useCreateFeedbackMutation.status,
		onSubmit: handleSubmit(data => useCreateFeedbackMutation.mutate(data)),
		fields: {
			message: {
				error: errors.message?.message,
				setError: (message: string) => setError(COMMENT_FIELD_NAME, { message }),
			},
		},
	}
}
