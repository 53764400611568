import { notReachable } from '@prostpost/utils'

import { FeedbackFormModal } from './Modal'
import type { Interface as InterfaceModal, Msg as MsgModal } from './Modal'

type Props = { variant: 'modal' } & InterfaceModal & { onMsg: (msg: MsgModal) => void }

export const FeedbackFormVariants = (props: Props) => {
	switch (props.variant) {
		case 'modal':
			return <FeedbackFormModal {...props} />
		default:
			return notReachable(props.variant)
	}
}
