import { useTranslation } from 'react-i18next'

import { dateToPeriod } from '@prostpost/utils'
import { Flex, Text } from '@prostpost/uikit'

type Props = {
	date: string
}

export const UpdatedAt = ({ date }: Props) => {
	const { t } = useTranslation()
	const updatedAt = dateToPeriod(date, t('draft.preview.momentAgo', 'a moment ago'))
	return (
		<Flex as="footer" w="100%" align="center" just="space-between" my={2} zIndex={0}>
			<Text color="blue_40" size={13}>
				{updatedAt}
			</Text>
			<i />
			{/* do not remove <i /> it keeps layout correct inside flex */}
		</Flex>
	)
}
