import { useTranslation, Trans } from 'react-i18next'

import { VStack, Heading, Paragraph, Text } from '@prostpost/uikit'

export const BetaInfoPlain = () => {
	const { t } = useTranslation()
	return (
		<VStack space={4} w="100%">
			<Heading h={3}>{t('userSettings.plans.betaTitle', "🚀 We're in Beta")}</Heading>
			<Paragraph size={14} color="blue_40">
				<Trans i18nKey="userSettings.plans.betaText">
					{
						'What does it mean for you? You have a full access to all of the features without limitations for free. No card information needed. After Beta period there will be different pricing plans for all the users'
					}
					<br />
					<br />
					{'Make sure you check your emails to get '}
					<Text size={14} color="blue_100">
						{'special offers, free months and other bonuses for beta program participants'}
					</Text>
					{' before final launch'}
				</Trans>
			</Paragraph>
		</VStack>
	)
}
