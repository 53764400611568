import _ from 'lodash'
import { getTimeZones } from '@vvo/tzdb'

export const useTimezones = () =>
	_(getTimeZones())
		.map(tz => {
			const offsetH = Math.abs(tz.currentTimeOffsetInMinutes / 60)
			const flooredOffset = Math.floor(offsetH)
			const withLeadingZero = flooredOffset > 9 ? flooredOffset : `0${flooredOffset}`
			const minutes = flooredOffset !== offsetH ? '30' : '00'
			const offsetStr =
				tz.currentTimeOffsetInMinutes > 0
					? `+${withLeadingZero}:${minutes}`
					: `${offsetH === 0 ? '' : '-'}${withLeadingZero}:${minutes}`
			return {
				value: tz.name,
				label: `${offsetStr} ${tz.mainCities.join(', ')}`,
			}
		})
		.sortBy('text')
		.value()
