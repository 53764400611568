import z from 'zod'
import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { DraftScheduledSchema } from 'app/domains/Draft/api/schemas'
import type { DraftScheduled } from 'app/domains/Draft'

import type { PostInternal } from '..'

import { PostInternalSchema } from './schemas'

const knownErrors = ['UNABLE_TO_GET_DRAFT', 'DRAFT_NOT_FOUND'] as const

type Data = { uuid: string }

const ResultSchema = z.union([DraftScheduledSchema, PostInternalSchema])

export const fetchPost = ({ uuid }: Data): Promise<PostInternal | DraftScheduled> =>
	axiosInstance
		.get<JsonApiResponseSingle>(`/drafts/draft/${uuid}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return ResultSchema.parse(result.data)
		})

export const usePost = (
	uuid: string,
	options: Omit<
		UseQueryOptions<
			PostInternal | DraftScheduled,
			ParsedError<(typeof knownErrors)[number]>,
			PostInternal | DraftScheduled
		>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		postQuery: useQuery<
			PostInternal | DraftScheduled,
			ParsedError<(typeof knownErrors)[number]>,
			PostInternal | DraftScheduled
		>(['post', uuid], () => fetchPost({ uuid }), {
			enabled: false,
			...options,
		}),
	}
}
