import { createRoot } from 'react-dom/client'

import { themeLight, ThemeProvider as ThemeProviderNext } from '@prostpost/uikit-next'

import { config } from 'app/config'

export const RootModals = () => (
	<ThemeProviderNext theme={themeLight}>
		<div id={config.constants.MODALS_ROOT_ID} />
	</ThemeProviderNext>
)

export const RootFloatingUI = () => (
	<ThemeProviderNext theme={themeLight}>
		<div id={config.constants.FLOATING_UI_ROOT_ID} />
	</ThemeProviderNext>
)

export const rootApp = createRoot(config.viewport.appContainerTag)

// We need to render modals in a separate root because we need to setup CSS variables for them since
// react-modal renders them outside the #app container (and it's for a reason: http://reactcommunity.org/react-modal/#custom-parent)
export const rootModals = createRoot(
	document.getElementById(config.constants.MODALS_ROOT_CONTAINER_ID) as HTMLDivElement,
)

// Same for floating UI
export const rootFloatingUI = createRoot(
	document.getElementById(config.constants.FLOATING_UI_ROOT_CONTAINER_ID) as HTMLDivElement,
)
