import { QueryClient } from 'react-query'
import type { AxiosError } from 'axios'

export const init = () => ({
	client: new QueryClient({
		defaultOptions: {
			queries: {
				cacheTime: 0, // We don't want to deal with react-query cache and have mobx stores to store data
				refetchOnWindowFocus: false,
				refetchIntervalInBackground: false,
				refetchInterval: false,
				refetchOnMount: false,
				retry: (count, err: unknown) => {
					// Do not retry before 422 response and retry other requests only once
					const stopOnCount = count >= 1
					const noRepeatCodes = ((err as AxiosError).response?.status || 0) < 422
					return !(stopOnCount || noRepeatCodes)
				},
			},
		},
	}),
})
