import { useLocation } from 'react-router-dom'

import { useChannelsStore } from 'app/domains/Channel/store'

import { routesUrls } from 'app/routes/urls'

export const useIsEditorRouteOpen = () => {
	const location = useLocation()
	const channels = useChannelsStore()
	return channels.current ? location.pathname === routesUrls.channel.getEditorRoute(channels.current) : false
}
