import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { Input } from '@prostpost/uikit-next'

import type { useSetBotLink, useBotLinkForm } from 'app/domains/Bot/features/LinkBotToUser/hooks'

export type Msg = { type: 'on_bot_is_linked_to_user' }

type Props = {
	form: ReturnType<typeof useBotLinkForm>
	botLinkStatus: ReturnType<typeof useSetBotLink>['botLinkStatus']
	onMsg: (msg: Msg) => void
}

export const LinkBotToUserForm = ({ form, botLinkStatus, onMsg }: Props) => {
	const { t } = useTranslation()

	const botLinkedToast = useToast({
		type: 'success',
		text: t('content:bot.linkBotToUser.success', 'Bot was successfully linked to your account'),
	})

	useEffect(() => {
		switch (botLinkStatus.type) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				form.fields.code.setError(botLinkStatus.message)
				break
			case 'success':
				onMsg({ type: 'on_bot_is_linked_to_user' })
				botLinkedToast.show()
				break
			default:
				notReachable(botLinkStatus)
		}
	}, [botLinkStatus])

	return (
		<form>
			<Controller
				name="code"
				control={form.control}
				render={({ field }) => (
					<Input
						size="sm"
						variant={form.fields.code.error ? 'error' : 'normal'}
						message={form.fields.code.error}
						{...field}
						{...form.fields.code.inputProps}
					/>
				)}
			/>
		</form>
	)
}
