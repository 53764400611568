import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { i18n as I18n } from 'i18next'

import { canUseDOM, notReachable } from '@prostpost/utils'

import { updateSessionStorage } from '../utils'
import { useUserPreferences } from '../api'
import type { UserPreferences } from '..'

type WindowWithData = typeof window & {
	INITIAL_STATE: {
		lang: string
	}
}

type Args = {
	i18n: I18n
	throwError: boolean
}

export const useUserPreferencesLoader = ({ i18n, throwError }: Args): UserPreferences | undefined => {
	const { t } = useTranslation()
	const { userPreferencesQuery } = useUserPreferences()

	useEffect(() => {
		switch (userPreferencesQuery.status) {
			case 'idle':
			case 'loading':
				break

			case 'error':
				try {
					switch (userPreferencesQuery.error.code) {
						case 'UNKNOWN_ERROR':
						case 'NOT_VALID_ERROR':
							throw {
								...userPreferencesQuery.error,
								title: t(
									'notify:preferences.error.loadingFailed',
									'Unable to load account preferences',
								),
							}
						default:
							notReachable(userPreferencesQuery.error.code)
					}
				} catch (e) {
					if (throwError) throw e
				}
				break

			case 'success': {
				// Language
				const defaultLang = ['ru', 'en'].includes(userPreferencesQuery.data.lang || '')
					? userPreferencesQuery.data.lang
					: (canUseDOM() ? (window as WindowWithData).INITIAL_STATE?.lang : i18n.language) || 'en'

				userPreferencesQuery.data = {
					...userPreferencesQuery.data,
					lang: defaultLang,
				}

				i18n.changeLanguage(defaultLang).catch(e => {
					console.error(e)
				})

				updateSessionStorage(userPreferencesQuery.data.timeZone, userPreferencesQuery.data.timeFormat24)
				break
			}

			default:
				notReachable(userPreferencesQuery)
		}
	}, [userPreferencesQuery.status])

	return userPreferencesQuery.status === 'success' ? userPreferencesQuery.data : undefined
}
