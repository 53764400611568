import { mergeAttributes, Node } from '@tiptap/core'
import Suggestion from '@tiptap/suggestion'
import { PluginKey } from 'prosemirror-state'
import type { Content } from '@tiptap/core'
import type { SuggestionOptions } from '@tiptap/suggestion'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'

export type TagsOptions = {
	HTMLAttributes: Record<string, string>
	renderLabel: (props: { options: TagsOptions; node: ProseMirrorNode }) => string
	suggestion: Omit<SuggestionOptions<Record<string, unknown>>, 'editor'>
}

export const TagsPluginKey = new PluginKey('tags')

// Based on a native Mentions extensions: https://github.com/ueberdosis/tiptap/blob/main/packages/extension-mention/src/mention.ts
export const Hashtags = Node.create<TagsOptions>({
	name: 'tags',

	addOptions() {
		return {
			allowSpaces: false,
			HTMLAttributes: {},
			renderLabel({ options, node }) {
				return `${options.suggestion.char || ''}${(node.attrs.label || node.attrs.id || '') as string}`
			},
			suggestion: {
				char: '#',
				pluginKey: TagsPluginKey,
				decorationClass: 'editor-text-tag',
				command: ({ editor, range, props }) => {
					// increase range.to by one when the next node is of type "text"
					// and starts with a space character
					const nodeAfter = editor.view.state.selection.$to.nodeAfter
					const overrideSpace = nodeAfter?.text?.startsWith(' ')

					if (overrideSpace) {
						range.to += 1
					}

					const content: Content[] = [
						{
							type: this.name,
							attrs: { id: props.id || '' },
						},
					]

					// we don't want to add space if Enter pressed
					if (props.confirmKey !== 'ENTER') {
						content.push({
							type: 'text',
							text: ' ',
						})
					}

					editor.chain().focus().insertContentAt(range, content).run()
					window.getSelection()?.collapseToEnd()
				},
				allow: ({ state, range }) => {
					const $from = state.doc.resolve(range.from)
					const type = state.schema.nodes[this.name]
					return !!$from.parent.type.contentMatch.matchType(type)
				},
			},
		}
	},

	group: 'inline',
	inline: true,
	selectable: true,
	atom: true,

	addAttributes() {
		return {
			id: {
				default: null,
				parseHTML: element => element.getAttribute('data-id') || '',
				renderHTML: (attributes: Record<string, string>) => {
					if (!attributes.id) {
						return {}
					}

					return {
						'data-id': attributes.id || '',
					}
				},
			},

			label: {
				default: null,
				parseHTML: element => element.getAttribute('data-label') || '',
				renderHTML: (attributes: Record<string, string>) => {
					if (!attributes.label) {
						return {}
					}

					return {
						'data-label': attributes.label || '',
					}
				},
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: `span[data-type="${this.name}"]`,
			},
		]
	},

	renderHTML({ node, HTMLAttributes }) {
		return [
			'span',
			mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
			this.options.renderLabel({
				options: this.options,
				node,
			}),
		]
	},

	renderText({ node }) {
		return this.options.renderLabel({
			options: this.options,
			node: node,
		})
	},

	addKeyboardShortcuts() {
		return {
			Backspace: () =>
				this.editor.commands.command(({ tr, state }) => {
					let isMention = false
					const { selection } = state
					const { empty, anchor } = selection

					if (!empty) {
						return false
					}

					// @ts-expect-error  "Not all code paths return a value or Sonar will complain about extra return"
					state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
						if (node.type.name === this.name) {
							isMention = true
							tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)
							return false
						}
					})
					return isMention
				}),
		}
	},

	addProseMirrorPlugins() {
		return [
			Suggestion({
				editor: this.editor,
				...this.options.suggestion,
			}),
		]
	},
})
