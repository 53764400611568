import { makeLoggable } from 'mobx-log'
import { observable, makeObservable } from 'mobx'
import type { User as SupabaseUser } from '@supabase/supabase-js'

import type { OnboardedUser, UserRoles } from 'app/domains/User'
import type { UserPreferences } from 'app/domains/UserPreferences'

import { UserStore } from '../../store'

type InitArgs = OnboardedUser & {
	supabaseUser: SupabaseUser
	preferences: UserPreferences
}

export class OnboardedUserStore extends UserStore {
	declare roles: UserRoles[]

	init(args: InitArgs) {
		super.init(args)
		this.roles = args.roles
	}

	constructor(args: InitArgs) {
		super(args)
		this.init(args)

		makeObservable(this, {
			roles: observable,
		})

		makeLoggable(this)
	}

	getUser(): OnboardedUser & { supabaseUser: SupabaseUser } {
		return {
			type: 'ONBOARDED',
			ava: this.ava,
			uuid: this.uuid,
			email: this.email,
			lastName: this.lastName,
			fullName: this.fullName,
			firstName: this.firstName,
			supabaseUser: this.supabaseUser,
			roles: this.roles,
		}
	}
}
