import { useEffect } from 'react'
import { captureException } from '@sentry/react'

import { notReachable } from '@prostpost/utils'

import { useFeatures } from '../api'
import type { Feature } from '..'

export const useFeaturesLoader = (): Feature[] | undefined => {
	const { featuresQuery } = useFeatures()

	useEffect(() => {
		switch (featuresQuery.status) {
			case 'idle':
			case 'loading':
				break

			case 'error':
				switch (featuresQuery.error.code) {
					case 'UNKNOWN_ERROR':
					case 'NOT_VALID_ERROR':
						captureException(featuresQuery.error)
						break

					// Unexpected errors since inactive user should
					// not have access to that area at all
					case 'USER_BANNED':
					case 'USER_INACTIVE':
					case 'USER_ARCHIVED':
					case 'USER_NOT_FOUND':
						captureException(featuresQuery.error)
						break

					default:
						notReachable(featuresQuery.error.code)
				}
				break

			case 'success':
				break

			default:
				notReachable(featuresQuery)
		}
	}, [featuresQuery.status])

	switch (featuresQuery.status) {
		case 'idle':
		case 'loading':
			return undefined
		case 'success':
			return featuresQuery.data
		case 'error':
			// Keep trying 3 times until return default empty list of features for a user
			if (featuresQuery.failureCount > 3) return []
			return undefined
	}
}
