import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { PostBackup } from '..'

import { PostBackupSchema } from './schemas'

const knownErrors = ['UNABLE_TO_GET_DRAFT', 'DRAFT_NOT_FOUND'] as const

type Data = { uuid: string }

const fetchBackup = ({ uuid }: Data): Promise<PostBackup> =>
	axiosInstance
		.get<JsonApiResponseSingle>(`/drafts/backup/${uuid}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return PostBackupSchema.parse(result.data)
		})

export const useBackup = (
	uuid: string,
	options: Omit<
		UseQueryOptions<PostBackup, ParsedError<(typeof knownErrors)[number]>, PostBackup>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		backupQuery: useQuery<PostBackup, ParsedError<(typeof knownErrors)[number]>, PostBackup>(
			['backup', uuid],
			() => fetchBackup({ uuid }),
			{
				enabled: false,
				...options,
			},
		),
	}
}
