import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { ArrowRightLeft } from 'lucide-react'
import type { TFunction } from 'i18next'
import { runInAction } from 'mobx'

import { truncate } from '@prostpost/utils'
import { Ava, Field } from '@prostpost/uikit'
import type { Theme } from '@prostpost/uikit'

import { useChannelsStore } from 'app/domains/Channel/store'
import { MEDIA_URL } from 'app/domains/Media/constants'
import type { ChannelActive } from 'app/domains/Channel'

type Option = {
	label: string
	value: string
	prefix?: React.ReactNode
}

type Props = {
	hasSharedOption: boolean
	onSelect: (channelUuid: string | undefined) => void
}

const getSharedValue = (t: TFunction, theme: Theme) => ({
	label: t('content:templates.shared.title', 'My templates'),
	value: 'shared',
	prefix: <ArrowRightLeft size={16} strokeWidth={2.5} color={theme.colors.blue_40} />,
})

const getDropdownValue = (channel: ChannelActive, t: TFunction): Option => ({
	label: truncate(channel.alias, 15),
	value: channel.uuid,
	prefix: (
		<Ava
			size="small"
			alt={t('channel.item.alt', 'Channel')}
			src={channel.avaSmall ? MEDIA_URL(channel.avaSmall) : undefined}
		/>
	),
})

export const ChannelsDropdown = observer(function ChannelsDropdown({ hasSharedOption, onSelect }: Props) {
	const theme = useTheme()
	const { t } = useTranslation()

	const channelsStore = useChannelsStore()
	const [selectedChannel, setSelectedChannel] = useState<undefined | ChannelActive>()

	return (
		<Field.Dropdown
			isSearchEnabled={false}
			options={[
				...(hasSharedOption ? [getSharedValue(t, theme)] : []),
				...channelsStore.activeList.map(channel => getDropdownValue(channel, t)),
			]}
			value={selectedChannel ? getDropdownValue(selectedChannel, t) : getSharedValue(t, theme)}
			onSelect={v => {
				runInAction(() => {
					v && setSelectedChannel(channelsStore.getActiveChannelByUuid(v.value))
					onSelect(v?.value)
				})
			}}
		/>
	)
})

ChannelsDropdown.displayName = 'ChannelsDropdown'
