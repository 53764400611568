import { useMutation } from 'react-query'
import type { UseMutationOptions } from 'react-query'
import type { AxiosError } from 'axios'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { DraftContent } from 'app/domains/Draft'
import type { PostBackup } from 'app/domains/Post'

import { PostBackupSchema } from './schemas'

const knownErrors = ['UNABLE_TO_GET_DRAFT_BACKUP', 'DRAFT_BACKUP_NOT_FOUND', 'DRAFT_NOT_FOUND'] as const

type Data = { uuid: string } & DraftContent

const updatePostBackup = ({ uuid, ...data }: Data): Promise<PostBackup> =>
	axiosInstance
		.patch<JsonApiResponseSingle>(`/drafts/backup/${uuid}`, data)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return PostBackupSchema.parse(result.data)
		})

export const useUpdateBackup = (
	options: Omit<
		UseMutationOptions<PostBackup, ParsedError<(typeof knownErrors)[number]>, Data>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		updatePostBackupMutation: useMutation<PostBackup, ParsedError<(typeof knownErrors)[number]>, Data>({
			mutationKey: 'backup',
			mutationFn: (data: Data) => updatePostBackup(data),
			...options,
		}),
	}
}
