import { useTranslation } from 'react-i18next'

import { notReachable } from '@prostpost/utils'

import { useNotArchivedChannels } from 'app/domains/Channel/api'
import type { ChannelActive, ChannelLocked } from 'app/domains/Channel'

export const useNotArchivedChannelsLoader = () => {
	const { t } = useTranslation()
	const { notArchivedChannelsQuery } = useNotArchivedChannels()

	switch (notArchivedChannelsQuery.status) {
		case 'idle':
		case 'loading':
			return { activeChannels: undefined, lockedChannels: undefined }

		case 'error':
			switch (notArchivedChannelsQuery.error.code) {
				case 'UNKNOWN_ERROR':
				case 'NOT_VALID_ERROR':
				default:
					throw {
						...notArchivedChannelsQuery.error,
						title: t('notify:channels.error.loadingFailed', 'Unable to get channels'),
					}
			}

		case 'success':
			return {
				activeChannels: notArchivedChannelsQuery.data.filter((ch): ch is ChannelActive => ch.type === 'ACTIVE'),
				lockedChannels: notArchivedChannelsQuery.data.filter((ch): ch is ChannelLocked => ch.type === 'LOCKED'),
			}

		default:
			return notReachable(notArchivedChannelsQuery)
	}
}
