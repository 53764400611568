import { useMutation } from 'react-query'
import type { AxiosError } from 'axios'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { PostInternalSchema } from 'app/domains/Post/api/schemas'
import type { PostInternal } from 'app/domains/Post'

const knownErrors = [
	'DRAFT_NOT_FOUND',
	'UNABLE_TO_GET_DRAFT',
	'UNABLE_TO_PUBLISH_EMPTY_POST',
	'ATTEMPT_TO_SEND_EMPTY_POST',
	'CHAT_NOT_FOUND',
	'UNABLE_TO_GET_CHAT',
] as const

type Data = {
	uuid: string
}

const publishPost = ({ uuid }: Data): Promise<PostInternal> =>
	axiosInstance
		.put<JsonApiResponseSingle>(`/drafts/post/${uuid}`, { preview: false })
		.then(response => {
			const result = parseSingle(response.data)
			return PostInternalSchema.parse({
				...result.data,
				history: false, // it's never a history post
			})
		})
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})

export const usePublishPost = () => {
	return {
		publishPostMutation: useMutation<PostInternal, ParsedError<(typeof knownErrors)[number]>, Data>(
			['post', 'publish'],
			({ uuid }: Data) => publishPost({ uuid }),
		),
	}
}
