import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { getColor } from '@prostpost/css'
import { HStack, Text } from '@prostpost/uikit'

const Circle = styled.div`
	width: 8px;
	height: 8px;

	background: ${getColor('green_100')};
	border-radius: 8px;
`

export const AdTag = () => {
	const { t } = useTranslation()
	return (
		<HStack space={1} align="center">
			<Circle />
			<Text size={13500} color="green_100">
				{t('content:postTile.ad', 'Ad')}
			</Text>
		</HStack>
	)
}
