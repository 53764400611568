import { Fragment } from 'react'
import { toJS, runInAction } from 'mobx'
import { Observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Box, Scrollbars, SlidingPopup, VStack, Heading, Paragraph, Text, Hr } from '@prostpost/uikit'

import { useTemplatesStore } from 'app/domains/Template/store'
import type { ChannelActive } from 'app/domains/Channel'
import type { TemplateShortInfo } from 'app/domains/Template'

import { DataLoader, TemplateListItem } from './components'

export type Msg =
	| { type: 'on_template_clicked'; template: TemplateShortInfo }
	| { type: 'on_template_removed'; template: TemplateShortInfo }

type MsgProp = {
	onMsg: (msg: Msg) => void
}

export type Interface = {
	channel: ChannelActive
	trigger: (args: { isOpen: boolean; onClick: () => void }) => React.ReactNode
}

const SectionDivider = ({ title }: { title: string }) => (
	<VStack space={2} py={2}>
		<Text size={13} color="black_80">
			{title}
		</Text>
		<Hr />
	</VStack>
)

export const ChannelTemplatesListPopup = ({ channel, trigger, onMsg }: Interface & MsgProp) => {
	const { t } = useTranslation()
	const templatesStore = useTemplatesStore()

	const channelUuid = runInAction(() => toJS(channel.uuid))

	return (
		<SlidingPopup width="300px" use={trigger}>
			{({ close }) => (
				<Box h="370px">
					<VStack space={1} mb={4} w="100%">
						<Heading h={4}>{t('content:templates.list.title', 'Templates')}</Heading>
						<Observer>
							{() =>
								templatesStore.templates.length === 0 ? (
									<Paragraph size={12} color="blue_40">
										{t(
											'content:templates.list.createDescription',
											'Create a template to deliver new posts quicker',
										)}
									</Paragraph>
								) : (
									<Paragraph size={12} color="blue_40">
										{t(
											'content:templates.list.chooseDescription',
											'Choose a template to deliver new posts quicker',
										)}
									</Paragraph>
								)
							}
						</Observer>
					</VStack>

					<Box h="290px">
						<Scrollbars position="relative" trackYStyles={{ left: '-16px' }}>
							<DataLoader isShowShared channelUuid={channelUuid}>
								{({ data, firstPrivateIndex }) => (
									<VStack space={2} w="100%">
										{data.map((template, index) => (
											<Fragment key={template.uuid}>
												{index === 0 ? (
													<SectionDivider
														title={t(
															'content:templates.channelList.title',
															"Channel's templates",
														)}
													/>
												) : null}

												{index === firstPrivateIndex && firstPrivateIndex !== 0 ? (
													<SectionDivider
														title={t('content:templates.sharedList.title', 'My templates')}
													/>
												) : null}

												<TemplateListItem
													key={template.uuid}
													template={template}
													onRemoved={() => onMsg({ type: 'on_template_removed', template })}
													onClick={() => {
														onMsg({ type: 'on_template_clicked', template })
														close()
													}}
												/>
											</Fragment>
										))}
									</VStack>
								)}
							</DataLoader>
						</Scrollbars>
					</Box>
				</Box>
			)}
		</SlidingPopup>
	)
}
