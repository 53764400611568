import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { ContextMenu } from '@prostpost/uikit'

export type Msg = { type: 'on_click_edit' }

type Props = {
	isDisabled: boolean
	onMsg: (msg: Msg) => void
}

export const Edit = ({ isDisabled, onMsg }: Props) => {
	const { t } = useTranslation()

	return (
		<ContextMenu.Item
			icon={Icons.Edit2}
			variant={isDisabled ? 'disabled' : 'normal'}
			onClick={() => onMsg({ type: 'on_click_edit' })}
		>
			{t('labels.editPublishedPost', 'Edit')}
		</ContextMenu.Item>
	)
}
