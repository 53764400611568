import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Nav, Button, Modal } from '@prostpost/uikit'

import type { Tabs } from '../../types'

type Props = {
	currentTab: Tabs
	onClose: () => void
	setCurrentTab: (tab: Tabs) => void
}

export const Header = ({ currentTab, setCurrentTab, onClose }: Props) => {
	const { t } = useTranslation()

	const getNavButtonProps = useCallback(
		(tab: Tabs) => ({
			state: currentTab === tab ? ('active' as const) : ('normal' as const),
			onClick: () => setCurrentTab(tab),
		}),
		[currentTab],
	)

	return (
		<Modal.Header>
			<Modal.Title>{t('user.settings.title', 'Settings')}</Modal.Title>
			<Nav variant="dimmed">
				<Nav.Item variant="plain">
					<Button.Nav {...getNavButtonProps('account')}>
						{t('userSettings.nav.account', 'Account')}
					</Button.Nav>
				</Nav.Item>
				<Nav.Item variant="plain">
					<Button.Nav {...getNavButtonProps('plan')}>{t('userSettings.nav.plan', 'Plan')}</Button.Nav>
				</Nav.Item>
				<Nav.Item variant="plain">
					<Button.Nav {...getNavButtonProps('preferences')}>
						{t('userSettings.nav.preferences', 'Preferences')}
					</Button.Nav>
				</Nav.Item>
			</Nav>
			<Modal.Close onClick={onClose} />
		</Modal.Header>
	)
}
