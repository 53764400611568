import { createContext, useContext } from 'react'

import type { StoreBot } from './store'

export const BotStoreContext = createContext<StoreBot | undefined>(undefined)

export const useBotStore = () => {
	const store = useContext(BotStoreContext)
	if (store === undefined) {
		throw Error('useBotStore can be used only within BotStoreContext')
	}
	return store
}
