import { z } from 'zod'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import * as Icons from 'lucide-react'

import { useToast } from '@prostpost/toast'
import { Input } from '@prostpost/uikit-next'
import { Modal, Heading, Paragraph } from '@prostpost/uikit'

import { config } from 'app/config'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useZodValidators, useSupabaseAuth } from 'app/domains/User/hooks'

import { routesUrls } from 'app/routes/urls'

type Msg = { type: 'on_success' } | { type: 'on_close' }
type Props = {
	onMsg: (msg: Msg) => void
}

type FormData = {
	email: string
}

const useToasts = () => {
	const { t } = useTranslation()

	const changeEmailFailed = useToast({
		type: 'error',
		text: t('notify:auth.changeEmail.error', 'Unable to change email. Please try again'),
	})

	const emailChangeLinkSent = useToast({
		type: 'success',
		text: t('notify:auth.emailChangeLinkSent.success', 'Confirmation link sent to your email'),
	})

	return {
		emailChangeLinkSent,
		changeEmailFailed,
	}
}

export const ChangeEmail = ({ onMsg }: Props) => {
	const { t } = useTranslation()
	const { zodEmail } = useZodValidators()

	const toasts = useToasts()

	const auth = useSupabaseAuth()
	const user = useOnboardedUserStore()

	const [isLoading, setIsLoading] = useState(false)

	const formSchema: z.ZodSchema<FormData> = z.object({
		email: z.string().min(1, zodEmail.nonemptyMsg(t)).email(zodEmail.invalidMsg(t)),
	})

	const {
		control,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		reValidateMode: 'onSubmit',
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
		},
	})

	const onChangeEmail = async (data: FormData) => {
		if (data.email === user.supabaseUser.email) {
			setError('email', {
				message: t('notify:auth.sameEmail.error', 'New email should be different from the old email'),
			})
			return
		}

		try {
			setIsLoading(true)
			const { error } = await auth.updateUser(
				{
					email: data.email,
				},
				{
					emailRedirectTo: `${config.constants.DOMAIN}${routesUrls.inbox}`,
				},
			)

			setIsLoading(false)
			if (error) {
				console.error(error)
				toasts.changeEmailFailed.show()
				return
			}

			toasts.emailChangeLinkSent.show()
			onMsg({ type: 'on_success' })
		} catch (error: unknown) {
			setIsLoading(false)
			console.error(error)
			toasts.changeEmailFailed.show()
		}
	}

	return (
		<Modal variant="confirm">
			<Modal.Close onClick={() => onMsg({ type: 'on_close' })} />
			<Modal.Content>
				<Heading h={4}>{t('userSettings.security.emailChangeTitle', 'Enter new email')}</Heading>
				<Paragraph mb={2} size={14} color="blue_40">
					{t(
						'userSettings.security.emailChangeDescription2',
						'You will receive a confirmation link to your email',
					)}
				</Paragraph>
				<form>
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<Input
								size="xs"
								placeholder={
									user.supabaseUser.email ??
									t('userSettings.security.emailChangePlaceholder', 'Email')
								}
								variant={errors.email ? 'error' : 'normal'}
								message={errors.email?.message}
								useIcon={Icons.Mail}
								{...field}
								onChange={value => {
									field.onChange(value)
									clearErrors()
								}}
							/>
						)}
					/>
				</form>
			</Modal.Content>
			<Modal.ConfirmSecondaryAction onClick={() => onMsg({ type: 'on_close' })}>
				{t('channel.settings.archiveConfirm.secondaryAction', 'Cancel')}
			</Modal.ConfirmSecondaryAction>
			<Modal.ConfirmPrimaryAction state={isLoading ? 'pending' : 'normal'} onClick={handleSubmit(onChangeEmail)}>
				{t('userSettings.security.emailChangeAction', 'Get link')}
			</Modal.ConfirmPrimaryAction>
		</Modal>
	)
}
