import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { Modal } from '@prostpost/uikit'

import { usePost } from 'app/domains/Post/api'
import { DraftEditorReadonly } from 'app/domains/Draft/features/DraftEditorReadonly'
import type { DraftNotScheduled } from 'app/domains/Draft'

import { ReactModal } from 'app/modals'

import { Actions } from './Actions'

type Msg =
	| { type: 'on_close_post_readonly_modal'; draftUuid: string }
	| { type: 'on_post_loaded_for_readonly_modal'; draftUuid: string }
	| { type: 'on_post_loading_failed_for_readonly_modal'; draftUuid: string }
	| { type: 'on_open_post_in_editor'; draftUuid: string; draftType: 'INTERNAL' | 'SCHEDULED' }
	| { type: 'on_click_copy_as_draft'; draft: DraftNotScheduled }

type Props =
	| {
			isTriggered: false
	  }
	| {
			isTriggered: true
			draftUuid: string
			draftType: 'INTERNAL' | 'SCHEDULED'
			onMsg: (msg: Msg) => void
	  }

export const PostReadonlyModal = (props: Props) => {
	const { t } = useTranslation()

	const failedFetchingPostToast = useToast({
		type: 'error',
		text: t('content:post.openInEditor.loadingFailed', 'Unable to get draft'),
	})

	const { postQuery } = usePost(props.isTriggered ? props.draftUuid : '', { enabled: props.isTriggered })

	useEffect(() => {
		if (props.isTriggered) {
			switch (postQuery.status) {
				case 'idle':
				case 'loading':
					break
				case 'error':
					failedFetchingPostToast.show()
					props.onMsg({ type: 'on_post_loading_failed_for_readonly_modal', draftUuid: props.draftUuid })
					break
				case 'success':
					props.onMsg({ type: 'on_post_loaded_for_readonly_modal', draftUuid: postQuery.data.uuid })

					break
				default:
					notReachable(postQuery)
			}
		}
	}, [props.isTriggered, postQuery.status])

	const onClose = () => {
		if (props.isTriggered) {
			if (props.draftUuid) {
				props.onMsg({ type: 'on_close_post_readonly_modal', draftUuid: props.draftUuid })
			} else {
				console.error('No draftUuid is given to close PostReadmeModal')
			}
		}
	}

	return (
		<ReactModal
			isOpen={props.isTriggered && postQuery.status === 'success'}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			onRequestClose={e => {
				e.stopPropagation()
				e.preventDefault()
				onClose()
			}}
		>
			<Modal variant="content" mobileScrollOffset={94}>
				<Modal.Header>
					<Modal.Title>
						{props.isTriggered ? (
							<Actions
								postUuid={props.draftUuid}
								postType={props.draftType}
								isPostLoaded={postQuery.status === 'success'}
								onMsg={msg => {
									switch (msg.type) {
										case 'on_open_post_in_editor':
											props.isTriggered &&
												props.onMsg({
													type: 'on_open_post_in_editor',
													draftUuid: props.draftUuid,
													draftType: props.draftType,
												})
											break
										case 'on_click_copy_as_draft':
											props.isTriggered && props.onMsg(msg)
											break
										default:
											notReachable(msg)
									}
								}}
							/>
						) : (
							<Actions.Skeleton />
						)}
					</Modal.Title>
					<Modal.Close onClick={onClose} />
				</Modal.Header>

				<Modal.Content>
					{(() => {
						switch (postQuery.status) {
							case 'idle':
							case 'error':
							case 'loading':
								return null
							case 'success':
								return <DraftEditorReadonly draft={postQuery.data} />
							default:
								return notReachable(postQuery)
						}
					})()}
				</Modal.Content>
			</Modal>
		</ReactModal>
	)
}
