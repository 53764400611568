import { Trans, useTranslation } from 'react-i18next'

import { useOpenWindow } from '@prostpost/utils'
import { VStack, Heading, Paragraph, TextLink, TextList } from '@prostpost/uikit'

import { useTelegramBotName, useTelegramBotLink } from 'app/domains/Telegram/hooks'

type Props = {
	title?: string | React.ReactElement
}

export const AddChannelInstruction = ({ title }: Props) => {
	const { t } = useTranslation()
	const openNewWindow = useOpenWindow()
	const telegramBotName = useTelegramBotName()
	return (
		<VStack space={3}>
			<Heading h={3}>{title || t('dashboard.addNewChannel.title', 'Let’s add your new channel')}</Heading>
			<Paragraph size={14} color="blue_40">
				{t(
					'channel.addChannel.instruction.description',
					'Prostpost uses Bot API to work with channel’s content. Please make our bot an administrator of your channel and allow to work with messages.',
				)}
			</Paragraph>

			<TextList variant="numbered" startWithZero={false} size={14} padding={1}>
				<TextList.Item>
					<Trans
						i18nKey="channel.addChannel.instruction.findBot2"
						values={{ botName: `@${telegramBotName}` }}
					>
						{'Add '}
						<TextLink isExternal variant="emphasized" onClick={() => openNewWindow(useTelegramBotLink())}>
							{'{{botName}}'}
						</TextLink>
						{" to Telegram account and make it a channel's administrators"}
					</Trans>
				</TextList.Item>
				<TextList.Item>
					{t(
						'channel.addChannel.instruction.setPermissions',
						'Allow Poster Bot to publish and edit messages',
					)}
				</TextList.Item>
				<TextList.Item>
					{t(
						'channel.addChannel.instruction.enterChannelName',
						'Give us a link to your channel and we will add it to your list',
					)}
				</TextList.Item>
			</TextList>
		</VStack>
	)
}
