import styled from '@emotion/styled'

import { Flex, Text } from '@prostpost/uikit'

type Props = {
	isSelected: boolean
	children: string
	onClick: () => void
}

const Container = styled(Flex)`
	border: none;

	&:hover {
		background: ${({ theme }) => theme.colors.blue_10};
	}
`

export const ListItem = ({ children, isSelected, onClick }: Props) => {
	return isSelected ? (
		<Container as="button" align="center" bg="blue_10" borderRadius="small" onClick={onClick} px={2} py={1}>
			<Text size={14} color="blue_100">
				{children}
			</Text>
		</Container>
	) : (
		<Container as="button" align="center" bg="white_100" borderRadius="small" onClick={onClick} px={2} py={1}>
			<Text size={14} color="black_80">
				{children}
			</Text>
		</Container>
	)
}
