import moment from 'moment'
import { useState, useEffect } from 'react'
import type { Moment } from 'moment'
import type { UseQueryOptions } from 'react-query'

import { useCalendarCapacity } from 'app/domains/Statistics/api'
import { useUserUtcOffset } from 'app/domains/UserPreferences/hooks'

type Options = Pick<UseQueryOptions, 'enabled'>

type ReturnHookType = {
	calendarCapacityQuery: ReturnType<typeof useCalendarCapacity>['calendarCapacityQuery']
	setCalendarVisibleMonth: (date: Moment) => void
}

export const usePostsSchedulingCapacity = (options: Options = {}): ReturnHookType => {
	const [calendarVisibleMonth, setCalendarVisibleMonth] = useState<Moment>(moment.utc())
	const utcOffset = useUserUtcOffset()
	const { calendarCapacityQuery } = useCalendarCapacity({
		utcOffset,
		date: calendarVisibleMonth,
		options: {
			...options,
			enabled: !!calendarVisibleMonth && (options.enabled != null ? options.enabled : true),
		},
	})

	// it fires a request twice (one above on changing date and on here as a refetch)
	// but it's better twice then not at all so will leave it as is for now
	useEffect(() => {
		calendarCapacityQuery.refetch().catch(console.error)
	}, [calendarVisibleMonth])

	return {
		setCalendarVisibleMonth,
		calendarCapacityQuery,
	}
}
