import { z } from 'zod'

import type {
	SupportTicket,
	SupportCategory,
	SupportChat,
	SupportTicketsMeta,
	FeedbackMessage,
	FeedbackSummary,
} from '..'

export const SupportCategorySchema: z.ZodSchema<SupportCategory, z.ZodTypeDef, unknown> = z.object({
	id: z.number(),
	name: z.enum(['question', 'bug', 'review', 'feature_request']),
})

export const SupportChatSchema: z.ZodSchema<SupportChat, z.ZodTypeDef, unknown> = z.object({
	id: z.number(),
	userUuid: z.string(),
	username: z.optional(z.string()),
	name: z.optional(z.string()),
	lastName: z.optional(z.string()),
	lang: z.optional(z.string()),
})

export const SupportTicketSchema: z.ZodSchema<SupportTicket, z.ZodTypeDef, unknown> = z
	.object({
		id: z.number(),
		parentId: z.number(),
		text: z.optional(z.string()),
		answer: z.optional(z.string()),
		imagesUrls: z.optional(z.array(z.string())),
		active: z.boolean(),
		closed: z.boolean(),
		submitted: z.optional(z.boolean()),
		replyNeeded: z.boolean(),
		createdAt: z.string(),
		updatedAt: z.optional(z.string()),
		category: SupportCategorySchema,
		chat: SupportChatSchema,
	})
	.transform(({ imagesUrls, active, closed, submitted, replyNeeded, ...response }) => ({
		...response,
		images: imagesUrls || [],
		isActive: active,
		isClosed: closed,
		isSubmitted: submitted || false,
		isReplyNeeded: replyNeeded,
	}))

export const SupportTicketsMetaSchema: z.ZodSchema<SupportTicketsMeta, z.ZodTypeDef, unknown> = z
	.object({
		page: z.object({
			items: z.number(),
			size: z.number(),
			pages: z.number(),
			number: z.number(),
		}),
	})
	.transform(({ page }) => ({
		pages: page.pages,
		currentPage: page.number,
	}))

export const FeedbackMessageSchema: z.ZodSchema<FeedbackMessage, z.ZodTypeDef, unknown> = z.object({
	id: z.number(),
	rating: z.number(),
	message: z.optional(z.string()),
	category: SupportCategorySchema,
	createdAt: z.string(),
	userUuid: z.string(),
})

export const FeedbackSummarySchema: z.ZodSchema<FeedbackSummary, z.ZodTypeDef, unknown> = z.object({
	rating: z.record(z.string(), z.number()),
	count: z.number(),
})
