import { captureException } from '@sentry/react'

import { notReachable } from '@prostpost/utils'

import { useBotLink } from '../api'
import type { BotLink } from '..'

export const useGetBotLink = (): BotLink | false | undefined => {
	const { botLinkQuery } = useBotLink()

	switch (botLinkQuery.status) {
		case 'idle':
		case 'loading':
			return undefined
		case 'success':
			return botLinkQuery.data
		case 'error':
			// Do not fail on error
			switch (botLinkQuery.error.code) {
				case 'UNKNOWN_ERROR':
				case 'NOT_VALID_ERROR':
					captureException(botLinkQuery.error)
					return false
				case 'CHAT_NOT_FOUND':
					return false
				default:
					return notReachable(botLinkQuery.error.code)
			}
		default:
			return notReachable(botLinkQuery)
	}
}
