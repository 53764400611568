import styled from '@emotion/styled'
import { useState } from 'react'
import { useTheme } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Trash2 } from 'lucide-react'

import { getSpace, getAnimation, getColor } from '@prostpost/css'
import { Box, Flex, Text, Paragraph, Button } from '@prostpost/uikit'
import { truncate, dateToPeriod } from '@prostpost/utils'

import { ColorTagLabel } from 'app/domains/Draft/components'
import type { TemplateShortInfo } from 'app/domains/Template'

import { ConfirmationModal } from './ConfirmationModal'

const Actions = styled(Box)`
	position: absolute;
	top: 15px;
	right: ${getSpace(4)};

	opacity: 0;
	transition: opacity ${getAnimation('tt_tf')};
`

const Container = styled(Box)`
	position: relative;

	background: ${getColor('white_100')};
	transition: background ${getAnimation('tt_tf')};

	&:hover {
		background: ${getColor('blue_10')};
		${Actions} {
			opacity: 1;
			transition: opacity ${getAnimation('tt_tf')};
		}
	}
`

type Props = {
	template: TemplateShortInfo
	onRemoved: (template: TemplateShortInfo) => void
	onClick: (template: TemplateShortInfo) => void
}

export const TemplateListItem = observer(function TemplateListItem({ template, onClick, onRemoved }: Props) {
	const { t } = useTranslation()

	const [isOpen, setIsOpen] = useState(false)

	return (
		<>
			<Container px={4} py={3} borderRadius="mid" onClick={() => onClick(template)}>
				<Flex w="100%" align="center" just="space-between">
					<ColorTagLabel colorTag={template.colorTag} />
					<Text ml={2} size={14} mr="auto" color="black_80">
						{truncate(template.title, 24)}
					</Text>
				</Flex>
				<Paragraph pl="24px" size={12} color="blue_40">
					{t('content:templates.list.createdAt', 'created {{createdAt}}', {
						createdAt: dateToPeriod(template.createdAt),
					})}
				</Paragraph>
				<DeleteTemplateButton onClick={() => setIsOpen(true)} />
			</Container>
			<ConfirmationModal
				isOpen={isOpen}
				template={template}
				onRemoved={onRemoved}
				onClose={() => setIsOpen(false)}
			/>
		</>
	)
})

const DeleteTemplateButton = ({ onClick }: { onClick: () => void }) => {
	const theme = useTheme()
	return (
		<Actions mr={-1}>
			<Button.Icon
				size="small"
				onClick={e => {
					e.stopPropagation()
					e.preventDefault()
					onClick()
				}}
			>
				<Trash2 size={14} color={theme.colors.blue_40} />
			</Button.Icon>
		</Actions>
	)
}
