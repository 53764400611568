import type { FC } from 'react'
// import { notReachable } from '@prostpost/utils'

// import { useChannelRecentTags } from 'app/domains/Channel/api'
// import { useChannelFromUrlExistsAndActive } from 'app/domains/Channel/hooks'
import type { ChannelRecentTags } from 'app/domains/Channel'

type Props = {
	children: (props: { recentTags: ChannelRecentTags }) => React.ReactNode
}

export const WithRecentTags: FC<Props> = ({ children }) => {
	// const channelName = useChannelFromUrlExistsAndActive()
	// const { channelRecentTagsQuery } = useChannelRecentTags(channelName || '', { enabled: !!channelName })
	// switch (channelRecentTagsQuery.status) {
	// 	case 'idle':
	// 	case 'loading':
	// 	case 'error':
	// 	case 'success':
	// 		return children({ recentTags: channelRecentTagsQuery.data || [] })
	// 	default:
	// 		return notReachable(channelRecentTagsQuery)
	// }
	return children({ recentTags: [] })
}
