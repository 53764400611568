import { z } from 'zod'

import type { UploadMediaResponse, URLPreview } from '..'

export const UploadImagesSchema: z.ZodSchema<UploadMediaResponse, z.ZodTypeDef, unknown> = z
	.object({
		user: z.string().uuid(),
		urls: z.array(z.string()),
	})
	.transform(response => ({
		userUuid: response.user,
		urls: response.urls,
	}))

export const UrlPreviewSchema: z.ZodSchema<URLPreview, z.ZodTypeDef, unknown> = z.object({
	url: z.string().url(),
	type: z.string().optional(),
	site: z.string().optional(),
	title: z.string().optional(),
	desc: z.string().optional(),
	favicon: z.string().optional(),
	image: z.string().url().optional(),
})
