import i18n from 'i18next'
// import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
// import { Tolgee, I18nextPlugin, DevTools, BackendFetch, withTolgee } from '@tolgee/i18next'
// import type { TreeTranslationsData } from '@tolgee/i18next'
import Backend from 'i18next-http-backend'

const initPureI18Next = () => {
	i18n.use(LanguageDetector)
		.use(initReactI18next)
		.use(Backend)
		.init({
			fallbackLng: 'en',
			supportedLngs: ['en', 'ru'],
			returnNull: false,
			returnEmptyString: false,
			ns: ['common', 'titles', 'notify', 'aria'],
			defaultNS: 'common',
			backend: {
				loadPath: '/locales/{{lng}}/{{ns}}.json',
			},
			react: {
				useSuspense: false,
			},
		})
		.catch(console.error)
}

export const initLocalisation = () => {
	initPureI18Next()
}

export default i18n

// Below is commented out configuration for Tolgee
//
// !!! THAT FREEZES THE APP ON SOME RANDOM KEYS - DO NOT ENABLE IT NOW !!!

// type Language = 'en' | 'ru'
// type Namespace = 'common' | 'titles' | 'notify' | 'aria'

// const tolgeeFetchStaticData = (langs: Language[], namespaces: Namespace[]) => {
// 	const staticData: Record<string, () => Promise<TreeTranslationsData>> = {}
// 	langs.forEach(lang => {
// 		namespaces.forEach(ns => {
// 			staticData[`${lang}:${ns}`] = () => fetch(`/locales/${lang}/${ns}.json`).then(r => r.json())
// 		})
// 	})
// 	return staticData
// }

// DevTools automatically omitted with production bundle
// const tolgee = Tolgee()
// 	.use(DevTools())
// 	.use(I18nextPlugin())
// 	.use(import.meta.env.NODE_ENV === 'development' ? BackendFetch() : undefined)
// 	.init({
// 		defaultLanguage: 'en',
// 		staticData: tolgeeFetchStaticData(['en', 'ru'], ['aria', 'common', 'notify', 'titles']),
// 	})

// const initI18NextWithTolgee = () => {
// 	withTolgee(i18n, tolgee)
// 		.use(ICU)
// 		.use(LanguageDetector)
// 		.use(initReactI18next)
// 		.init({
// 			fallbackLng: 'en',
// 			supportedLngs: ['en', 'ru'],
// 			returnNull: false,
// 			returnEmptyString: false,
// 			ns: ['common', 'titles', 'notify', 'aria'],
// 			defaultNS: 'common',
// 			react: {
// 				useSuspense: false,
// 			},
// 		})
// }
