import { toJS, runInAction } from 'mobx'
// import { canUseDOM, SESSION_STORAGE_DATETIME_USE_24 } from '@prostpost/utils'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'

export const useUserTimeFormat = () => {
	const user = useOnboardedUserStore()
	// const use24 = canUseDOM() && sessionStorage.getItem(SESSION_STORAGE_DATETIME_USE_24) !== '0'
	return toJS(runInAction(() => user.preferences.timeFormat24)) ? '24h' : '12h'
}
