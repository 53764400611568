import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import type { Icon } from 'react-feather'

import { notReachable } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'
import { Box, Button, HStack } from '@prostpost/uikit'
import { Input } from '@prostpost/uikit-next'

import { useSetBotLink, useBotLinkForm } from 'app/domains/Bot/features/LinkBotToUser/hooks'

export type Msg =
	| { type: 'on_bot_is_linked_to_user' }
	| { type: 'on_bot_link_error'; error: ReturnType<typeof useSetBotLink>['botLinkError'] }

type Props = {
	size: 'normal' | 'mid'
	submitButton: { label: string; icon: Icon }
	onMsg: (msg: Msg) => void
}

type WrapperProps = {
	isMobile: boolean
	children: React.ReactNode | React.ReactNode[]
}

const Wrapper = ({ isMobile, children }: WrapperProps) =>
	isMobile ? (
		<>{children}</>
	) : (
		<HStack space={4} as="form" w="100%" maxW="550px">
			{children}
		</HStack>
	)

const ButtonWrapper = ({ isMobile, children }: WrapperProps) =>
	isMobile ? (
		<Box position="absolute" bottom={8} left={5} w="calc(100% - 48px)">
			{children}
		</Box>
	) : (
		<>{children}</>
	)

export const LinkBotToUserForm = ({ size, submitButton, onMsg }: Props) => {
	const UseSubmitIcon = submitButton.icon

	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'

	const { botLinkStatus, setBotLink, botLinkError } = useSetBotLink()
	const form = useBotLinkForm({ onSubmit: setBotLink })

	useEffect(() => {
		switch (botLinkStatus.type) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				form.fields.code.setError(botLinkStatus.message)
				onMsg({ type: 'on_bot_link_error', error: botLinkError })
				break
			case 'success':
				onMsg({ type: 'on_bot_is_linked_to_user' })
				break
			default:
				notReachable(botLinkStatus)
		}
	}, [botLinkStatus])

	return (
		<Wrapper isMobile={isMobile}>
			<Controller
				name="code"
				control={form.control}
				render={({ field }) => (
					<Input
						size={size === 'mid' ? 'xs' : 'md'}
						variant={form.fields.code.error ? 'error' : 'normal'}
						message={form.fields.code.error}
						{...form.fields.code.inputProps}
						{...field}
					/>
				)}
			/>

			<ButtonWrapper isMobile={isMobile}>
				<Button.Action
					submit
					size={size}
					variant="primary"
					iconPosition="right"
					onClick={form.onSubmit}
					fullWidth={isMobile}
					state={botLinkStatus.type === 'loading' ? 'pending' : 'normal'}
					icon={(() => {
						switch (size) {
							case 'normal':
								return <UseSubmitIcon size={20} strokeWidth={2.5} />
							case 'mid':
								return <UseSubmitIcon size={18} strokeWidth={3} />
							default:
								return notReachable(size)
						}
					})()}
				>
					{submitButton.label}
				</Button.Action>
			</ButtonWrapper>
		</Wrapper>
	)
}
