import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Heading } from '@prostpost/uikit'

import { useBotStore } from 'app/domains/Bot/store'

export const LinkBotToUserHeader = observer(function LinkBotToUserHeader() {
	const { t } = useTranslation()
	const bot = useBotStore()
	return bot.link ? (
		<Heading h={4}>{t('content:bot.linkBotToUser.modal.reconnectAccount', 'Reconnect account')}</Heading>
	) : (
		<Heading h={4}>{t('content:bot.linkBotToUser.modal.connectAccount', 'Connect your account')}</Heading>
	)
})
