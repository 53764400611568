import { useMemo } from 'react'

import { useChannelsStore } from 'app/domains/Channel/store'

import { useChannelNameFromUrl } from './useChannelNameFromUrl'

export const useChannelFromUrlExistsAndActive = (): string | undefined => {
	const channels = useChannelsStore()
	const channelName = useChannelNameFromUrl()

	return useMemo(() => {
		return channelName && channels.isChannelActive(channelName) ? channelName : undefined
	}, [channelName])
}
