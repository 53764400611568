import { Navigate, Outlet } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'

import {
	WithAuth,
	RouteSigninLoadable,
	RouteSignupLoadable,
	RouteSigninCodeVerificationLoadable,
	RouteSigninCodeRequestLoadable,
} from './Auth'
import {
	WithPolicy,
	RoutePolicyCookiesLoadable,
	RoutePolicyDisclaimerLoadable,
	RoutePolicyPrivacyLoadable,
	RoutePolicyRefundLoadable,
	RoutePolicyTermsLoadable,
	RoutePolicyUseLoadable,
} from './Policy'
import { RouteInvalidRequestLoadable, RouteUnsubscribeEmailLoadable } from './EmailConfirmations'
import { RouteInboxLoadable } from './Inbox'
import { WithPrivateAccess, WithRecentTags } from './Wrappers'
import { RouteSupportLoadable } from './Support'
// import { RouteDashboardLoadable } from './Dashboard'
import {
	WithOnboarding,
	RouteBetaOnboardingLoadable,
	RouteSuccessOnboardingLoadable,
	RouteLinkBotOnboardingLoadable,
	RouteAddChannelOnboardingLoadable,
} from './Onboarding'
import {
	WithChannel,
	RouteChannelEditorLoadable,
	RouteChannelSettingsLoadable,
	RouteChannelOverviewLoadable,
} from './Channel'
import { RootRoute } from './root'

export const publicRoutesConfig: RouteObject[] = [
	{
		path: 'legal',
		element: <WithPolicy />,
		children: [
			{
				path: 'privacy',
				element: <RoutePolicyPrivacyLoadable />,
			},
			{
				path: 'terms',
				element: <RoutePolicyTermsLoadable />,
			},
			{
				path: 'cookie',
				element: <RoutePolicyCookiesLoadable />,
			},
			{
				path: 'refund',
				element: <RoutePolicyRefundLoadable />,
			},
			{
				path: 'use',
				element: <RoutePolicyUseLoadable />,
			},
			{
				path: 'disclaimer',
				element: <RoutePolicyDisclaimerLoadable />,
			},
		],
	},

	// Email confirmations callbacks
	{
		path: 'callback',
		element: <Outlet />,
		children: [
			{
				path: 'unsubscribe',
				element: <RouteUnsubscribeEmailLoadable />,
			},
			{
				path: 'invalidrequest',
				element: <RouteInvalidRequestLoadable />,
			},
		],
	},
]

export const authRoutesConfig: RouteObject[] = [
	{
		path: 'auth',
		element: <WithAuth />,
		children: [
			{
				path: 'in',
				element: <RouteSigninLoadable />,
			},
			{
				index: true,
				path: 'up',
				element: <RouteSignupLoadable />,
			},
			{
				path: 'in/otp-request',
				element: <RouteSigninCodeRequestLoadable />,
			},
			{
				path: 'in/otp-verify',
				element: <RouteSigninCodeVerificationLoadable />,
			},
			{
				path: '',
				element: <Navigate to="up" />,
			},
		],
	},
	{
		path: '*',
		element: <RootRoute to="/auth/up" />,
	},
]

// Pass to matchRoutes for SSR to pre-fetch data
// or useRoutes for client side to render routes (don't do it manually - use Routes below)
export const onboardedUserRoutesConfig: RouteObject[] = [
	// {
	// 	index: true,
	// 	path: 'dashboard',
	// 	element: <RouteDashboardLoadable />,
	// },
	{
		path: '/dashboard',
		element: <RootRoute to="/inbox" />,
	},
	{
		index: true,
		path: 'inbox',
		element: <RouteInboxLoadable />,
	},
	{
		element: <WithRecentTags>{props => <WithChannel {...props} />}</WithRecentTags>,
		children: [
			{
				path: 'overview/:channelName',
				element: <RouteChannelOverviewLoadable />,
			},
			{
				path: 'editor/:channelName',
				element: <RouteChannelEditorLoadable />,
			},
			{
				path: 'settings/:channelName',
				element: <RouteChannelSettingsLoadable />,
			},
		],
	},
	{
		path: 'private',
		element: <WithPrivateAccess />,
		children: [
			{
				path: 'support',
				element: <RouteSupportLoadable />,
			},
		],
	},
	{
		path: '*',
		element: <RootRoute to="/inbox" />,
	},
]

export const notOnboardedUserRoutesConfig: RouteObject[] = [
	{
		path: 'onboard',
		element: <WithOnboarding />,
		children: [
			{
				path: 'channel',
				element: <RouteAddChannelOnboardingLoadable />,
			},
			{
				path: 'link',
				element: <RouteLinkBotOnboardingLoadable />,
			},
			{
				path: 'success',
				element: <RouteSuccessOnboardingLoadable />,
			},
			{
				path: 'beta',
				element: <RouteBetaOnboardingLoadable />,
			},
			{
				path: '',
				element: <Navigate to="channel" />,
			},
		],
	},
	{
		path: '*',
		element: <RootRoute to="/onboard" />,
	},
]

export const serverRoutesConfig = [
	...publicRoutesConfig,
	...authRoutesConfig,
	...onboardedUserRoutesConfig.slice(0, -1),
	...notOnboardedUserRoutesConfig.slice(0, -1),
	{
		path: '*',
		element: <RootRoute to="/dashboard" />,
	},
]
