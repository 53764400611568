import React from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { useOpenWindow } from '@prostpost/utils'
import { getSpace, mediaQueries, MEDIA } from '@prostpost/css'
import { Hr, Flex, Nav, Button, Layout, Text, Logo } from '@prostpost/uikit'

import { useProstpostChannelLink } from 'app/domains/Telegram/hooks'

const Container = styled(Flex)`
	${mediaQueries[MEDIA.NOT_DESKTOP]} {
		flex-direction: column;
		align-items: flex-start;

		> nav {
			margin: ${getSpace(5)} 0 ${getSpace(5)} -${getSpace(3)};
		}
	}
`

export const Footer = ({
	children,
	...contentProps
}: { children: React.ReactElement[] } & Omit<React.ComponentProps<typeof Layout.Content>, 'variant'>) => (
	<Layout.Content as="footer" type="box" variant="public" {...contentProps}>
		<Hr color="blue_20" />
		<Container pt={6} pb={6} align="center" just="space-between">
			{children}
		</Container>
	</Layout.Content>
)

type Props = {
	policyRouteUrl: string
	contactUsRouteUrl: string
	children: React.ReactNode | React.ReactNode[]
}

export const WithFooter = ({ contactUsRouteUrl, policyRouteUrl, children }: Props) => {
	const { t } = useTranslation()
	const openNewWindow = useOpenWindow()
	return (
		<>
			{children}
			<Footer>
				<Logo variant="dimmed" mt={-3} />
				<Nav variant="dimmed" mediaVerticalOnRes="not-desktop">
					<Nav.Item variant="plain">
						<Button.Nav to={contactUsRouteUrl} title={t('footer.contact', 'Contact us')} />
					</Nav.Item>
					<Nav.Item variant="plain">
						<Button.Nav
							title={t('footer.join', 'Join our channel')}
							onClick={() => openNewWindow(useProstpostChannelLink())}
						/>
					</Nav.Item>
					<Nav.Item variant="plain">
						<Button.Nav to={policyRouteUrl} title={t('footer.terms', 'Terms and Privacy')} />
					</Nav.Item>
				</Nav>
				<Text color="blue_40" size={14}>{`©${moment.utc().year()} - Prostpost`}</Text>
			</Footer>
		</>
	)
}
