import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { getSpace } from '@prostpost/css'

type Props = {
	isHover?: boolean
	isSelected: boolean
	isFailedToPublish: boolean
	isSelectable: boolean
}

export const Wrapper = styled.div<Props>`
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: calc(100% - 1px); /* -1px to have tile's right border fully visible */
	max-width: 600px;
	padding: ${getSpace(2)} ${getSpace(3)};

	${({ isSelectable }) =>
		isSelectable
			? css`
					cursor: pointer;
				`
			: css`
					cursor: default;
				`}

	background: ${({ isFailedToPublish, theme }) =>
		isFailedToPublish ? `${theme.colors.red_100}17` : theme.colors.white_100};

	border-radius: ${({ theme }) => theme.radii.mid};

	> header > div[data-name=\"post-context-menu\"] {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}

	${({ isHover, isSelectable, isFailedToPublish, theme }) =>
		isHover && isSelectable
			? css`
					background: ${isFailedToPublish ? `${theme.colors.red_100}17` : theme.colors.blue_10};

					> header > div[data-name=\"post-context-menu\"] {
						visibility: visible;
						opacity: 1;
						transition: opacity 0.2s ease-in-out;
					}
				`
			: css``}

	${({ isSelectable, isFailedToPublish, theme }) =>
		isSelectable
			? css`
					&:hover {
						background: ${isFailedToPublish ? `${theme.colors.red_100}17` : theme.colors.blue_10};

						> header > div[data-name=\"post-context-menu\"] {
							visibility: visible;
							opacity: 1;
							transition: opacity 0.2s ease-in-out;
						}
					}
				`
			: css``}

	${({ isSelected, isSelectable, isFailedToPublish, theme }) =>
		isSelected && isSelectable
			? css`
					background: ${isFailedToPublish ? `${theme.colors.red_100}17` : theme.colors.blue_10};
					border: 2px solid ${isFailedToPublish ? theme.colors.red_100 : theme.colors.blue_100};
					box-shadow: 0 1px 8px 0 rgba(0 14 64 6%);
					transition:
						background 0.2s ease-in-out,
						border 0.2s ease-in-out,
						box-shadow 0.2s ease-in-out;
				`
			: css`
					border: 2px solid transparent;
					box-shadow: none;
					transition:
						background 0.2s ease-in-out,
						border 0.2s ease-in-out,
						box-shadow 0.2s ease-in-out;
				`}

	${({ theme, isFailedToPublish }) =>
		isFailedToPublish
			? css`
					/* Text */
					> p {
						color: ${theme.colors.black_80} !important;
					}

					/* Expanded content */
					> div:last-of-type {
						p,
						span {
							color: ${theme.colors.black_80} !important;
						}
					}
				`
			: css``}
`
