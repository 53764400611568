import { useRef } from 'react'
import { runInAction } from 'mobx'
import { useNavigate } from 'react-router-dom'

import { EditorStore } from 'app/domains/Draft/store'
import { useChannelFromUrlExistsAndActive } from 'app/domains/Channel/hooks'

import { routesUrls } from 'app/routes/urls'

export const useDefineEditorStore = () => {
	const navigate = useNavigate()
	const channelName = useChannelFromUrlExistsAndActive()

	const { current: editorStore } = useRef<EditorStore>(
		new EditorStore({
			options: {
				clearFromOfflineStorageAfterDays: 3,
				onContentSync: () => {
					// We don't use draft routes now because otherwise you have to solve a problem with finding a draft
					// in a list of drafts on the left and it can be anywhere
					// TODO: Think about to open a standalone editor when a draft is open via a direct URL
					// navigate(channelRoutesMap.getDraftRoute(channel.name, uuid), { replace: true })
				},
				onContentClean: () => {
					runInAction(() => {
						channelName
							? navigate(routesUrls.channel.getEditorRoute(channelName), { replace: true })
							: navigate(routesUrls.inbox, { replace: true })
					})
				},
			},
		}),
	)

	return editorStore
}
