import { History } from './History'
import { Internal } from './Internal'
import { InternalPublishing } from './InternalPublishing'
import { Scheduled } from './Scheduled'

export const PostPreview = {
	History,
	Internal,
	InternalPublishing,
	Scheduled,
}
