import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { useCurrentMedia } from '@prostpost/css'
import { Modal, Button, Box, VStack, Heading, Paragraph } from '@prostpost/uikit'

import { ReactModal } from 'app/modals'
import { UserSettings } from 'app/modals/UserSettings'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'

import { AddChannelPlain } from '../Plain'
import type { Interface as AddChannelInterface, Msg as AddChannelMsg } from '../Plain'

export type Msg = AddChannelMsg | { type: 'on_add_channel_modal_close' }

type MsgProp = {
	onMsg: (msg: Msg) => void
}

export type Interface = AddChannelInterface & {
	isOpen: boolean
}

export const AddChannelModal = observer(({ isOpen, onMsg, ...props }: Interface & MsgProp) => {
	const theme = useTheme()
	const isMobile = useCurrentMedia() === 'MOBILE'
	const { t } = useTranslation()

	const channelsStore = useChannelsStore()
	const userStore = useOnboardedUserStore()

	const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false)

	// we need this check only once on initial opening
	// because otherwise it will be triggered after we just added a new channel and instead of closing the modal
	// and redirecting to a channel we will see limits reached modal
	const [isLimitReached] = useState(userStore.preferences.limits.channels > channelsStore.activeList.length)

	return (
		<>
			<ReactModal
				shouldCloseOnEsc
				shouldCloseOnOverlayClick
				isOpen={isOpen}
				onRequestClose={e => {
					e.stopPropagation()
					e.preventDefault()
					onMsg({ type: 'on_add_channel_modal_close' })
				}}
			>
				{isLimitReached ? (
					<Modal variant="content">
						<Modal.Content>
							<Box position="absolute" top="24px" right={isMobile ? '8px' : '-3px'}>
								<Button.Icon
									onClick={e => {
										e.stopPropagation()
										e.preventDefault()
										onMsg({ type: 'on_add_channel_modal_close' })
									}}
								>
									<Icons.X size={18} color={theme.colors.blue_40} strokeWidth={2.5} />
								</Button.Icon>
							</Box>
							<AddChannelPlain {...props} isFullWidth onMsg={onMsg} />
						</Modal.Content>
					</Modal>
				) : (
					<Modal variant="confirm">
						<Modal.Content>
							<Modal.Close onClick={() => onMsg({ type: 'on_add_channel_modal_close' })} />
							<VStack space={6} p={6} align="center">
								<Icons.HeartCrack size={48} strokeWidth={2} color={theme.colors.blue_40} />

								<VStack space={1}>
									<Heading centered h={4}>
										{t('content:addChannel.modal.limitReachedTitle', 'Channels limit reached')}
									</Heading>
									<Paragraph centered size={14}>
										{t(
											'content:addChannel.modal.limitReachedDesc',
											'You have reached your plan limit of channels. Please upgrade your plan to continue.',
										)}
									</Paragraph>
								</VStack>

								<Button.Action
									variant="primary"
									size="mid"
									icon={<Icons.ArrowBigUp strokeWidth={2.5} size={20} />}
									onClick={e => {
										e?.stopPropagation()
										e?.preventDefault()
										setIsUserSettingsModalOpen(true)
										onMsg({ type: 'on_add_channel_modal_close' })
									}}
								>
									{t('content:addChannel.modal.upgradePlan', 'Increase limits')}
								</Button.Action>
							</VStack>
						</Modal.Content>
					</Modal>
				)}
			</ReactModal>

			<UserSettings
				currentTab="plan"
				isOpen={isUserSettingsModalOpen}
				onClose={() => setIsUserSettingsModalOpen(false)}
			/>
		</>
	)
})
