import { useCurrentMedia } from '@prostpost/css'
import { HStack, Skeleton } from '@prostpost/uikit'

export const SkeletonChannelScreenHeader = () => {
	const isMobile = useCurrentMedia() === 'MOBILE'
	return (
		<>
			<HStack space={2} w="100%">
				<Skeleton isAnimated borderRadius="mid" width="120px" height={40} />
				{!isMobile ? <Skeleton isAnimated borderRadius="mid" width="200px" height={40} /> : null}
			</HStack>

			<HStack space={4} just="flex-end" w="100%">
				<Skeleton isAnimated borderRadius="mid" width={isMobile ? '63px' : '96px'} height={40} />
				<Skeleton isAnimated borderRadius="mid" width="87px" height={40} />
			</HStack>
		</>
	)
}
