import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Button, Paragraph, VStack, Heading } from '@prostpost/uikit'

const Glass = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	background: rgb(255 255 255 / 70%);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
	backdrop-filter: blur(7px);
`

const TextBg = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	background: rgb(255 255 255);
	background: linear-gradient(
		90deg,
		rgb(255 255 255 / 0%) 0%,
		rgb(255 255 255 / 100%) 50%,
		rgb(255 255 255 / 0%) 100%
	);
`

export const BotNotConnected = ({ onClickConnectBot }: { onClickConnectBot: () => void }) => {
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<Glass>
			<VStack space={6} p={6} align="center">
				<Icons.RefreshCwOff size={48} strokeWidth={2} color={theme.colors.blue_40} />

				<VStack as={TextBg} space={1}>
					<Heading centered h={4}>
						{t('content:editor.info.botNotConnectedTitle', 'Bot is not connected')}
					</Heading>
					<Paragraph centered size={14}>
						{t(
							'content:editor.info.botNotConnectedDesc',
							'Please add our telegram bot as an admin to your channel to be able to schedule and publish posts',
						)}
					</Paragraph>
				</VStack>

				<Button.Action
					variant="primary"
					size="mid"
					icon={<Icons.Plug strokeWidth={2.5} size={20} />}
					onClick={onClickConnectBot}
				>
					{t('content:editor.actions.connectBot', 'Connect Bot')}
				</Button.Action>
			</VStack>
		</Glass>
	)
}
