import localforage from 'localforage'
import { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'

import { Button, Paragraph, TextLink } from '@prostpost/uikit'

import { animation, StyledAbsoluteContainer, StyledCookieBannerContainer } from './styled'

const COOKIE_ACCEPTED = 'cookiesAccepted'

export const CookieBanner = ({
	onAccept,
	onClickLearnMore,
}: {
	onAccept: () => void
	onClickLearnMore: () => void
}) => {
	const { t } = useTranslation()
	const [accepted, setAccepted] = useState(true)

	useEffect(() => {
		localforage
			.getItem(COOKIE_ACCEPTED)
			.then(v => {
				if (!v) setAccepted(false)
				return null
			})
			.catch(e => {
				throw e
			})
	}, [])

	const onAcceptCookies = (): void => {
		setAccepted(true)
		localforage.setItem(COOKIE_ACCEPTED, true).catch(e => {
			throw e
		})
		onAccept()
	}

	return (
		<StyledAbsoluteContainer>
			<AnimatePresence>
				{!accepted && (
					<motion.div {...animation}>
						<StyledCookieBannerContainer just="space-between" align="center">
							<Paragraph size={14} color="white_100">
								{t('cookie.title', 'We use cookies to improve your experience')}
								<br />
								<Trans t={t} i18nKey="cookie.link">
									{'Learn more in our '}
									<TextLink variant="plain" color="yellow_100" onClick={onClickLearnMore}>
										{'privacy policy'}
									</TextLink>
									{'.'}
								</Trans>
							</Paragraph>

							<Button.Action
								size="mid"
								variant="secondary"
								title={t('cookie.accept', 'Accept')}
								onClick={onAcceptCookies}
							/>
						</StyledCookieBannerContainer>
					</motion.div>
				)}
			</AnimatePresence>
		</StyledAbsoluteContainer>
	)
}
