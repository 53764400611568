import axios from 'axios'

import { config as appConfig } from './'

export const axiosInstance = axios.create({
	baseURL: import.meta.env.VITE_API_DOMAIN_URL,
	headers: {
		'Content-Type': 'application/json',
	},
})

axiosInstance.interceptors.request.use(
	async config => {
		const record = await appConfig.supabase.client.auth.getSession()
		if (!record.data.session) return config
		const accessToken = record.data.session.access_token
		config.headers.Authorization = `Bearer ${accessToken}`
		config.withCredentials = true
		return config
	},
	error => {
		return Promise.reject(error)
	},
)
