type Quote = {
	author: string
	quote: string
}

export const QUOTES: Quote[] = [
	{
		author: 'Mark Twain',
		quote: 'The secret of getting ahead is getting started.',
	},
	{
		author: 'F. Scott Fitzgerald',
		quote: 'You don’t write because you want to say something, you write because you have something to say.',
	},
	{
		author: 'George Orwell',
		quote: 'Good prose is like a windowpane.',
	},
	{
		author: 'Leo Tolstoy',
		quote: 'The best stories don’t come from ‘good vs. bad’ but ‘good vs. good.’',
	},
	{
		author: 'Harper Lee',
		quote: 'The book to read is not the one that thinks for you but the one which makes you think.',
	},
]
