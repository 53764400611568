import { notReachable } from '@prostpost/utils'

import { PricingPlansTilesGroup } from './TilesGroup'

type Props = { variant: 'tiles-group' }

export const PricingPlansVariants = (props: Props) => {
	switch (props.variant) {
		case 'tiles-group':
			return <PricingPlansTilesGroup />
		default:
			return notReachable(props.variant)
	}
}
