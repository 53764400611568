import z from 'zod'

import type { Draft, DraftScheduled, DraftNotScheduled } from 'app/domains/Draft'

export const DraftScheduledSchema: z.ZodSchema<DraftScheduled, z.ZodTypeDef, unknown> = z
	.object({
		uuid: z.string(),
		text: z.optional(z.string()),
		mdText: z.optional(z.string()),
		webPage: z.optional(z.string()),
		images: z.optional(z.array(z.string())),
		tags: z.optional(z.array(z.string())),
		createdAt: z.string(),
		updatedAt: z.optional(z.string()),
		ad: z.optional(z.boolean()),
		silent: z.boolean(),
		colorTag: z.optional(z.string()),
		channelUuid: z.string(),
		publishAt: z.string(),
		scheduled: z.literal(true),
		unpublishedVersion: z.optional(z.string()),
		externalLink: z.optional(
			z.object({
				url: z.string().url(),
				title: z.optional(z.string()),
			}),
		),
	})
	.transform(response => ({
		type: 'SCHEDULED',
		uuid: response.uuid,
		text: response.text,
		mdText: response.mdText,
		webPage: response.webPage,
		images: response.images || [],
		tags: response.tags || [],
		createdAt: response.createdAt,
		updatedAt: response.updatedAt,
		publishAt: response.publishAt,
		ad: response.ad || false,
		silent: response.silent,
		colorTag: response.colorTag,
		channelUuid: response.channelUuid,
		externalLink: response.externalLink,
		unpublishedVersion: response.unpublishedVersion,
	}))

export const DraftNotScheduledSchema: z.ZodSchema<DraftNotScheduled, z.ZodTypeDef, unknown> = z
	.object({
		uuid: z.string(),
		text: z.optional(z.string()),
		mdText: z.optional(z.string()),
		webPage: z.optional(z.string()),
		images: z.optional(z.array(z.string())),
		tags: z.optional(z.array(z.string())),
		createdAt: z.string(),
		updatedAt: z.optional(z.string()),
		ad: z.optional(z.boolean()),
		silent: z.boolean(),
		colorTag: z.optional(z.string()),
		channelUuid: z.optional(z.string()),
		publishAt: z.optional(z.string()),
		scheduled: z.literal(false),
		externalLink: z.optional(
			z.object({
				url: z.string().url(),
				title: z.optional(z.string()),
			}),
		),
	})
	.transform(response => ({
		type: 'NOT_SCHEDULED',
		uuid: response.uuid,
		text: response.text,
		mdText: response.mdText,
		publishAt: response.publishAt,
		webPage: response.webPage,
		images: response.images || [],
		tags: response.tags || [],
		createdAt: response.createdAt,
		updatedAt: response.updatedAt,
		ad: response.ad || false,
		silent: response.silent,
		colorTag: response.colorTag,
		channelUuid: response.channelUuid,
		externalLink: response.externalLink,
	}))

export const DraftSchema: z.ZodSchema<Draft, z.ZodTypeDef, unknown> = z.union([
	DraftScheduledSchema,
	DraftNotScheduledSchema,
])
