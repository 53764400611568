import { z } from 'zod'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { isUrl } from '@prostpost/utils'
import { Input } from '@prostpost/uikit-next'
import { Box, HStack } from '@prostpost/uikit'

import type { ExternalLink as ExternalLinkType } from 'app/domains/Draft'

import { AttachmentContainer } from '../../components'

type FormData = {
	url: string
	title?: string
}

type Msg = { type: 'on_dismiss' } | { type: 'on_change'; externalLink: ExternalLinkType }

type Props = {
	externalLink: ExternalLinkType | undefined | null
} & (
	| {
			isRemovable: true
			onMsg: (msg: Msg) => void
	  }
	| {
			isRemovable: false
			onMsg?: never
	  }
)

const Wrapper = ({
	children,
	isRemovable,
	onDismiss,
}: {
	isRemovable: boolean
	children: React.ReactNode
	onDismiss: () => void
}) => {
	return isRemovable ? (
		<AttachmentContainer isRemovable onClickRemove={onDismiss}>
			{children}
		</AttachmentContainer>
	) : (
		<AttachmentContainer isRemovable={false}>{children}</AttachmentContainer>
	)
}

export const ExternalLink = ({ externalLink, ...props }: Props) => {
	const { t } = useTranslation()

	const formSchema: z.ZodSchema<FormData> = z.object({
		url: z.string().refine(isUrl, () => ({ message: t('notify:draft.externalLink.urlInvalid', 'Invalid URL') })),
		title: z
			.string()
			.min(1, t('notify:draft.externalLink.titleRequired', 'Title is required'))
			.max(32, t('notify:draft.externalLink.titleMaxLength', 'Maximum 32 characters allowed')),
	})

	const {
		watch,
		control,
		clearErrors,
		formState: { errors, defaultValues, isValid },
	} = useForm<FormData>({
		mode: 'onChange',
		reValidateMode: 'onChange',
		resolver: zodResolver(formSchema),
		defaultValues: {
			url: externalLink?.url ?? '',
			title: externalLink?.title ?? '',
		},
	})

	const url = watch('url')
	const title = watch('title')

	useEffect(() => {
		// we don't want to trigger this if URL is not valid because if we proceed with an invalid url
		// and set it to store it will be saved and we will get a schema error while reading this value
		if (props.isRemovable && isValid && title && url && isUrl(url)) {
			props.onMsg({ type: 'on_change', externalLink: { url, title } })
		}
	}, [url, title, isValid])

	const onDismiss = () => {
		if (!props.isRemovable) return
		clearErrors()
		props.onMsg({ type: 'on_dismiss' })
	}

	return (
		<Wrapper isRemovable={props.isRemovable} onDismiss={onDismiss}>
			<HStack
				as="form"
				align="center"
				w="100%"
				h="40px"
				space={2}
				onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => e.preventDefault()}
			>
				<Controller
					name="title"
					control={control}
					render={({ field }) => (
						<Box w="35%">
							<Input
								size="xs"
								defaultValue={defaultValues?.title || ''}
								variant={errors.title?.message ? 'error' : 'normal'}
								placeholder={t('content:draft.externalLink.titlePlaceholder', 'Add title')}
								{...field}
								onChange={v => {
									field.onChange(v)
									clearErrors('title')
								}}
							/>
						</Box>
					)}
				/>

				<Controller
					name="url"
					control={control}
					render={({ field }) => (
						<Input
							size="xs"
							defaultValue={defaultValues?.url || ''}
							variant={errors.url?.message ? 'error' : 'normal'}
							placeholder={t('content:draft.externalLink.urlPlaceholder', 'and paste URL here...')}
							{...field}
							onChange={v => {
								field.onChange(v)
								clearErrors('url')
							}}
						/>
					)}
				/>
			</HStack>
		</Wrapper>
	)
}
