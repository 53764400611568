import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { ContextMenu } from '@prostpost/uikit'

import type { DraftScheduled } from 'app/domains/Draft'
import type { PostInternal } from 'app/domains/Post'

export type Msg =
	| { type: 'on_published_now'; post: PostInternal }
	| { type: 'on_click_publish_now'; post: DraftScheduled }
	| { type: 'on_publish_now_failed'; post: DraftScheduled }
	| { type: 'on_publish_now_canceled'; post: DraftScheduled }

type Props = {
	isDisabled: boolean
	post: DraftScheduled
	onMsg: (msg: Msg) => void
}

export const PublishNow = ({ post, isDisabled, onMsg }: Props) => {
	const { t } = useTranslation()
	return (
		<ContextMenu.Item
			variant={isDisabled ? 'disabled' : 'normal'}
			icon={Icons.Send}
			onClick={() => onMsg({ type: 'on_click_publish_now', post })}
		>
			{t('labels.publishNow', 'Publish now')}
		</ContextMenu.Item>
	)
}
