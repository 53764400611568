import { useMutation } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseMutationOptions } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { DraftNotScheduled, DraftContent } from '..'

import { DraftNotScheduledSchema } from './schemas'

const knownErrors = [
	'UNABLE_TO_UPDATE_DRAFT',
	'UNABLE_TO_GET_DRAFT',
	'UNABLE_TO_SCHEDULE_DRAFT',
	'UNABLE_TO_SCHEDULE_DRAFT_IN_THE_PAST',
	'DRAFT_NOT_FOUND',
] as const

type Data = DraftContent & { uuid: string; channelUuid?: string | null }

const updateDraft = ({ uuid, ...data }: Data): Promise<DraftNotScheduled> =>
	!uuid
		? Promise.reject({ message: 'No draft UUID is given to update a draft' })
		: axiosInstance
				.patch<JsonApiResponseSingle>(`/drafts/draft/${uuid}`, {
					...data,
					images: data.images.filter(Boolean),
				})
				.catch((e: AxiosError<JsonApiErrorResponse>) => {
					throw parseFirstError(e, knownErrors)
				})
				.then(response => {
					const result = parseSingle(response.data)
					return DraftNotScheduledSchema.parse(result.data)
				})

export const useUpdateDraft = (
	options: Omit<
		UseMutationOptions<DraftNotScheduled, ParsedError<(typeof knownErrors)[number]>, Data>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		updateDraftMutation: useMutation<DraftNotScheduled, ParsedError<(typeof knownErrors)[number]>, Data>({
			mutationKey: 'draft',
			mutationFn: (data: Data) => updateDraft(data),
			...options,
		}),
	}
}
