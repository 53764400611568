import { observer, Observer } from 'mobx-react-lite'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { Box, Flex, Ava } from '@prostpost/uikit'
import { datetime, notReachable } from '@prostpost/utils'

import { MEDIA_URL } from 'app/domains/Media/constants'
import { useChannelNameFromUrl } from 'app/domains/Channel/hooks'
import type { DraftScheduled } from 'app/domains/Draft'
import type { ChannelActive } from 'app/domains/Channel'
import type { Post, PostInternalPublishing } from 'app/domains/Post'

type TimeProps = {
	post: DraftScheduled | Post | PostInternalPublishing
	timeFormat: '12h' | '24h'
	children: ({
		time,
		formattedTime,
		isChannelAvaVisible,
	}: {
		time: string
		formattedTime: string
		isChannelAvaVisible: boolean
	}) => React.ReactNode
}

const Time = observer(function Time({ post, timeFormat, children }: TimeProps) {
	const currentChannelName = useChannelNameFromUrl()
	const dates = (() => {
		switch (post.type) {
			case 'SCHEDULED':
				return {
					time: post.publishAt,
					formattedTime: datetime(post.publishAt, true, { to: 'local', timeFormat }),
				}
			case 'INTERNAL':
			case 'HISTORY':
			case 'INTERNAL_PUBLISHING':
				return {
					time: post.publishedAt,
					formattedTime: datetime(post.publishedAt, true, { to: 'local', timeFormat }),
				}
			default:
				return notReachable(post)
		}
	})()

	return <Box>{children({ ...dates, isChannelAvaVisible: !currentChannelName })}</Box>
})

type Props = {
	channelsCount: number
	channel: ChannelActive
	children: React.ReactNode | React.ReactNode[]
}

const Wrapper = ({ channelsCount, channel, children }: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()

	const currentChannelName = useChannelNameFromUrl()

	return (
		<Flex as="header" just="space-between" align="center" w="100%" h="24px">
			{!currentChannelName && channelsCount > 1 ? (
				<Observer>
					{() => (
						<Ava
							mr={2}
							size="small"
							src={channel.avaSmall ? MEDIA_URL(channel.avaSmall) : undefined}
							loader={({ size }) => <Icons.Send size={size} color={theme.colors.blue_40} />}
							alt={t('feed.post.imageAlt', 'Post image')}
						/>
					)}
				</Observer>
			) : null}
			{children}
		</Flex>
	)
}

export const Header = Object.assign(Wrapper, {
	Time,
})
