import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { notReachable } from '@prostpost/utils'

import { useUser } from '../api'
import type { User } from '..'

const MAX_RETRIES_ON_NOT_FOUND = 5

type Return = {
	retriesLimitReached: boolean
	data: User | undefined
}

export const useUserLoader = ({ throwError }: { throwError: boolean } = { throwError: true }): Return => {
	const { t } = useTranslation()

	const [retries, setRetries] = useState(0)

	const { userQuery } = useUser({
		retryDelay: 1000,
		// in case API returns 404 so user was just registered and was't yet sync with our user table
		retry: (failures, error) => {
			setRetries(p => p + 1)
			if (failures > MAX_RETRIES_ON_NOT_FOUND) return false
			return error.code === 'USER_NOT_FOUND'
		},
	})

	useEffect(() => {
		switch (userQuery.status) {
			case 'idle':
			case 'loading':
				break

			case 'error':
				try {
					switch (userQuery.error.code) {
						case 'UNKNOWN_ERROR':
						case 'NOT_VALID_ERROR':
							throw {
								...userQuery.error,
								title: t('notify:user.error.loadingFailed', 'Unable to get user'),
							}

						// Unexpected errors since inactive user should
						// not have access to that area at all
						case 'USER_BANNED':
						case 'USER_INACTIVE':
						case 'USER_ARCHIVED':
							throw {
								...userQuery.error,
								title: t('notify:user.error.inactive', 'Account is inactive'),
							}

						case 'USER_NOT_FOUND':
							break

						default:
							notReachable(userQuery.error.code)
					}
				} catch (e) {
					if (throwError) throw e
				}
				break

			case 'success':
				break

			default:
				notReachable(userQuery)
		}
	}, [userQuery.status])

	return {
		retriesLimitReached: retries >= MAX_RETRIES_ON_NOT_FOUND,
		data: userQuery.status === 'success' ? userQuery.data : undefined,
	}
}
