import { useTheme } from '@emotion/react'
import { Bug, PackagePlus, MessageSquare } from 'lucide-react'
import type { LucideIcon } from 'lucide-react'

import { Box } from '@prostpost/uikit'

import type { FeedbackCategoryOption } from './types'

const IconWrapper = ({ icon: Icon }: { icon: LucideIcon }) => {
	const theme = useTheme()
	return (
		<Box mt="1px">
			<Icon size={16} color={theme.colors.blue_40} />
		</Box>
	)
}

export const FEEDBACK_CATEGORIES: FeedbackCategoryOption[] = [
	{
		value: 'feedback',
		label: 'Feedback',
		prefix: <IconWrapper icon={MessageSquare} />,
	},
	{
		value: 'feature_request',
		label: 'Feature request',
		prefix: <IconWrapper icon={PackagePlus} />,
	},
	{
		value: 'bug',
		label: 'Bug',
		prefix: <IconWrapper icon={Bug} />,
	},
]
