import { makeLoggable } from 'mobx-log'
import { observable, action, makeObservable } from 'mobx'
import type { Moment } from 'moment'

import { datetime } from '@prostpost/utils'

import type { PricingPlan } from 'app/domains/PricingPlan'

import type {
	UserPreferences,
	Notifications,
	UserLimits,
	UserFeedPreferences,
	NotificationStatus,
	UserMorePreferences,
} from '..'

type InitArgs = Omit<UserPreferences, 'nextSubscriptionPeriod'> & {
	nextSubscriptionPeriod: string | Moment
	feedHistoryImagesNotLoaded?: boolean
}

export class StoreUserPreferences {
	declare timeFormat24: boolean

	declare plan: PricingPlan
	declare nextSubscriptionPeriod: Moment

	declare limits: UserLimits
	declare more: UserMorePreferences
	declare notifications: { [key in Notifications]?: NotificationStatus }

	declare feed: UserFeedPreferences
	declare feedHistoryImagesNotLoaded: boolean

	declare card?: string
	declare lang?: string
	declare phone?: string
	declare country?: string
	declare timeZone?: string

	init(preferences: InitArgs) {
		this.timeFormat24 = preferences.timeFormat24

		this.plan = preferences.plan
		this.nextSubscriptionPeriod =
			typeof preferences.nextSubscriptionPeriod === 'string'
				? datetime(preferences.nextSubscriptionPeriod, false, { to: 'local' })
				: preferences.nextSubscriptionPeriod

		this.feed = preferences.feed
		this.feedHistoryImagesNotLoaded = false

		this.more = preferences.more
		this.limits = preferences.limits
		this.notifications = preferences.notifications

		this.card = preferences.card
		this.lang = preferences.lang
		this.phone = preferences.phone
		this.country = preferences.country
		this.timeZone = preferences.timeZone
	}

	constructor(args: InitArgs) {
		this.init(args)

		makeObservable(this, {
			timeFormat24: observable,
			plan: observable,
			feed: observable,
			feedHistoryImagesNotLoaded: observable,
			more: observable,
			limits: observable,
			notifications: observable,
			card: observable,
			lang: observable,
			phone: observable,
			country: observable,
			timeZone: observable,

			init: action,
			setPreferences: action,
			skipNotification: action,
		})

		makeLoggable(this)
	}

	setPreferences(preferences: InitArgs) {
		this.init(preferences)
	}

	skipNotification(notification: Notifications) {
		this.notifications = Object.fromEntries(
			Object.entries(this.notifications).filter(([key]) => key !== notification),
		)
	}
}
