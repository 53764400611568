import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import * as Icons from 'react-feather'

import { Text, Button, PricingPlanTile } from '@prostpost/uikit'

import { useCurrency } from 'app/domains/PricingPlan/hooks'
import type { ChannelActive } from 'app/domains/Channel'
import type { PricingPlanLimited } from 'app/domains/PricingPlan'

export const BloggerPlanTile = observer(
	({ plan, channels, isActive }: { plan: PricingPlanLimited; channels: ChannelActive[]; isActive: boolean }) => {
		const { t } = useTranslation()
		// Probably in future we will support more currencies and they will be added to BE plans
		// but now it's only USD now
		const { sign: currencySign } = useCurrency('USD')

		return (
			<PricingPlanTile w="100%" variant={isActive ? 'primary' : 'secondary'}>
				<PricingPlanTile.Title>{t('userSettings.plans.bloggerTitle', 'Creator')}</PricingPlanTile.Title>
				<PricingPlanTile.Price
					period={t('userSettings.plans.periodMonth', 'month')}
					currencySign={currencySign}
					value={plan.price}
				/>
				<PricingPlanTile.Features>
					<PricingPlanTile.Feature
						variant="iconed"
						icon={Icons.Check}
						iconColor={channels.length === plan.limits.channels ? 'green_100' : 'blue_40'}
					>
						{t('userSettings.plans.channelFeature', '{{channels}}/{{count}} Channel', {
							channels: channels.length,
							count: plan.limits.channels,
						})}
					</PricingPlanTile.Feature>
					{plan.limits.posts < 9999 ? (
						<PricingPlanTile.Feature variant="iconed" icon={Icons.Calendar} iconColor="blue_40">
							{t('userSettings.plans.postsFeature2', '{{count}} Posts per month', {
								count: plan.limits.posts,
							})}
						</PricingPlanTile.Feature>
					) : (
						<PricingPlanTile.Feature variant="iconed" icon={Icons.Calendar} iconColor="blue_40">
							{t('userSettings.plans.postsFeatureUnlimited', 'Unlimited posts')}
						</PricingPlanTile.Feature>
					)}
					<PricingPlanTile.Feature variant="iconed" icon={Icons.BarChart2} iconColor="blue_40">
						{t('userSettings.plans.analyticsFeature', 'Advanced analytics')}
					</PricingPlanTile.Feature>
				</PricingPlanTile.Features>
				<PricingPlanTile.Side>
					{isActive ? (
						<Text size={14} color="black_80">
							{t('userSettings.plans.current', 'Current')}
						</Text>
					) : (
						<Button.Action
							size="small"
							variant="primary"
							onClick={() => console.log('Downgrade plan clicked')}
						>
							{t('userSettings.plans.downgradeAction2', 'Upgrade')}
						</Button.Action>
					)}
				</PricingPlanTile.Side>
			</PricingPlanTile>
		)
	},
)
