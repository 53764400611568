import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { Template } from '..'

import { TemplateSchema } from './schemas'

const knownErrors = ['UNABLE_TO_GET_TEMPLATE', 'TEMPLATE_NOT_FOUND'] as const

type Data = { uuid: string }

const fetchTemplate = ({ uuid }: Data): Promise<Template> =>
	axiosInstance
		.get<JsonApiResponseSingle>(`/drafts/template/${uuid}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return TemplateSchema.parse(result.data)
		})

export const useTemplate = (
	uuid: string,
	options: Omit<
		UseQueryOptions<Template, ParsedError<(typeof knownErrors)[number]>, Template>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		templateQuery: useQuery<Template, ParsedError<(typeof knownErrors)[number]>, Template>(
			['template', uuid],
			() => fetchTemplate({ uuid }),
			{
				enabled: false,
				...options,
			},
		),
	}
}
