import { useAnalytics } from '.'

export const useAuthAnalyticEvents = () => {
	const { track } = useAnalytics()
	const PREFIX = 'auth'
	return {
		clicks: {
			signUp: (method: 'email' | 'google') => track(`${PREFIX}_click_sign_up`, { method }),
			signIn: (method: 'email' | 'google' | 'otp') => track(`${PREFIX}_click_sign_in`, { method }),
			forgotPassword: () => track(`${PREFIX}_click_forgot_password`),
		},
	}
}

export const useChannelAnalyticsEvents = (channel: string) => {
	const { track } = useAnalytics()
	const PREFIX = 'channel'
	return {
		clicks: {
			newDraft: () => track(`${PREFIX}_click_new_draft`, { channel }, { groupId: channel }),
			scheduleDraft: () => track(`${PREFIX}_click_schedule`, { channel }, { groupId: channel }),
		},
	}
}

export const useInboxAnalyticsEvents = () => {
	const { track } = useAnalytics()
	const PREFIX = 'inbox'
	return {
		clicks: {
			newDraft: () => track(`${PREFIX}_click_new_draft`),
		},
	}
}
