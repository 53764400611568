import { useEffect } from 'react'
import { observable } from 'mobx'

import { getNow, datetime } from '@prostpost/utils'

import { config } from 'app/config'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'

// we need a shared store here since we use useShowTopSticker is a lot of places
// to check if a sticker should be shown or not so local React state doesn't work here
const topStickerStore = observable({ isVisible: true })

export const useShowTopSticker = () => {
	const { preferences } = useOnboardedUserStore()

	const isStickerVisible = config.localStorage.getItem('showTrialSticker')
		? datetime(config.localStorage.getItem('showTrialSticker')!, false).isBefore(getNow(false))
		: true

	const onDismissSticker = () => {
		topStickerStore.isVisible = false
		const tomorrow = datetime(getNow(false).add(1, 'day'), false).format('YYYY-MM-DD[T]HH:mm:ss')
		config.localStorage.setItem('showTrialSticker', tomorrow)
	}

	useEffect(() => {
		if (preferences.plan.name !== 'TRIAL') {
			config.localStorage.removeItem('showTrialSticker')
		}
	}, [])

	useEffect(() => {
		if (!isStickerVisible) topStickerStore.isVisible = false
	}, [isStickerVisible])

	return {
		topSticker: topStickerStore,
		onDismissSticker,
	}
}
