import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { notReachable } from '@prostpost/utils'
import { ContextMenu as UIContextMenu } from '@prostpost/uikit'

import { useBotStore } from 'app/domains/Bot/store'
import { UnschedulePost } from 'app/domains/Post/features/UnschedulePost'
import type { DraftScheduled, DraftNotScheduled } from 'app/domains/Draft'
import type { PostInternal } from 'app/domains/Post'

import { ToggleSilentMode } from '../ToggleSilentMode'
import { ContextMenuWrapper } from '../../../components'

import { Open } from './Open'
import { Edit } from './Edit'
import { PublishNow } from './PublishNow'
import { CopyAsDraft } from './CopyAsDraft'
import { UnpublishedVersion } from './UnpublishedVersion'

export type Msg =
	| { type: 'on_unschedule'; post: DraftScheduled }
	| { type: 'on_click_unschedule'; post: DraftScheduled }
	| { type: 'on_published_now'; post: PostInternal }
	| { type: 'on_click_publish_now'; post: DraftScheduled }
	| { type: 'on_publish_now_failed'; post: DraftScheduled }
	| { type: 'on_publish_now_canceled'; post: DraftScheduled }
	| { type: 'on_click_toggle_silent'; isSilent: boolean }
	| { type: 'on_toggle_silent_failed'; post: DraftScheduled; prevIsSilent: boolean }
	| { type: 'on_click_edit' }
	| { type: 'on_context_menu_toggle'; isOpen: boolean }
	| { type: 'on_click_open_modal_editor' }
	| { type: 'on_click_load_unpublished_version'; backupUuid: string }
	| { type: 'on_click_copy_as_draft'; draft: DraftNotScheduled }

type Props = {
	post: DraftScheduled
	isPostPublishingFailed: boolean
	onMsg: (msg: Msg) => void
}

export const ContextMenu = observer(function ContextMenu(props: Props) {
	const botStore = useBotStore()
	return (
		<ContextMenuWrapper
			iconColor={props.isPostPublishingFailed ? 'red_100' : 'blue_40'}
			onToggle={isOpen => props.onMsg({ type: 'on_context_menu_toggle', isOpen })}
		>
			<Open onMsg={props.onMsg} />

			{props.post.unpublishedVersion ? (
				<UnpublishedVersion
					onMsg={msg => {
						switch (msg.type) {
							case 'on_click_load_unpublished_version':
								runInAction(() => {
									props.onMsg({
										type: 'on_click_load_unpublished_version',
										backupUuid: props.post.unpublishedVersion as string,
									})
								})
								break
							default:
								notReachable(msg.type)
						}
					}}
				/>
			) : (
				<Edit {...props} />
			)}

			<CopyAsDraft postUuid={props.post.uuid} onMsg={props.onMsg} />
			<ToggleSilentMode variant="context" isDisabled={!botStore.link} {...props} />

			<UIContextMenu.Divider />

			<PublishNow {...props} isDisabled={!botStore.link} />
			<UnschedulePost {...props} variant="context-menu-item" />
		</ContextMenuWrapper>
	)
})
