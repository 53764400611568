import { notReachable } from '@prostpost/utils'

import { UserBasicInformationPlain } from './Plain'

type Props = { variant: 'plain' }

export const UserBasicInformationVariants = (props: Props) => {
	switch (props.variant) {
		case 'plain':
			return <UserBasicInformationPlain {...props} />
		default:
			return notReachable(props.variant)
	}
}
