import { makeLoggable } from 'mobx-log'
import { observable, action, computed, makeObservable } from 'mobx'

import { notReachable } from '@prostpost/utils'

import type { ChannelActive, ChannelLocked, ChannelNotArchived } from '..'

type UpdateData = {
	title?: string
	description?: string
} & ({ avaBig: string; avaSmall: string } | { avaBig?: never; avaSmall?: never })

type InitArgs = {
	activeChannels: ChannelActive[]
	lockedChannels: ChannelLocked[]
	callbacks?: Callbacks
}

type Callbacks = {
	onArchiveChannel?: (channelName: string) => void
}

export class StoreChannels {
	declare channelsLimitReached: boolean
	declare current: ChannelActive | undefined

	declare activeList: ChannelActive[]
	declare lockedList: ChannelLocked[]

	declare callbacks: Callbacks

	init({ activeChannels, lockedChannels, callbacks = {} }: InitArgs) {
		this.current = undefined
		this.channelsLimitReached = false

		this.activeList = activeChannels
		this.lockedList = lockedChannels

		this.callbacks = callbacks
	}

	constructor(args: InitArgs) {
		this.init(args)

		makeObservable(this, {
			current: observable,
			activeList: observable,
			lockedList: observable,
			channelsLimitReached: observable,

			notArchivedList: computed,

			init: action,
			setCurrent: action,
			resetCurrent: action,
			updateChannel: action,
			setActiveList: action,
			setLockedList: action,
			addNewChannel: action,
			archiveChannel: action,
			isChannelActive: action,
			setNotArchivedList: action,
			getActiveChannelByName: action,
			setChannelsLimitReached: action,
		})

		makeLoggable(this)
	}

	get notArchivedList() {
		return [...this.activeList, ...this.lockedList]
	}

	isChannelActive(channelName: string) {
		return !!this.activeList.some(ch => ch.name === channelName)
	}

	getActiveChannelByName(channelName: string) {
		return this.activeList.find(ch => ch.name === channelName)
	}

	getActiveChannelByUuid(uuid: string) {
		return this.activeList.find(ch => ch.uuid === uuid)
	}

	addNewChannel(channel: ChannelNotArchived) {
		switch (channel.type) {
			case 'ACTIVE':
				this.setActiveList([...this.activeList, channel])
				break
			case 'LOCKED':
				this.setLockedList([...this.lockedList, channel])
				break
			default:
				notReachable(channel)
		}
	}

	setActiveList(channels: ChannelActive[]): void {
		this.activeList = channels
	}

	setLockedList(channels: ChannelLocked[]): void {
		this.lockedList = channels
	}

	setNotArchivedList(channels: ChannelNotArchived[]) {
		this.activeList = channels.filter((ch): ch is ChannelActive => ch.type === 'ACTIVE')
		this.lockedList = channels.filter((ch): ch is ChannelLocked => ch.type === 'LOCKED')
	}

	setChannelsLimitReached(reached: boolean): void {
		this.channelsLimitReached = reached
	}

	setCurrent(channel: ChannelActive): void {
		this.current = channel
		this.activeList = this.activeList.map(ch => (ch.name === channel.name ? channel : ch))
	}

	resetCurrent(): void {
		this.current = undefined
	}

	archiveChannel(channel: ChannelNotArchived) {
		switch (channel.type) {
			case 'ACTIVE':
				this.activeList = this.activeList.filter(ch => ch.name !== channel.name)
				break
			case 'LOCKED':
				this.lockedList = this.lockedList.filter(ch => ch.name !== channel.name)
				break
			default:
				notReachable(channel)
		}

		this.callbacks.onArchiveChannel?.(channel.name)

		if (this.current?.name === channel.name) {
			this.resetCurrent()
		}
	}

	updateChannel(chName: string, data: UpdateData) {
		this.setActiveList(this.activeList.map(ch => (ch.name === chName ? { ...ch, ...data } : ch)))
		this.setLockedList(this.lockedList.map(ch => (ch.name === chName ? { ...ch, ...data } : ch)))

		if (this.current?.name === chName) {
			this.setCurrent({ ...this.current, ...data })
		}
	}
}
