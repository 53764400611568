import styled from '@emotion/styled'
import { css } from '@emotion/react'
import type { SerializedStyles } from '@emotion/react'

import { getSpace, getBorderRadius, getAnimation } from '@prostpost/css'
import type { Theme } from '@prostpost/uikit'

export const toggleBgOnSelection = ({ theme, isSelected }: { theme: Theme; isSelected: boolean }): SerializedStyles =>
	!isSelected
		? css`
				background: ${theme.colors.white_100};
			`
		: css`
				background: ${theme.colors.blue_10};
			`

export const Container = styled.li<{ isSelected: boolean; hasScaleOnHover: boolean; hoverVariant: 'shadow' | 'bg' }>`
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 100%;
	height: 115px;
	padding: ${getSpace(4)} ${getSpace(4)} ${getSpace(4)} 40px;

	cursor: pointer;

	border-radius: ${getBorderRadius('small')};

	&::before {
		content: '';

		position: absolute;
		top: 4px;
		left: 0;
		z-index: 0;

		width: 100%;
		height: 96px;

		border-radius: ${getBorderRadius('small')};

		transition:
			transform ${getAnimation('tt_tf')},
			background ${getAnimation('tt_tf')},
			box-shadow ${getAnimation('tt_tf')};

		${props => {
			switch (props.hoverVariant) {
				case 'bg':
					return toggleBgOnSelection(props)
				case 'shadow':
					return css`
						background: ${props.theme.colors.white_100};
						box-shadow: none;
					`
				default:
					return ''
			}
		}}
	}

	&:hover {
		> button {
			visibility: visible;
		}

		&::before {
			${({ theme, hoverVariant }) => {
				switch (hoverVariant) {
					case 'bg':
						return css`
							background: ${theme.colors.blue_10};
						`
					case 'shadow':
						return css`
							${theme.shadows.mid};
						`
					default:
						return ''
				}
			}}

			${({ hasScaleOnHover }) =>
				!hasScaleOnHover
					? ''
					: css`
							transform: scale(1.03);
						`}
		}
	}
`
