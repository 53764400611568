import { isUrl } from '@prostpost/utils'

type Error = 'INVALID_LINK'
type Result = { state: 'SUCCESS'; name: string } | { state: 'ERROR'; name: string; error: Error }

const checkChannelRef = (channelNameOrLink: string): Result => {
	// channel name without @ e.g. prostpost
	let channelName = channelNameOrLink
	if (channelNameOrLink.startsWith('@')) {
		// channel name with leading @ e.g. @prostpost
		channelName = channelNameOrLink.slice(1)
	} else if (isUrl(channelNameOrLink)) {
		if (channelNameOrLink.includes('t.me')) {
			// valid link e.g. https://t.me/prostpost
			const splitted = channelNameOrLink.split('/')
			channelName = splitted[splitted.length - 1]
		} else {
			// invalid link e.g. https://tlgm.ru/prostpost
			return {
				name: channelName,
				state: 'ERROR',
				error: 'INVALID_LINK',
			}
		}
	}

	return {
		name: channelName,
		state: 'SUCCESS',
	}
}

export const useCheckChannelRef = () => {
	return { checkChannelRef }
}
