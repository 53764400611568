import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { captureException } from '@sentry/react'

import { notReachable } from '@prostpost/utils'

import { useBotStore } from 'app/domains/Bot/store'
import { useRemoveBotLink } from 'app/domains/Bot/api'

type Status = { type: 'success' | 'error'; message: string } | { type: 'loading' | 'idle' }

export const useUnsetBotLink = () => {
	const { t } = useTranslation()

	const bot = useBotStore()
	const { removeBotLinkMutation } = useRemoveBotLink()
	const [status, setStatus] = useState<Status>({ type: 'idle' })

	useEffect(() => {
		switch (removeBotLinkMutation.status) {
			case 'idle':
				setStatus({ type: 'idle' })
				break

			case 'loading':
				setStatus({ type: 'loading' })
				break

			case 'error':
				console.error(removeBotLinkMutation.error)
				switch (removeBotLinkMutation.error.code) {
					case 'UNKNOWN_ERROR':
					case 'NOT_VALID_ERROR':
						captureException(removeBotLinkMutation.error)
						setStatus({
							type: 'error',
							message: t('notify:bot.unlinkError', 'Unable to unlink bot'),
						})
						break
					case 'UNABLE_TO_DELETE_CHAT':
						setStatus({
							type: 'error',
							message: t('notify:bot.unlinkError', 'Unable to unlink bot'),
						})
						break
					default:
						notReachable(removeBotLinkMutation.error.code)
				}
				break

			case 'success':
				bot.setLink(false)
				setStatus({
					type: 'success',
					message: t('notify:bot.botLink.unlink.success', 'Bot was successfully unlinked from your account'),
				})

				break
			default:
				notReachable(removeBotLinkMutation)
		}
	}, [removeBotLinkMutation.status])

	return { botLinkStatus: status, unsetBotLink: removeBotLinkMutation.mutate }
}
