import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { Feature } from '..'

import { FeaturesSchema } from './schemas'

const knownErrors = ['USER_NOT_FOUND', 'USER_INACTIVE', 'USER_BANNED', 'USER_ARCHIVED'] as const

const fetchFeatures = (): Promise<Feature[]> =>
	axiosInstance
		.get<JsonApiResponseSingle>('/auth/me/features')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return FeaturesSchema.parse(result.data)
		})

export const useFeatures = () => {
	return {
		featuresQuery: useQuery<Feature[], ParsedError<(typeof knownErrors)[number]>, Feature[]>(
			'features',
			fetchFeatures,
		),
	}
}
