import z from 'zod'

import type { Template, TemplateShortInfo } from 'app/domains/Template'

export const TemplateSchema: z.ZodSchema<Template, z.ZodTypeDef, unknown> = z
	.object({
		uuid: z.string(),
		title: z.string(),
		text: z.optional(z.string()),
		webPage: z.optional(z.string()),
		images: z.optional(z.array(z.string())),
		tags: z.optional(z.array(z.string())),
		createdAt: z.string(),
		updatedAt: z.optional(z.string()),
		ad: z.optional(z.boolean()),
		silent: z.boolean(),
		channelUuid: z.optional(z.string()),
		colorTag: z.optional(z.string()),
		externalLink: z.optional(
			z.object({
				url: z.string().url(),
				title: z.optional(z.string()),
			}),
		),
	})
	.transform(response => ({
		images: response.images || [],
		tags: response.tags || [],
		ad: response.ad || false,
		...response,
	}))

export const TemplateListSchema: z.ZodSchema<TemplateShortInfo, z.ZodTypeDef, unknown> = z.object({
	uuid: z.string(),
	title: z.string(),
	createdAt: z.string(),
	ad: z.optional(z.boolean()),
	colorTag: z.optional(z.string()),
	channelUuid: z.optional(z.string()),
})
