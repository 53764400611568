import styled from '@emotion/styled'

import { getSpace, getColor, getBorderRadius, mediaQueries, MEDIA } from '@prostpost/css'
import { Flex } from '@prostpost/uikit'

import { Z_INDEXES } from 'app/config/zIndexes'

export const StyledAbsoluteContainer = styled.div`
	position: fixed;
	right: ${getSpace(5)};
	bottom: 0;
	z-index: ${Z_INDEXES.COOKIES_BANNER} !important; /* to override client chat z-index */
	overflow: hidden;

	padding-bottom: ${getSpace(5)};

	${mediaQueries[MEDIA.MOBILE]} {
		right: 0;

		width: 100%;
		padding-bottom: 0;
	}
`

export const StyledCookieBannerContainer = styled(Flex)`
	width: 460px;
	padding: ${getSpace(5)};

	background: ${getColor('black_80')};
	border-radius: ${getBorderRadius('mid')};

	${mediaQueries[MEDIA.MOBILE]} {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		border-radius: 0;

		> button {
			margin-top: ${getSpace(4)};
		}
	}
`

const animationVariants = {
	hidden: {
		opacity: 0,
		height: 0,
		y: '-10px',
	},
	exit: {
		y: '-20px',
	},
	exitOpacity: {
		opacity: 0,
		transition: {
			duration: 0.4,
		},
	},
	visible: {
		opacity: 1,
		y: 0,
		height: 'auto',
		transition: {
			type: 'spring',
			damping: 10,
			stiffness: 100,
			delay: 5,
		},
	},
}

export const animation = {
	initial: 'hidden',
	animate: 'visible',
	exit: ['hidden', 'exitOpacity'],
	variants: animationVariants,
}
