import type { TFunction } from 'i18next'

export const useCommonLocalizations = (t: TFunction) => ({
	almostThere: t('common.loading.general', 'Almost there...'),
	actionGotIt: t('common:actions.gotIt', 'Got it'),
	actionUndo: t('common:actions.undo', 'Undo'),
	actionReview: t('common:actions.review', 'Review'),
	tryAgainMessage: t('common:actions.tryAgain', 'Try again'),
	actionContinue: t('common:actions.continue', 'Continue'),
	actionCancel: t('common:actions.cancel', 'Cancel'),
	actionArchive: t('common:actions.archive', 'Archive'),
	somethingWentWrongMessage: t('common:sww', 'Something went wrong'),
	pleaseContactSupportMessage: t('common:contactSupport', 'Please try again or contact our support team'),
	loadingMessage: t('common:loading', 'Loading...'),
})
