import { toJS, runInAction } from 'mobx'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { getNow } from '@prostpost/utils'
import { useToast } from '@prostpost/toast'
import type { UpdateToastProps } from '@prostpost/toast'

import { usePublishPost } from 'app/domains/Post/api'
import type { DraftScheduled } from 'app/domains/Draft'
import type { PostInternal, PostInternalPublishing } from 'app/domains/Post'

import { useCommonLocalizations } from 'app/shared/hooks'

export type Msg =
	| { type: 'on_published_now'; post: PostInternal }
	| { type: 'on_publish_now_failed'; post: DraftScheduled }
	| { type: 'on_publish_now_canceled'; post: DraftScheduled }

type HookArgs = {
	post: DraftScheduled
	onMsg: (msg: Msg) => void
}

const UNDO_TIMEOUT = 7_000

const useToasts = ({ onUndo }: { onUndo: (update: (args: UpdateToastProps) => void) => void }) => {
	const { t } = useTranslation()
	const { actionUndo } = useCommonLocalizations(t)

	const failedToPublish = useToast({
		type: 'error',
		text: t('content:editor.actions.publish.error', 'Failed to publish post'),
	})

	const isPublishing = useToast({
		type: 'info',
		text: t('content:editor.actions.publish.inProgress', 'Post is being publishing...'),
		icon: Icons.Clock,
		hideProgressBar: false,
		actions: ({ update }) => [
			{
				label: actionUndo,
				onClick: () => onUndo(update),
			},
		],
	})

	return { failedToPublish, isPublishing }
}

export const usePublishNowClick = ({ post: _post, onMsg }: HookArgs) => {
	const { t } = useTranslation()

	const post = runInAction(() => toJS(_post))
	const { publishPostMutation } = usePublishPost()

	const timeout = useRef<ReturnType<typeof setTimeout> | null>(null)
	const [publishingPost, setPublishingPost] = useState<PostInternalPublishing | undefined>()

	const onClearTimeout = () => timeout.current && clearTimeout(timeout.current)
	const onUndo = () => {
		runInAction(() => {
			onMsg({ type: 'on_publish_now_canceled', post })
			setPublishingPost(undefined)
			onClearTimeout()
		})
	}

	const toasts = useToasts({
		onUndo: update => {
			onUndo()
			update({
				type: 'success',
				text: t('content:editor.actions.publish.canceled', 'Publishing canceled'),
			})
		},
	})

	useEffect(() => {
		if (publishingPost) {
			timeout.current = setTimeout(() => {
				publishPostMutation.mutate(
					{ uuid: publishingPost.uuid },
					{
						onSuccess: data => {
							onMsg({ type: 'on_published_now', post: data })
							// clear local state
							onUndo()
						},

						onError: e => {
							console.error(e)
							runInAction(() => {
								onMsg({ type: 'on_publish_now_failed', post })
								if (publishingPost) onUndo()
								toasts.failedToPublish.show()
							})
						},
					},
				)
			}, UNDO_TIMEOUT)
		}
	}, [publishingPost])

	return () => {
		setPublishingPost({
			...post,
			type: 'INTERNAL_PUBLISHING',
			views: 0,
			scheduled: true,
			publishedAt: getNow(true, { to: 'utc' }),
		})

		toasts.isPublishing.show()
	}
}
