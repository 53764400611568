import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { ContextMenu } from '@prostpost/uikit'

import { useCopyDraft } from 'app/domains/Draft/hooks'
import type { DraftNotScheduled } from 'app/domains/Draft'

export type Msg = { type: 'on_click_copy_as_draft'; draft: DraftNotScheduled }

type Props = {
	postUuid: string
	onMsg: (msg: Msg) => void
}

export const CopyAsDraft = ({ postUuid, onMsg }: Props) => {
	const { t } = useTranslation()
	const { copyDraft } = useCopyDraft({
		uuid: postUuid,
		onSuccess: draft => onMsg({ type: 'on_click_copy_as_draft', draft }),
	})

	return (
		<ContextMenu.Item icon={Icons.Copy} onClick={copyDraft}>
			{t('labels.copyAsDraft', 'Copy as draft')}
		</ContextMenu.Item>
	)
}
