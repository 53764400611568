import { createContext, useContext } from 'react'

import type { OnboardedUserStore } from './store'

export const OnboardedUserStoreContext = createContext<OnboardedUserStore | undefined>(undefined)

export const useOnboardedUserStore = (): OnboardedUserStore => {
	const user = useContext(OnboardedUserStoreContext)
	if (user === undefined) {
		throw Error('useOnboardedUserStore can be used only within OnboardedUserStoreContext')
	}
	return user
}
