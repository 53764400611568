import { useTranslation } from 'react-i18next'
import { ExternalLink } from 'lucide-react'

import { ContextMenu } from '@prostpost/uikit'

type Msg = { type: 'on_click_open_in_telegram' }

type Props = {
	onMsg: (msg: Msg) => void
}

export const OpenInTelegram = ({ onMsg }: Props) => {
	const { t } = useTranslation()

	return (
		<ContextMenu.Item icon={ExternalLink} onClick={() => onMsg({ type: 'on_click_open_in_telegram' })}>
			{t('labels:openPostInTelegram', 'Open in Telegram')}
		</ContextMenu.Item>
	)
}
