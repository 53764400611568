import { VStack } from '@prostpost/uikit'

import { IMAGE_WIDTH } from '../constants'

import { AddChannelInstruction, AddChannelForm } from './components'
import type { Msg as MsgComponents } from './components'

export type Msg = MsgComponents
type MsgProp = {
	onMsg: (msg: Msg) => void
}

export type Interface = React.ComponentProps<typeof AddChannelInstruction> &
	Omit<React.ComponentProps<typeof AddChannelForm>, 'onMsg'> & {
		isFullWidth?: boolean
	}

export const AddChannelPlain = ({ title, size, submitButton, isFullWidth, onMsg }: Interface & MsgProp) => (
	<VStack space={4} p={2} w={isFullWidth ? '100%' : `calc(100% - ${IMAGE_WIDTH})`}>
		<AddChannelInstruction title={title} />
		<AddChannelForm size={size} submitButton={submitButton} onMsg={onMsg} />
	</VStack>
)
