import { useMutation } from 'react-query'
import type { UseMutationOptions } from 'react-query'
import type { AxiosError } from 'axios'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { DraftNotScheduled } from '..'

import { DraftNotScheduledSchema } from './schemas'

const knownErrors = ['UNABLE_TO_CREATE_DRAFT'] as const

type Data = {
	uuid: string
}

const copyDraft = ({ uuid }: Data): Promise<DraftNotScheduled> => {
	return axiosInstance
		.post<JsonApiResponseSingle>(`/drafts/draft/copy/${uuid}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return DraftNotScheduledSchema.parse(result.data)
		})
}

export const useCopyDraft = (
	uuid: string,
	options: Omit<
		UseMutationOptions<DraftNotScheduled, ParsedError<(typeof knownErrors)[number]>, void>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		copyDraftMutation: useMutation<DraftNotScheduled, ParsedError<(typeof knownErrors)[number]>, void>({
			mutationKey: 'draft',
			mutationFn: () => copyDraft({ uuid }),
			...options,
		}),
	}
}
