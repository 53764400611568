import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'
import type { PropsWithChildren } from 'react'

import { HStack, Box } from '@prostpost/uikit'

type Props =
	| {
			isRemovable: true
			onClickRemove: () => void
	  }
	| {
			isRemovable?: false
			onClickRemove?: never
	  }

const DismissButton = styled.button`
	width: 14px;
	height: 14px;
	border: none;
`

const AttachmentContainerWrapper = styled(HStack)`
	position: relative;
	width: calc(100% + 10px);

	/* to align X borders considering X icon appears on hover */
	height: 100%;
	margin-left: -10px;
	cursor: pointer;

	> div > button[data-name='dismiss-attachment'] {
		visibility: hidden;
	}

	&:hover {
		> div > button[data-name='dismiss-attachment'] {
			visibility: visible;
		}
	}
`

export const AttachmentContainer = ({ children, ...props }: PropsWithChildren<Props>) => {
	const theme = useTheme()
	return (
		<AttachmentContainerWrapper height="100%" align="center">
			<HStack position="absolute" space={1} h="100%">
				{props.isRemovable && (
					<DismissButton data-name="dismiss-attachment" onClick={props.onClickRemove}>
						<Icons.X size={14} strokeWidth={3} color={theme.colors.black_80} />
					</DismissButton>
				)}
				<Box w="2px" h="100%" top={0} left={0} bg="black_80" />
			</HStack>
			{props.isRemovable ? <Box w="30px" h="100%" bg="white_100" /> : <Box w="12px" h="100%" bg="white_100" />}
			{children}
		</AttachmentContainerWrapper>
	)
}
