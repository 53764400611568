import { z } from 'zod'
import { useEffect } from 'react'
import { runInAction, toJS } from 'mobx'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'

import { useUpdateUser } from 'app/domains/User/api'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'

type FormData = {
	firstName?: string
	lastName?: string
}

const useToasts = () => {
	const { t } = useTranslation()

	const alreadyInUse = useToast({
		type: 'error',
		text: t('userSettings.basics.emailIsAlreadyInUse', 'Email is already in use'),
	})

	const updateFailed = useToast({
		type: 'error',
		text: t('userSettings.basics.updateFailed', 'Unable to update user information'),
	})

	return { alreadyInUse, updateFailed }
}

export const useUserBasicInformationForm = () => {
	const toasts = useToasts()

	const user = useOnboardedUserStore()
	const { updateUserMutation } = useUpdateUser()

	const formSchema: z.ZodSchema<FormData> = z.object({
		firstName: z.optional(z.string().max(64)),
		lastName: z.optional(z.string().max(64)),
	})

	const {
		control,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: zodResolver(formSchema),
		defaultValues: {
			firstName: user.firstName,
			lastName: user.lastName,
		},
	})

	useEffect(() => {
		switch (updateUserMutation.status) {
			case 'idle':
			case 'loading':
				break

			case 'success': // since we update user data on blur we don't want to spam with successful notification
				user.setCurrent({
					...runInAction(() => toJS(user.getUser())),
					preferences: user.preferences,
					firstName: updateUserMutation.data.firstName,
					lastName: updateUserMutation.data.lastName,
				})
				break

			case 'error':
				console.error(updateUserMutation.error)
				switch (updateUserMutation.error.code) {
					case 'UNKNOWN_ERROR':
					case 'NOT_VALID_ERROR':
						toasts.updateFailed.show()
						break
					case 'EMAIL_ALREADY_IN_USE':
						toasts.alreadyInUse.show()
						break
					default:
						notReachable(updateUserMutation.error.code)
				}
				break

			default:
				notReachable(updateUserMutation)
		}
	}, [updateUserMutation.status])

	return {
		control,
		status: updateUserMutation.status,
		handleSubmit: handleSubmit(data => updateUserMutation.mutate(data)),
		fields: {
			firstName: {
				error: errors.firstName?.message,
				setError: (message: string) => setError('firstName', { message }),
				clearErrors,
			},
			lastName: {
				error: errors.lastName?.message,
				setError: (message: string) => setError('lastName', { message }),
				clearErrors,
			},
		},
	}
}
