import { useMutation } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseMutationOptions } from 'react-query'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { DraftScheduledSchema } from 'app/domains/Draft/api/schemas'
import type { DraftScheduled } from 'app/domains/Draft'

const knownErrors = [
	'DRAFT_NOT_FOUND',
	'UNABLE_TO_GET_DRAFT',
	'UNABLE_TO_PUBLISH_EMPTY_POST',
	'ATTEMPT_TO_SEND_EMPTY_POST',
	'CHAT_NOT_FOUND',
	'UNABLE_TO_GET_CHAT',
] as const

type Data = {
	uuid: string
	publishAt: string
}

const schedulePost = ({ uuid, publishAt }: Data): Promise<DraftScheduled> =>
	axiosInstance
		.put<JsonApiResponseSingle>(`/drafts/post/${uuid}`, { preview: false, publishAt })
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return DraftScheduledSchema.parse(result.data)
		})

export const useSchedulePost = (
	options: Omit<
		UseMutationOptions<DraftScheduled, ParsedError<(typeof knownErrors)[number]>, Data>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		schedulePostMutation: useMutation<DraftScheduled, ParsedError<(typeof knownErrors)[number]>, Data>({
			mutationKey: ['post', 'schedule'],
			mutationFn: ({ uuid, publishAt }: Data) => schedulePost({ uuid, publishAt }),
			...options,
		}),
	}
}
