import { memo } from 'react'
import { useTheme } from '@emotion/react'
import { Send, Repeat } from 'react-feather'

import { Flex, Text, Tooltip } from '@prostpost/uikit'

import { usePostForwardedFromLabel } from 'app/domains/Post/hooks'
import type { PostHistory } from 'app/domains/Post'

type Props = {
	time: string | null
	post: PostHistory
}

const arePropsEqual = (prevProps: Props, nextProps: Props) => prevProps.time === nextProps.time

export const Time = memo(function Time({ time, post }: Props) {
	const theme = useTheme()

	const iconProps = {
		size: 16,
		strokeWidth: 2.5,
		color: theme.colors.black_80,
	}

	const forwardedFromTooltipContent = usePostForwardedFromLabel(post)

	return (
		<Flex align="center">
			{forwardedFromTooltipContent ? (
				<Tooltip placement="bottom-start" content={forwardedFromTooltipContent}>
					<Repeat {...iconProps} />
				</Tooltip>
			) : (
				<Send {...iconProps} />
			)}

			<Text noWrap ml={1} mr={1} size={14} color="black_80">
				{time}
			</Text>
		</Flex>
	)
}, arePropsEqual)
