import { createContext, useContext } from 'react'

import type { ChannelActive } from '../..'

export const CurrentActiveChannelContext = createContext<ChannelActive | undefined>(undefined)

export const useCurrentActiveChannel = (): ChannelActive => {
	const channel = useContext(CurrentActiveChannelContext)
	if (!channel) {
		throw Error('useCurrentActiveChannel can be used only within CurrentActiveChannelContext')
	}
	return channel
}
