import { useTranslation } from 'react-i18next'

import { Tile, HStack, Image } from '@prostpost/uikit'

import { Images } from 'app/resources'

import { LinkBotToUserPlain } from '../Plain'
import type { Msg as MsgPlain } from '../Plain'

export type Msg = MsgPlain
type MsgProp = {
	onMsg: (msg: Msg) => void
}

export type Interface = Omit<React.ComponentProps<typeof LinkBotToUserPlain>, 'onMsg'>

export const LinkBotToUserTile = (props: Interface & MsgProp) => {
	const { t } = useTranslation()
	return (
		<Tile w="100%">
			<HStack.Responsive space={6} w="100%" responsive={{ MOBILE: { rule: 'wrap', vSpace: 5 } }}>
				<Image src={Images.BotLink} height="144px" alt={t('content:bot.linkBotToUser.tile.alt', 'Bot link')} />
				<LinkBotToUserPlain {...props} />
			</HStack.Responsive>
		</Tile>
	)
}
