import type { PostHistory } from 'app/domains/Post'

import { ContextMenuWrapper } from '../../../components'

import { OpenInTelegram } from './OpenInTelegram'

type Msg =
	| { type: 'on_click_open_in_telegram'; post: PostHistory }
	| { type: 'on_context_menu_toggle'; isOpen: boolean }

type Props = {
	post: PostHistory
	onMsg: (msg: Msg) => void
}

export const ContextMenu = ({ post, onMsg }: Props) => {
	return (
		<ContextMenuWrapper iconColor="blue_40" onToggle={isOpen => onMsg({ type: 'on_context_menu_toggle', isOpen })}>
			<OpenInTelegram onMsg={() => onMsg({ type: 'on_click_open_in_telegram', post })} />
		</ContextMenuWrapper>
	)
}
