import { EditorState } from 'prosemirror-state'
import type { Editor } from '@tiptap/react'

export const useResetEditorState = (editor?: Editor | null) => {
	return (content: string) => {
		if (editor) {
			// https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1546629928
			setTimeout(() => {
				editor.commands.setContent(content)
			})

			editor.view.updateState(
				EditorState.create({
					doc: editor.state.doc,
					plugins: editor.state.plugins,
					schema: editor.state.schema,
				}),
			)
		}
	}
}
