import React from 'react'

import { VStack, ShadowBox } from '@prostpost/uikit'

export const ListContainer = ({
	children,
	width = 'auto',
}: {
	width?: string
	children: React.ReactNode | React.ReactNode[]
}) => {
	return (
		<ShadowBox variant="mid" borderRadius="mid">
			<VStack space={1} p={2} w={width} bg="white_100" borderRadius="mid">
				{children}
			</VStack>
		</ShadowBox>
	)
}
