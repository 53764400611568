import { Mark, mergeAttributes } from '@tiptap/core'

export interface SpoilerOptions {
	HTMLAttributes: Record<string, string>
}

declare module '@tiptap/core' {
	interface Commands<ReturnType> {
		spoiler: {
			setSpoiler: () => ReturnType
			toggleSpoiler: () => ReturnType
			unsetSpoiler: () => ReturnType
		}
	}
}

export const Spoiler = Mark.create<SpoilerOptions>({
	name: 'spoiler',

	addOptions() {
		return {
			HTMLAttributes: {},
		}
	},

	parseHTML() {
		return [
			{
				tag: `span[data-type=${this.name}]`,
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['span', mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes), 0]
	},

	addCommands() {
		return {
			setSpoiler:
				() =>
				({ commands }) => {
					return commands.setMark(this.name)
				},
			toggleSpoiler:
				() =>
				({ commands }) => {
					return commands.toggleMark(this.name)
				},
			unsetSpoiler:
				() =>
				({ commands }) => {
					return commands.unsetMark(this.name)
				},
		}
	},
})
