import { useTheme } from '@emotion/react'
import * as Icons from 'react-feather'

import { Box, Button, ContextMenu } from '@prostpost/uikit'
import { useCurrentMedia } from '@prostpost/css'
import type { Color } from '@prostpost/css'

type Props = {
	iconColor: Color
	isDisabled?: boolean
	onToggle?: (isOpen: boolean) => void
	children: React.ReactElement | React.ReactElement[]
}

export const ContextMenuWrapper = ({ iconColor, isDisabled = false, children, onToggle }: Props) => {
	const theme = useTheme()
	const isMobile = useCurrentMedia() === 'MOBILE'
	return (
		<Box ml={2} mr={-2} data-name="post-context-menu">
			<ContextMenu
				onToggle={onToggle}
				isDisabled={isDisabled}
				placement="bottom-start"
				use={({ onClick }) => (
					<Button.Inline
						icon={<Icons.MoreHorizontal color={theme.colors[iconColor]} />}
						color={iconColor}
						onClick={(e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement> | undefined) => {
							if (isMobile) {
								e?.stopPropagation()
								e?.preventDefault()
							}
							onClick()
						}}
					/>
				)}
			>
				{children}
			</ContextMenu>
		</Box>
	)
}
