import React from 'react'

import type { Layout } from '@prostpost/uikit'

import { Container, Content } from './_styled'

export type Props = Omit<React.ComponentProps<typeof Layout.Content>, 'variant'> & {
	children: React.ReactNode | React.ReactNode[]
	className?: string
}

export const PublicHeader: React.FC<Props> = React.memo(({ children, ...props }) => (
	<Container as="header" {...props} variant="public">
		<Content>{children}</Content>
	</Container>
))

PublicHeader.displayName = 'Header'
