import { runInAction } from 'mobx'
import { useEffect, useRef, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { TagsList, Box } from '@prostpost/uikit'

import { fetchDraft } from 'app/domains/Draft/api'
import type { DraftNotScheduled } from 'app/domains/Draft'
import type { EditorStore } from 'app/domains/Draft/store'

import { ColorTagLabel } from '../ColorTagLabel'

import { Container } from './Container'
import { Placeholder } from './Placeholder'
import { PreviewText } from './PreviewText'
import { UpdatedAt } from './UpdatedAt'

type Props = {
	draft: DraftNotScheduled
	search?: string
	hasScaleOnHover: boolean
	hoverVariant: 'shadow' | 'bg'
	className?: string
	wrapper?: 'li' | 'div'
	onSelect?: (draft: DraftNotScheduled) => void
} & (
	| {
			isSelectEnabled: false
	  }
	| {
			isSelectEnabled: true
			isSelected: boolean
			openDraft?: boolean
			editorStore: EditorStore
			onDraftLoaded: (draft: DraftNotScheduled) => void
			onDraftFailedToLoad: (draftUuid: string) => void
	  }
)

const arePropsEqual = (prevProps: Props, nextProps: Props) => {
	if (prevProps.isSelectEnabled && nextProps.isSelectEnabled) {
		if (nextProps.isSelected) return false
		if (prevProps.isSelected !== nextProps.isSelected) return false
		if (prevProps.search !== nextProps.search) return false
	}
	return true
}

export const DraftItem = memo(function DraftItem({
	draft,
	search = '',
	hasScaleOnHover,
	className,
	hoverVariant,
	wrapper = 'li',
	...props
}: Props) {
	const { t } = useTranslation()

	const isSelected = props.isSelectEnabled && props.isSelected
	const timer = useRef<undefined | ReturnType<typeof setTimeout>>()

	const draftLoadingFailedToast = useToast({
		type: 'error',
		text: t('notify:editor.loadDraft.error', 'Unable to load selected draft'),
	})

	const draftDataReceived = (data: DraftNotScheduled) => {
		if (props.isSelectEnabled) {
			// delay setting LOADED status to show loading blur overlay a little longer
			// to avoid it's blinking if loading was very fast
			timer.current = setTimeout(() => {
				props.editorStore.setDraftLoadingStatus('LOADED')
				timer.current && clearTimeout(timer.current)
			}, 500)

			props.editorStore.setContentFromDraft(data)
			props.onDraftLoaded(data)
		}
	}

	const onLoadDraft = () => {
		if (props.isSelectEnabled) {
			props.editorStore.getDraftFromOfflineStorageIfNoRemote({
				uuid: draft.uuid,
				onMsg: msg => {
					switch (msg.type) {
						case 'on_error':
							draftLoadingFailedToast.show()
							props.onDraftFailedToLoad(draft.uuid)
							break
						case 'on_success':
							switch (msg.draft.type) {
								case 'SCHEDULED':
									console.error(
										'Draft item we are trying to get from offline storage is already scheduled',
									)
									props.onDraftFailedToLoad(draft.uuid)
									break
								case 'NOT_SCHEDULED':
									draftDataReceived(msg.draft)
									break
								default:
									notReachable(msg.draft)
							}
							break
						case 'on_has_remote':
							// if we use useDraft (react-query) and call refetch() to trigger it
							// for some reason here in then(...) sometimes response
							// is returned with isFetching: true and with no data yet
							// so as a workaround we use direct api call instead
							// (useEffect by status or onSuccess in useDraft don't work either)
							fetchDraft({ uuid: draft.uuid })
								.then(data => {
									return draftDataReceived(data)
								})
								.catch(e => {
									console.error(e)
									draftLoadingFailedToast.show()
									props.onDraftFailedToLoad(draft.uuid)
								})
							break
						default:
							notReachable(msg)
					}
				},
			})
		}
	}

	const onClick = () => {
		runInAction(() => {
			props.onSelect?.(draft)
			if (props.isSelectEnabled && !props.isSelected) {
				props.editorStore.setDraftUuid(draft.uuid)
				onLoadDraft()
			}
		})
	}

	useEffect(() => {
		if (props.isSelectEnabled && props.openDraft) {
			onClick()
		}
	}, [props.isSelectEnabled && props.openDraft])

	useEffect(() => {
		return () => {
			timer.current && clearTimeout(timer.current)
		}
	}, [])

	return (
		<Container
			as={wrapper}
			isSelected={isSelected}
			hasScaleOnHover={hasScaleOnHover}
			hoverVariant={hoverVariant}
			className={className}
			onClick={onClick}
		>
			<ColorTagLabel colorTag={draft.colorTag} position="absolute" top="18px" left={3} />
			{!draft.ad && draft.tags.length === 0 ? (
				<Placeholder isSelected={isSelected} hoverVariant={hoverVariant} />
			) : null}
			<PreviewText text={draft.mdText || undefined} tags={draft.tags} ad={draft.ad} search={search} />
			{draft.tags.length === 0 && !draft.ad ? null : (
				<Box pt={1}>
					<TagsList
						limit={draft.tags.length + (draft.ad ? 1 : 0) > 4 ? 3 : 4}
						tags={
							draft.ad
								? [
										// TODO: Probably remove # symbol from server side
										...draft.tags.map(tag => ({ text: tag.replace('#', '') })),
										{ text: 'AD', isAd: true },
									]
								: draft.tags
						}
						variant={isSelected ? 'light' : 'dimmed'}
						onClick={onClick}
					/>
				</Box>
			)}
			<UpdatedAt date={draft.updatedAt || draft.createdAt} />
		</Container>
	)
}, arePropsEqual)
