import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getEmojiDataFromNative } from 'emoji-mart'

import { shortNumber } from '@prostpost/utils'
import { HStack, Text, Flex } from '@prostpost/uikit'
import type { CSSMarginProps } from '@prostpost/css'

import type { Reactions as PostReactions } from 'app/domains/Post'

type Props = CSSMarginProps & {
	reactions: PostReactions
}

const StyledReaction = styled(Flex)`
	padding: 3px 6px;
	border-radius: 12px;
	border: 1px solid #d9e6ff;
	background: #f3f7ff;
`

const Reaction = ({ emojiCode, count }: { emojiCode: string; count: number }) => {
	const [emojiNative, setEmojiNative] = useState<string>(emojiCode)
	useEffect(() => {
		getEmojiDataFromNative(emojiCode)
			.then(({ native }: { native: string }) => setEmojiNative(native || emojiCode))
			.catch(console.error)
	}, [])

	return (
		<StyledReaction align="center" just="center">
			<Text size={13} color="blue_40">{`${emojiNative} ${shortNumber(count)}`}</Text>
		</StyledReaction>
	)
}

export const Reactions = observer(function Reactions({ reactions, ...styleProps }: Props) {
	getEmojiDataFromNative(Object.keys(reactions)[0]).then(console.log).catch(console.error)
	return (
		<HStack space={2} {...styleProps}>
			{Object.entries(reactions)
				.sort((a, b) => b[1] - a[1])
				.map(([emoji, count], index) => (
					<Reaction key={index} emojiCode={emoji} count={count} />
				))}
		</HStack>
	)
})
