import type { DraftContent } from 'app/domains/Draft'
import type { Template } from 'app/domains/Template'

export const useTemplateToDraftContent = (template: Template): DraftContent => {
	const { images, tags, ad, silent, text, webPage, colorTag, externalLink } = template
	return {
		images,
		tags,
		ad,
		silent,
		text,
		webPage,
		colorTag,
		externalLink,
	}
}
