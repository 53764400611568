import qs from 'qs'
import moment from 'moment'
import { useQuery } from 'react-query'
import type { Moment } from 'moment'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { PostCalendarCapacity } from '..'

import { PostCalendarCapacitySchema } from './schemas'

const knownErrors = ['INVALID_DRAFTS_CAPACITY_DATE', 'UNABLE_TO_GET_CAPACITY'] as const

const formatDate = (date: Moment): string => {
	const d = moment({ ...date })
	d.set('date', 1)
	return d.format('YYYY-MM-DD')
}

const fetchCapacity = (date: Moment, channelName?: string, utcOffset = 0): Promise<PostCalendarCapacity> => {
	return axiosInstance
		.get<JsonApiResponseSingle>(
			`/drafts/capacity${qs.stringify(
				{ date: formatDate(date), ch_name: channelName, utc_offset: utcOffset },
				{ addQueryPrefix: true },
			)}`,
		)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return PostCalendarCapacitySchema.parse(result.data)
		})
}

export const useCalendarCapacity = ({
	date,
	channelName,
	utcOffset = 0,
	options,
}: {
	date: Moment
	utcOffset: number
	channelName?: string // if not passed - loading across all channels
	options?: UseQueryOptions<PostCalendarCapacity, ParsedError<(typeof knownErrors)[number]>, PostCalendarCapacity>
}) => {
	return {
		calendarCapacityQuery: useQuery<
			PostCalendarCapacity,
			ParsedError<(typeof knownErrors)[number]>,
			PostCalendarCapacity
		>(
			['capacity', channelName, `${date.year()}-${date.month()}`],
			() => fetchCapacity(date, channelName, utcOffset),
			options,
		),
	}
}
