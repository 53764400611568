import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { BotLink } from '..'

import { BotLinkSchema } from './schemas'

const knownErrors = [
	'BOT_LINK_FAILED_INVALID_CODE',
	'BOT_CHAT_ALREADY_LINKED_TO_ANOTHER_ACCOUNT',
	'CONFIRMATION_CODE_NOT_FOUND',
] as const

type Data = {
	code: string
}

const updateBotLink = ({ code }: Data): Promise<BotLink> =>
	axiosInstance
		.patch<JsonApiResponseSingle>('/bot/link', { code })
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return BotLinkSchema.parse(result.data)
		})

export const useUpdateBotLink = () => {
	return {
		updateBotLinkMutation: useMutation<BotLink, ParsedError<(typeof knownErrors)[number]>, Data>(
			'bot_link',
			(data: Data) => updateBotLink(data),
		),
	}
}
