import { notReachable } from '@prostpost/utils'

import { AddChannelModal } from './Modal'
import { AddChannelPlain } from './Plain'
import { AddChannelTile } from './Tile'

import type { Interface as InterfacePlain, Msg as MsgPlain } from './Plain'
import type { Interface as InterfaceModal, Msg as MsgModal } from './Modal'
import type { Interface as InterfaceTile, MsgStatic as MsgStaticTile, MsgAnimated as MsgAnimatedTile } from './Tile'

type Props =
	| ({ variant: 'plain' } & InterfacePlain & { onMsg: (msg: MsgPlain) => void })
	| ({ variant: 'modal' } & InterfaceModal & { onMsg: (msg: MsgModal) => void })
	| ({ variant: 'tile' } & Extract<InterfaceTile, { type: 'static' }> & { onMsg: (msg: MsgStaticTile) => void })
	| ({ variant: 'tile' } & Extract<InterfaceTile, { type: 'animated' }> & { onMsg: (msg: MsgAnimatedTile) => void })

export const AddChannelVariants = (props: Props) => {
	switch (props.variant) {
		case 'plain':
			return <AddChannelPlain {...props} />
		case 'modal':
			return <AddChannelModal {...props} />
		case 'tile':
			return <AddChannelTile {...props} />
		default:
			return notReachable(props)
	}
}
