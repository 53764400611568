import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { parseFirstError } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { STRIP_MEDIA_URL } from 'app/domains/Media/constants'

const knownErrors = ['INVALID_MEDIA_URLS_TO_REMOVE', 'FILES_WERE_NOT_REMOVED'] as const

type Data = {
	urls: string[]
}

const removeImagesByUrls = ({ urls }: Data): Promise<void> => {
	return axiosInstance
		.delete('/upload/remove', {
			headers: { 'Content-Type': 'application/json' },
			data: {
				urls: urls.map(STRIP_MEDIA_URL),
			},
		})
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(() => {
			return
		})
}

export const useRemoveUploadedImagesByUrls = () => {
	return {
		removeUploadedImagesMutation: useMutation<void, ParsedError<(typeof knownErrors)[number]>, Data>(
			'upload',
			removeImagesByUrls,
		),
	}
}
