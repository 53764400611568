import { useTheme } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Button, Item, Group } from '@prostpost/uikit'

import { useBotStore } from 'app/domains/Bot/store'

type Props = {
	onClick: () => void
}

export const UnlinkBotFromUserTrigger = observer(function UnlinkBotFromUserTrigger({ onClick }: Props) {
	const theme = useTheme()
	const { t } = useTranslation()
	const bot = useBotStore()

	return bot.link ? (
		<Group.Item>
			<Group.ItemContent>
				<Item variant="labeled">
					<Item.Title>
						{t('bot.unlinkBotFromUser.actionDescription', 'Unlink our bot from your account')}
					</Item.Title>
				</Item>
			</Group.ItemContent>
			<Group.ItemSide>
				<Button.Action
					size="mid"
					variant="secondary"
					icon={<Icons.Unlink size={16} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={onClick}
				>
					{t('bot.unlinkBotFromUser.actionLabel', 'Unlink')}
				</Button.Action>
			</Group.ItemSide>
		</Group.Item>
	) : null
})
