import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { HStack } from '@prostpost/uikit'

import { SearchInput } from './styled'

type Msg = { type: 'on_drafts_search_changed'; search: string }

type Props = {
	onMsg: (msg: Msg) => void
}

export const Search = ({ onMsg }: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()

	return (
		<HStack space={1} maxW="400px" w="100%" h="56px" align="center">
			<Icons.Search size={16} strokeWidth={2.5} color={theme.colors.blue_40} />
			<SearchInput
				placeholder={t('content:editor.search.placeholder', 'Search drafts...')}
				onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
					onMsg({ type: 'on_drafts_search_changed', search: (e.target as HTMLInputElement).value })
				}
			/>
		</HStack>
	)
}
