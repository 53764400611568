import { toJS, runInAction } from 'mobx'
import { useState, useEffect } from 'react'

import { notReachable } from '@prostpost/utils'

import { useTemplate } from 'app/domains/Template/api'
import { useTemplatesStore } from 'app/domains/Template/store'
import { TemplatesList } from 'app/domains/Template/features/TemplatesList'
import { useCurrentActiveChannel } from 'app/domains/Channel/store'
import { useTemplateToDraftContent } from 'app/domains/Template/hooks'
import { useSyncContentByUuid } from 'app/domains/Draft/hooks'
import type { EditorStore } from 'app/domains/Draft/store'

type Props = {
	editorStore: EditorStore
	trigger: (args: { isOpen: boolean; onClick: () => void }) => React.ReactNode
	resetEditorState: (content: string) => void
}

export const Templates = ({ editorStore, trigger, resetEditorState }: Props) => {
	const templatesStore = useTemplatesStore()
	const currentChannel = useCurrentActiveChannel()
	const channelUuid = runInAction(() => toJS(currentChannel.uuid))

	const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null)
	const { templateQuery } = useTemplate(selectedTemplate || '', { enabled: !!selectedTemplate })

	const { syncContentToRemoteFromOfflineStorage } = useSyncContentByUuid({
		editorStore,
	})

	useEffect(() => {
		switch (templateQuery.status) {
			case 'idle':
				break
			case 'loading':
				editorStore.setDraftLoadingStatus('LOADING')
				editorStore.resetContent()
				break
			case 'error':
				setSelectedTemplate(null)
				editorStore.setDraftLoadingStatus('NO_DRAFT')
				break
			case 'success':
				setSelectedTemplate(null)
				void editorStore.updateContent(useTemplateToDraftContent(templateQuery.data), channelUuid).then(() => {
					return editorStore.draftUuid
						? syncContentToRemoteFromOfflineStorage(editorStore.draftUuid)
						: Promise.reject('Unable immediately update content created from template')
				})
				resetEditorState(templateQuery.data.text || '')
				editorStore.setDraftLoadingStatus('LOADED')
				break
			default:
				notReachable(templateQuery)
		}
	}, [templateQuery.status])

	return (
		<TemplatesList
			variant="popup"
			type="channel"
			trigger={trigger}
			channel={currentChannel}
			onMsg={msg => {
				switch (msg.type) {
					case 'on_template_removed':
						runInAction(() => {
							templatesStore.deleteTemplate(msg.template.uuid)
						})
						break
					case 'on_template_clicked':
						runInAction(() => {
							setSelectedTemplate(msg.template.uuid)
						})
						break
					default:
						notReachable(msg)
				}
			}}
		/>
	)
}
