import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { Text, Skeleton } from '@prostpost/uikit'

export const SkeletonError = () => {
	const theme = useTheme()
	const { t } = useTranslation()
	return (
		<Skeleton height={64} width="100%">
			<Icons.AlertTriangle size={14} strokeWidth={2} color={theme.colors.blue_40} />
			<Text size={13} ml={2} color="blue_40">
				{t('common:editor.linkPreview.error', 'Unable to load link preview')}
			</Text>
		</Skeleton>
	)
}
