import { useTranslation, Trans } from 'react-i18next'

import { Text, Modal, Paragraph, Heading } from '@prostpost/uikit'

import { useDeleteBackup } from 'app/domains/Post/api'

import { ReactModal } from 'app/modals'

type Msg =
	| { type: 'on_cancel_editing_modal_closed' }
	| { type: 'on_backup_cleaned' }
	| { type: 'on_start_cleaning_backup' }

type Props = {
	isOpen: boolean
	draftUuid: string
	onMsg: (msg: Msg) => void
}

export const CancelEditingModal = ({ draftUuid, isOpen, onMsg }: Props) => {
	const { t } = useTranslation()

	const { deleteBackupMutation } = useDeleteBackup({
		onSuccess: () => onMsg({ type: 'on_backup_cleaned' }),
		// we don't care if backup were not removed, we pass error to Sentry to investigate but for user it doesn't matter
		onError: () => onMsg({ type: 'on_backup_cleaned' }),
	})

	return (
		<ReactModal
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			isOpen={isOpen}
			onRequestClose={e => {
				e.preventDefault()
				e.stopPropagation()
				onMsg({ type: 'on_cancel_editing_modal_closed' })
			}}
		>
			<Modal variant="confirm">
				<Modal.Close onClick={() => onMsg({ type: 'on_cancel_editing_modal_closed' })} />
				<Modal.Content>
					<Heading h={4}>{t('content:post.editing.cancelTitle', 'Discard changes')}</Heading>
					<Paragraph my={3} size={14} color="blue_40">
						<Trans i18nKey="content:post.editing.cancelDescription">
							{'Your changes will '}
							<Text size={14} weight={600}>
								{'not'}
							</Text>
							{' be saved. Are you sure you want to cancel editing your post?'}
						</Trans>
					</Paragraph>
				</Modal.Content>
				<Modal.ConfirmSecondaryAction onClick={() => onMsg({ type: 'on_cancel_editing_modal_closed' })}>
					{t('channel.bot.linkCancel', 'Cancel')}
				</Modal.ConfirmSecondaryAction>
				<Modal.ConfirmPrimaryAction
					variant="danger"
					state={deleteBackupMutation.status === 'loading' ? 'pending' : 'normal'}
					onClick={() => {
						onMsg({ type: 'on_start_cleaning_backup' })
						deleteBackupMutation.mutate({ uuid: draftUuid })
					}}
				>
					{t('content:post.editing.confirmCancel', 'Cancel editing')}
				</Modal.ConfirmPrimaryAction>
			</Modal>
		</ReactModal>
	)
}
