import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'

import { useSupabaseAuth } from 'app/domains/User/hooks'

type Args = {
	redirectTo: string
}

export const useSignout = ({ redirectTo }: Args) => {
	const auth = useSupabaseAuth()
	const toast = useToast({ type: 'error' })

	const { t } = useTranslation()

	return {
		signOut: async () => {
			try {
				const { error } = await auth.signOut()

				if (error) {
					console.error(error)
					toast.show({
						type: 'error',
						text: t('auth.signout.unknownError', 'Unable to sign out. Please try again'),
					})
				} else {
					window.location.assign(redirectTo)
				}
			} catch (e: unknown) {
				console.error(e)
				toast.show({
					type: 'error',
					text: t('auth.signout.unknownError', 'Unable to sign out. Please try again'),
				})
			}
		},
	}
}
