import styled from '@emotion/styled'

import { getColor } from '@prostpost/css'

export const SearchInput = styled.input`
	width: 100%;
	height: 56px;

	font-size: 14px;
	line-height: 16px;

	color: ${getColor('black_80')};
	background: transparent;
	border: none;

	/* stylelint-disable selector-no-vendor-prefix */
	&::placeholder,
	&::-webkit-input-placeholder,
	&:-moz-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder {
		user-select: none;
		color: ${getColor('blue_40')};
	}
	/* stylelint-enable selector-no-vendor-prefix */
`
