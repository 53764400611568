import { useState } from 'react'
import { Observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Box, Scrollbars, SlidingPopup, VStack, Heading, Paragraph } from '@prostpost/uikit'

import { useTemplatesStore } from 'app/domains/Template/store'
import { ChannelsDropdown } from 'app/domains/Channel/features/ChannelsDropdown'
import type { TemplateShortInfo } from 'app/domains/Template'

import { DataLoader, TemplateListItem } from './components'

export type Msg =
	| { type: 'on_template_clicked'; template: TemplateShortInfo }
	| { type: 'on_template_removed'; template: TemplateShortInfo }

type MsgProp = {
	onMsg: (msg: Msg) => void
}

export type Interface = {
	trigger: (args: { isOpen: boolean; onClick: () => void }) => React.ReactNode
}

export const SharedTemplatesListPopup = ({ trigger, onMsg }: Interface & MsgProp) => {
	const { t } = useTranslation()

	const templatesStore = useTemplatesStore()
	const [selectedChannelUuid, setSelectedChannelUuid] = useState<string | undefined>()

	return (
		<SlidingPopup width="300px" use={trigger}>
			{({ close }) => (
				<VStack space={5} h="440px">
					<VStack space={1} w="100%">
						<Heading h={4}>{t('content:templates.list.title', 'Templates')}</Heading>
						<Observer>
							{() =>
								templatesStore.templates.length === 0 ? (
									<Paragraph size={12} color="blue_40">
										{t(
											'content:templates.list.createDescription',
											'Create a template to deliver new posts quicker',
										)}
									</Paragraph>
								) : (
									<Paragraph size={12} color="blue_40">
										{t(
											'content:templates.list.chooseDescription',
											'Choose a template to deliver new posts quicker',
										)}
									</Paragraph>
								)
							}
						</Observer>
						<Box mt={3}>
							<ChannelsDropdown
								hasSharedOption
								onSelect={channelUuid => {
									setSelectedChannelUuid(channelUuid === 'shared' ? undefined : channelUuid)
								}}
							/>
						</Box>
					</VStack>

					<Box h="290px">
						<Scrollbars position="relative" trackYStyles={{ left: '-10px' }}>
							{selectedChannelUuid ? (
								<DataLoader isShowShared={false} channelUuid={selectedChannelUuid}>
									{({ data }) => (
										<VStack space={2} w="100%">
											{data.map(template => (
												<TemplateListItem
													key={template.uuid}
													template={template}
													onRemoved={() => onMsg({ type: 'on_template_removed', template })}
													onClick={() => {
														onMsg({ type: 'on_template_clicked', template })
														close()
													}}
												/>
											))}
										</VStack>
									)}
								</DataLoader>
							) : (
								<DataLoader channelUuid={undefined}>
									{({ data }) => (
										<VStack space={2} w="100%">
											{data.map(template => (
												<TemplateListItem
													key={template.uuid}
													template={template}
													onRemoved={() => onMsg({ type: 'on_template_removed', template })}
													onClick={() => {
														onMsg({ type: 'on_template_clicked', template })
														close()
													}}
												/>
											))}
										</VStack>
									)}
								</DataLoader>
							)}
						</Scrollbars>
					</Box>
				</VStack>
			)}
		</SlidingPopup>
	)
}
