import { z } from 'zod'

import type { BotLink } from '..'

export const BotLinkSchema: z.ZodSchema<BotLink, z.ZodTypeDef, unknown> = z.object({
	id: z.number(),
	userUuid: z.string().uuid(),
	name: z.optional(z.string()),
	lastName: z.optional(z.string()),
	username: z.optional(z.string()),
	lang: z.optional(z.string().min(2).max(5)),
})

export const BotUpdateChannelAva: z.ZodSchema<{ avaSmall: string; avaBig: string }, z.ZodTypeDef, unknown> = z.object({
	avaSmall: z.string(),
	avaBig: z.string(),
})
