import { useMutation } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseMutationOptions } from 'react-query'

import { constant } from '@prostpost/utils'
import { parseFirstError } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

const knownErrors = ['DRAFT_NOT_FOUND', 'UNABLE_TO_GET_DRAFT', 'CHAT_NOT_FOUND', 'UNABLE_TO_GET_CHAT'] as const

type Data = {
	uuid: string
}

const unpublishPost = ({ uuid }: Data): Promise<null> => {
	return axiosInstance
		.delete<null>(`/drafts/post/${uuid}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(constant(null))
}

export const useUnpublishPost = (
	{ uuid }: { uuid: string },
	options: Omit<
		UseMutationOptions<null, ParsedError<(typeof knownErrors)[number]>, void>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		unpublishPostMutation: useMutation<null, ParsedError<(typeof knownErrors)[number]>, void>({
			mutationKey: ['post', 'unpublish', uuid],
			mutationFn: () => unpublishPost({ uuid }),
			...options,
		}),
	}
}
