import localforage from 'localforage'
import { createClient } from '@supabase/supabase-js'

import { canUseDOM, notReachable } from '@prostpost/utils'

import { cookiesTyped } from './cookies'
import { MEDIA_ROOT_URL } from './constants'

export const AUTH_ROUTES = {
	signin: '/auth/in',
	signup: '/auth/up',
	inAppHome: '/inbox',
}

export const init = () => {
	const VITE_SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY
	const VITE_SUPABASE_PROJECT_URL = import.meta.env.VITE_SUPABASE_PROJECT_URL

	const authStorage = localforage.createInstance({
		version: 1.0,
		storeName: 'prostpost-auth',
		description: 'Offline storage to keep user authentication',
	})

	const client = createClient(VITE_SUPABASE_PROJECT_URL, VITE_SUPABASE_ANON_KEY, {
		auth: {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			storage: authStorage,
		},
	})

	client.auth.onAuthStateChange((event, session) => {
		switch (event) {
			case 'SIGNED_IN':
			case 'INITIAL_SESSION':
			case 'TOKEN_REFRESHED':
			case 'MFA_CHALLENGE_VERIFIED': {
				if (!session) return
				let cookieDomain = 'localhost'
				if (process.env.NODE_ENV !== 'development') {
					const domainSplitted = import.meta.env.VITE_DOMAIN.split('.')
					cookieDomain = `.${domainSplitted[domainSplitted.length - 2]}.${domainSplitted[domainSplitted.length - 1]}`
				}

				cookiesTyped.setCookie('access_token', session.access_token, {
					path: MEDIA_ROOT_URL,
					domain: cookieDomain,
				})

				break
			}
			case 'SIGNED_OUT':
				cookiesTyped.removeCookie('access_token')
				break
			case 'USER_UPDATED':
			case 'PASSWORD_RECOVERY':
				break
			default:
				notReachable(event)
		}
	})

	const supabaseGenerateGoogleAuthNonce = () => {
		let hashedNonce: string = ''
		const nonce = btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(32))))
		const encoder = new TextEncoder()
		const encodedNonce = encoder.encode(nonce)
		if (crypto.subtle) {
			crypto.subtle.digest('SHA-256', encodedNonce).then(hashBuffer => {
				const hashArray = Array.from(new Uint8Array(hashBuffer))
				hashedNonce = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
			})
		}

		return { nonce, hashedNonce }
	}

	const { nonce, hashedNonce } = supabaseGenerateGoogleAuthNonce()

	let googleOneTapInitIntervalCount = 0
	const googleOneTapInitInterval = setInterval(() => {
		googleOneTapInitIntervalCount++

		if (
			canUseDOM() &&
			(window.location.pathname === AUTH_ROUTES.signin || window.location.pathname === AUTH_ROUTES.signup)
		) {
			console.log(`[GOT] Initialization attempt: ${googleOneTapInitIntervalCount}...`)
			document.querySelectorAll('#g_id_onload').forEach(googleAuthDiv => {
				console.log('[GOT] Setting tag attributes...')
				googleAuthDiv.setAttribute('data-nonce', hashedNonce)
				googleAuthDiv.setAttribute('data-auto_prompt', 'true')

				console.log('[GOT] Initialized successfully!')
				clearInterval(googleOneTapInitInterval)
			})

			if (googleOneTapInitIntervalCount > 10) {
				console.error('[GOT] Initialization failed!')
				clearInterval(googleOneTapInitInterval)
			}
		}
	}, 1000)

	/**
	 * One Tap Google Auth
	 * @param {object} response Google response
	 * @param {string} response.credential Token
	 */
	window.GOOGLE_ONE_TAP_CALLBACK = async function (response: { credential: string }) {
		const { data, error } = await client.auth.signInWithIdToken({
			provider: 'google',
			token: response.credential,
			nonce,
		})

		if (error) {
			console.error(error)
			return
		}

		if (!data.user) {
			console.error('Supabase returned no user on sign up action')
			return
		}

		if (data.user.email) {
			canUseDOM() && window.location.assign(`${import.meta.env.VITE_DOMAIN}${AUTH_ROUTES.inAppHome}`)
		}
	}

	const VITE_SUPABASE_AUTH_REDIRECT_PATH = import.meta.env.VITE_SUPABASE_AUTH_REDIRECT_PATH

	return {
		client,
		VITE_SUPABASE_AUTH_REDIRECT_PATH,
	}
}
