import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { getBorderRadius } from '@prostpost/css'

import { Images } from 'app/resources'

const Image = styled.div<{ noImage?: boolean }>`
	flex-shrink: 0;

	width: 120px;
	height: 64px;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	border-radius: ${getBorderRadius('small')};

	${({ noImage }) =>
		!noImage
			? ''
			: css`
					background-size: contain;
					background-color: #eaf0fb;
				`}
`

export const Thumbnail = ({ image }: { image: string | undefined }) => (
	<Image noImage={!image} style={{ backgroundImage: `url(${image || Images.NoImage})` }} />
)
