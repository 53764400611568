import { useMemo } from 'react'
import removeMd from 'remove-markdown'

import { truncate } from '@prostpost/utils'

import type { DraftScheduled } from 'app/domains/Draft'
import type { FeedPost } from 'app/domains/Post'

const TEXT_LENGTH = 160

type Options = {
	trimMd?: boolean
}

export const usePostTextPreview = (post: DraftScheduled | FeedPost, options?: Options): string => {
	return useMemo(
		() =>
			post.text
				? truncate(options?.trimMd ? removeMd(post.text, { stripListLeaders: false }) : post.text, TEXT_LENGTH)
						.replace(/\n/g, ' ')
						.trim()
				: '',
		[post.text],
	)
}
