import Modal from 'react-modal'
import { Global, css } from '@emotion/react'

import { mediaQueries, MEDIA } from '@prostpost/css'

import { Z_INDEXES } from 'app/config/zIndexes'

export const init = () => {
	Modal.setAppElement('#app')
	Modal.defaultStyles = {}

	return {
		ModalEmotionGlobalStyles: () => (
			<Global
				styles={css`
					.ReactModal__Overlay {
						position: fixed;
						top: 0;
						left: 0;
						z-index: ${Z_INDEXES.REACT_MODAL_OVERLAY};
						display: flex;
						justify-content: center;

						align-items: center;
						width: 100%;
						height: 100%;
						background: rgb(0 14 23 / 50%);

						${mediaQueries[MEDIA.MOBILE]} {
							justify-content: flex-start;
							align-items: flex-start;
						}
					}
				`}
			/>
		),
	}
}
