export const MEDIA_URL = (image: string) => {
	return import.meta.env.NODE_ENV === 'development'
		? `/media/${image}`
		: `${import.meta.env.VITE_MEDIA_DOMAIN_URL}/${image}`
}

export const STRIP_MEDIA_URL = (url: string) => {
	return import.meta.env.NODE_ENV === 'development'
		? url.replace('/media/', '')
		: url.replace('', `${import.meta.env.VITE_MEDIA_DOMAIN_URL}/`)
}
