import { makeLoggable } from 'mobx-log'
import { observable, action, makeObservable } from 'mobx'

import type { BotLink } from '..'

type LinkState = BotLink | false | undefined

type InitArgs = LinkState

export class StoreBot {
	declare link: LinkState

	init(link: InitArgs) {
		this.link = link
	}

	constructor(args: InitArgs) {
		this.init(args)

		makeObservable(this, {
			link: observable,

			init: action,
			setLink: action,
		})

		makeLoggable(this)
	}

	setLink(link: LinkState): void {
		this.link = link
	}
}
