import React from 'react'
import { ErrorBoundary as BaseErrorBoundary } from 'react-error-boundary'

import { ErrorWidget } from '../ErrorWidget'

export const ErrorBoundary = ({
	children,
	actionTitle,
	onClickAction,
}: {
	actionTitle: string
	onClickAction: () => void
	children: React.ReactElement | React.ReactElement[]
}) => (
	<BaseErrorBoundary
		fallbackRender={({ error, resetErrorBoundary }) => {
			return (
				<ErrorWidget
					isFullScreen
					size="big"
					error={{
						code: 'ERROR_BOUNDARY',
						status: 0,
						detail: (error as unknown as { message?: string }).message,
					}}
					onTryAgain={() => window.location.reload()}
				>
					<ErrorWidget.Action
						onClick={() => {
							resetErrorBoundary()
							onClickAction()
						}}
					>
						{actionTitle}
					</ErrorWidget.Action>
				</ErrorWidget>
			)
		}}
	>
		{children}
	</BaseErrorBoundary>
)
