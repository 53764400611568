import axios from 'axios'
import type { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { UploadImagesSchema } from './schemas'

type UploadFormData = FormData & {
	values?: () => Array<{
		name: string
	}>
}

type Data = {
	formData: UploadFormData
	axiosConfig: AxiosRequestConfig
}

const knownErrors = [
	'INVALID_MEDIA_LOCATION',
	'INVALID_FILE_EXTENSION',
	'MAXIMUM_FILE_SIZE_EXCEEDED',
	'CAN_NOT_MAKE_FILE_PUBLIC_CDN',
] as const

export type UploadImageError = ParsedError<(typeof knownErrors)[number]>

// we don't use react-query here because we need to cancel this requests on image removal
// and react-query can't cancel mutations
export const uploadImages = ({ formData, axiosConfig }: Data, signal?: AbortSignal): Promise<string[]> => {
	return axiosInstance
		.post<JsonApiResponseSingle, AxiosResponse<JsonApiResponseSingle>, UploadFormData>('/upload/upload', formData, {
			...axiosConfig,
			headers: { 'Content-Type': 'multipart/form-data' },
			signal,
		})
		.then(response => {
			const result = parseSingle(response.data)
			return UploadImagesSchema.parse(result.data).urls
		})
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			console.error(e)
			if (axios.isCancel(e)) return []
			throw parseFirstError(e, knownErrors)
		})
}
