import type { PostHistory } from 'app/domains/Post'

export const usePostForwardedFromLabel = (post: PostHistory): string | null => {
	const { forwardedFrom } = post
	return forwardedFrom
		? forwardedFrom.title ||
				(forwardedFrom.firstName
					? `${forwardedFrom.firstName} ${forwardedFrom.lastName || ''}`
					: forwardedFrom.username)
		: null
}
