import { useState, useEffect } from 'react'

import { useSupabaseAuth } from 'app/domains/User/hooks'

export const useIsUserSignedIn = () => {
	const [isSignedIn, setIsSignedIn] = useState<boolean | null>(null)
	const auth = useSupabaseAuth()

	useEffect(() => {
		auth.getSession()
			.then(response => {
				if (response.data.session && !response.error) {
					setIsSignedIn(true)
				} else {
					setIsSignedIn(false)
				}
				return null
			})
			.catch((error: unknown) => {
				console.error(error)
				setIsSignedIn(false)
			})
	}, [])

	return isSignedIn
}
