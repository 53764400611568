import { useState } from 'react'

export const useLoadingPosts = () => {
	const [loadingPostUuid, setLoadingPostUuid] = useState<string[]>([])

	const addLoadingPost = (uuid: string) => {
		setLoadingPostUuid(p => [...new Set([...p, uuid])])
	}

	const removeLoadingPost = (uuid: string) => {
		setLoadingPostUuid(p => p.filter(u => u !== uuid))
	}

	const isPostLoading = (uuid: string) => {
		return loadingPostUuid.includes(uuid)
	}

	return { addLoadingPost, removeLoadingPost, isPostLoading }
}
