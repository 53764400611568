import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { Toggle, Group } from '@prostpost/uikit'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import type { UpdatePreferencesData, AdditionalPreferences } from 'app/domains/UserPreferences/api'

type Msg =
	| { type: 'on_change_silent_bot_notifications'; value: boolean }
	| { type: 'on_change_notify_on_publish'; value: boolean }

type Interface = {
	loadingState: Array<keyof UpdatePreferencesData | keyof AdditionalPreferences>
	onMsg: (msg: Msg) => void
}

export const UpdateBotNotifications = observer(function BotNotifications({ loadingState, onMsg }: Interface) {
	const { t } = useTranslation()
	const { preferences } = useOnboardedUserStore()

	return (
		<Group>
			<Group.Title>{t('userSettings.preferences.botNotificationsTitle', 'Bot notifications')}</Group.Title>
			<Group.Item>
				<Group.ItemContent>
					<Toggle
						on={preferences.feed.isBotNotificationSilent}
						disabled={loadingState.includes('silentBotNotifications')}
						onToggle={value => onMsg({ type: 'on_change_silent_bot_notifications', value })}
					>
						<Toggle.Label color="black_80">
							{t('userSettings.preferences.botNotifySilent', 'Silent bot notifications')}
						</Toggle.Label>
					</Toggle>
				</Group.ItemContent>
			</Group.Item>
			<Group.Item>
				<Group.ItemContent>
					<Toggle
						on={preferences.feed.isNotifyOnPublishEnabled}
						disabled={loadingState.includes('notifyOnPublish')}
						onToggle={value => onMsg({ type: 'on_change_notify_on_publish', value })}
					>
						<Toggle.Label color="black_80">
							{t('userSettings.preferences.botNotifyScheduledPostPublished', 'Scheduled post published')}
						</Toggle.Label>
					</Toggle>
				</Group.ItemContent>
			</Group.Item>
		</Group>
	)
})
