/* eslint-disable */
// @ts-nocheck
// import type React from 'react'
/// <reference types="vite-plugin-svgr/client" />

import TwoPlanesColor from './images/two-planes-color.png'
import VerifyEmail from './images/verify-email.png'
import NoImage from './images/no-image.png'
import AddBotToChannelAdmins from './images/add-bot-to-channel-admins.png'
import OnboardingWelcome from './images/onboarding-welcome.png'
import OnboardingBot from './images/onboarding-bot.png'
import AddNewChannel from './images/add-new-channel.png'
import BotLink from './images/bot-link.png'
import ChannelLocked from './images/channel-locked.png'
import NoChannelsPlaceholder from './images/no-channels-placeholder.png'
import PricingFree from './images/pricing-free.png'
import PricingPro from './images/pricing-pro.png'
import WiseMan from './images/wise-man-illustration.jpg'

// it's necessary to use "ReactComponent as" otherwise we have an error while building with Vite
import Google from './icons/google.inline.svg?react'
import GraphPlaceholder from './icons/graph-placeholder.inline.svg?react'

export const Icons = {
	Google: Google as React.FC,
	GraphPlaceholder: GraphPlaceholder as React.FC,
}

export const Images = {
	TwoPlanesColor: TwoPlanesColor as string,
	VerifyEmail: VerifyEmail as string,
	NoImage: NoImage as string,
	AddBotToChannelAdmins: AddBotToChannelAdmins as string,
	OnboardingWelcome: OnboardingWelcome as string,
	OnboardingBot: OnboardingBot as string,
	AddNewChannel: AddNewChannel as string,
	BotLink: BotLink as string,
	ChannelLocked: ChannelLocked as string,
	NoChannelsPlaceholder: NoChannelsPlaceholder as string,
	PricingPro: PricingPro as string,
	PricingFree: PricingFree as string,
	WiseMan: WiseMan as string,
}
