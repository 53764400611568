/**
 * Based on https://github.com/justinmoon/tiptap-markdown-demo/blob/master/src/Tiptap.jsx
 */
import { marked } from 'marked'
import { MarkdownSerializer, defaultMarkdownSerializer } from 'prosemirror-markdown'
import { DOMParser as ProseMirrorDOMParser } from 'prosemirror-model'
import Paragraph from '@tiptap/extension-paragraph'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Strike from '@tiptap/extension-strike'
import Italic from '@tiptap/extension-italic'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import HardBreak from '@tiptap/extension-hard-break'
import Code from '@tiptap/extension-code'
import Bold from '@tiptap/extension-bold'
import Underline from '@tiptap/extension-underline'
import Blockquote from '@tiptap/extension-blockquote'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import Link from '@tiptap/extension-link'
import type { Schema } from 'prosemirror-model'
import type { JSONContent } from '@tiptap/core'
import type { Node as ProseMirrorNode, Mark as ProseMirrorMark, Schema as ProseMirrorSchema } from '@tiptap/pm/model'
import type { MarkdownSerializerState } from 'prosemirror-markdown'

import { Spoiler } from '../Spoiler'
import { Hashtags } from '../Hashtags'

const renderHardBreak = (
	state: MarkdownSerializerState,
	node: ProseMirrorNode,
	parent: ProseMirrorNode,
	index: number,
) => {
	const br = '\\\n'
	for (let i = index + 1; i < parent.childCount; i += 1) {
		if (parent.child(i).type !== node.type) {
			state.write(br)
			return
		}
	}
}

const renderOrderedList = (state: MarkdownSerializerState, node: ProseMirrorNode) => {
	const { parens, start = 1 } = node.attrs as { parens: boolean; start: number }
	const maxW = String(start + node.childCount - 1).length
	const space = state.repeat(' ', maxW + 2)
	const delimiter = parens ? ')' : '.'
	state.renderList(node, space, i => {
		const nStr = String(start + i)
		return `${state.repeat(' ', maxW - nStr.length) + nStr}${delimiter} `
	})
}

const isPlainURL = (link: ProseMirrorMark, parent: ProseMirrorNode, index: number, side: number) => {
	const { href, title } = link.attrs as { href: string; title: string }
	if (title || !/^\w+:/.test(href)) return false
	const content = parent.child(index + (side < 0 ? -1 : 0))
	if (!content.isText || content.text !== link.attrs.href || content.marks[content.marks.length - 1] !== link)
		return false
	if (index === (side < 0 ? 1 : parent.childCount - 1)) return true
	const next = parent.child(index + (side < 0 ? -2 : 1))
	return !link.isInSet(next.marks)
}

const serializerMarks = {
	...defaultMarkdownSerializer.marks,
	[Bold.name]: defaultMarkdownSerializer.marks.strong,
	[Strike.name]: {
		open: '~',
		close: '~',
		mixable: true,
		expelEnclosingWhitespace: true,
	},
	[Italic.name]: {
		open: '_',
		close: '_',
		mixable: true,
		expelEnclosingWhitespace: true,
	},
	[Bold.name]: {
		open: '*',
		close: '*',
		mixable: true,
		expelEnclosingWhitespace: true,
	},
	[Underline.name]: {
		open: '__',
		close: '__',
		mixable: true,
		expelEnclosingWhitespace: true,
	},
	[Spoiler.name]: {
		open: '||',
		close: '||',
		mixable: true,
		expelEnclosingWhitespace: true,
	},
	[Code.name]: defaultMarkdownSerializer.marks.code,
	[Link.name]: {
		open(_: MarkdownSerializerState, mark: ProseMirrorMark, parent: ProseMirrorNode, index: number) {
			return isPlainURL(mark, parent, index, 1) ? '' : '['
		},
		close(state: MarkdownSerializerState, mark: ProseMirrorMark, parent: ProseMirrorNode, index: number) {
			const { canonicalSrc, href, title } = mark.attrs as { href: string; canonicalSrc: string; title: string }
			return isPlainURL(mark, parent, index, -1)
				? ''
				: '](' + state.esc(canonicalSrc || href) + mark.attrs.title
					? ' ' + title
					: '' + ')'
		},
	},
}

const serializerNodes = {
	...defaultMarkdownSerializer.nodes,
	[Paragraph.name]: defaultMarkdownSerializer.nodes.paragraph,
	[BulletList.name]: defaultMarkdownSerializer.nodes.bullet_list,
	[ListItem.name]: defaultMarkdownSerializer.nodes.list_item,
	[HorizontalRule.name]: defaultMarkdownSerializer.nodes.horizontal_rule,
	[OrderedList.name]: renderOrderedList,
	[HardBreak.name]: renderHardBreak,
	[CodeBlockLowlight.name]: (state: MarkdownSerializerState, node: ProseMirrorNode) => {
		const { language } = node.attrs as { language: string }
		state.write(`\`\`\`${language || ''}\n`)
		state.text(node.textContent, false)
		state.ensureNewLine()
		state.write('```')
		state.closeBlock(node)
	},
	[Blockquote.name]: (state: MarkdownSerializerState, node: ProseMirrorNode) => {
		if (node.attrs.multiline) {
			state.write('>>>')
			state.ensureNewLine()
			state.renderContent(node)
			state.ensureNewLine()
			state.write('>>>')
			state.closeBlock(node)
		} else {
			state.wrapBlock('> ', null, node, () => state.renderContent(node))
		}
	},
	[Hashtags.name]: (state: MarkdownSerializerState, node: ProseMirrorNode) => {
		const { id } = node.attrs as { id: string }
		state.write(`\\#${id}`)
	},
}

const serialize = (schema: ProseMirrorSchema, content: JSONContent): string => {
	const proseMirrorDocument = schema.nodeFromJSON(content)
	const serializer = new MarkdownSerializer(serializerNodes, serializerMarks, {
		// check if we need to escape anything else
		// NB:
		// 1. *[]~_ characters are escaped by default so we don't need to add them here to avoid extra // in the result text
		// 2. # handled by Hashtags above but we still keep it here in case we use # as not a part of a tag
		escapeExtraCharacters: /([|{}+)(#>!=\-.])/gm,
	})
	return serializer.serialize(proseMirrorDocument, {
		tightLists: true,
	})
}

const deserialize = async (schema: Schema, content: string): Promise<JSONContent | null> => {
	let htmlObject = marked.parse(content)
	if (!htmlObject) return null
	if (typeof htmlObject === 'string') htmlObject = Promise.resolve(htmlObject)

	const html = await htmlObject
	const parser = new DOMParser()
	const { body } = parser.parseFromString(html, 'text/html')

	// append original source as a comment that nodes can access
	body.append(document.createComment(content))

	const state = ProseMirrorDOMParser.fromSchema(schema).parse(body)
	return state.toJSON() as JSONContent
}

// const loadMarkdownInput = (editor: Editor, markdownInput: string) => {
// 	const deserialized = deserialize(editor.schema, markdownInput)
// 	editor.commands.setContent(deserialized)
// }

// To parse into markdown you can use onCreate and onUpdate arguments for useEditor hook
//
// onCreate({ editor }) {
// 	setMarkdownOutput(serialize(editor.schema, editor.getJSON()))
// }

// onUpdate: ({ editor }) => {
// 	setMarkdownOutput(serialize(editor.schema, editor.getJSON()))
// }

export const Markdown = {
	serialize,
	deserialize,
}
