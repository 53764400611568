import { useTranslation } from 'react-i18next'

import { Box } from '@prostpost/uikit'
import type { CSSPaddingProps } from '@prostpost/css'
import type { ParsedError } from '@prostpost/jsonapi'

import { ErrorWidget as ErrorWidgetBase } from 'app/domains/Error/components'
import type { ErrorType } from 'app/domains/Error'

type Props = CSSPaddingProps & {
	type?: ErrorType
	error: ParsedError<unknown>
	onClickTryAgain: () => void
}

export const ErrorWidget = ({ error, type, onClickTryAgain, ...paddingProps }: Props) => {
	const { t } = useTranslation()
	return (
		<Box {...paddingProps}>
			<ErrorWidgetBase
				size="mid"
				isFullScreen={false}
				type={type}
				error={error}
				title={t('labels.error', 'Error')}
				summary={t('feed.fetch.errorSummary', 'Unable to fetch posts')}
				onTryAgain={onClickTryAgain}
			/>
		</Box>
	)
}
