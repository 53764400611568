import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { FeedbackMessage, Category } from '..'

import { FeedbackMessageSchema } from './schemas'

const knownErrors = ['UNABLE_TO_CREATE_FEEDBACK_MESSAGE'] as const

type Data = {
	rating: number
	category: Category
	message?: string
}

const createFeedbackMessage = ({ data }: { data: Data }): Promise<FeedbackMessage> =>
	axiosInstance
		.post<JsonApiResponseSingle>('/bot/feedback', data)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return FeedbackMessageSchema.parse(result.data)
		})

export const useCreateFeedbackMessage = () => {
	return {
		useCreateFeedbackMutation: useMutation<FeedbackMessage, ParsedError<(typeof knownErrors)[number]>, Data>(
			'channel',
			(data: Data) => createFeedbackMessage({ data }),
		),
	}
}
