import { notReachable } from '@prostpost/utils'

import { TilesWithChannel, TilesOutsideChannel } from './variants'
import type { InterfaceWithChannel, InterfaceOutsideChannel } from './variants'

type Props =
	| ({ variant: 'with-channel' } & InterfaceWithChannel)
	| ({ variant: 'outside-channel' } & InterfaceOutsideChannel)

export const PostsList = (props: Props) => {
	switch (props.variant) {
		case 'with-channel':
			return <TilesWithChannel {...props} />
		case 'outside-channel':
			return <TilesOutsideChannel {...props} />
		default:
			return notReachable(props)
	}
}
