import { createContext, useContext } from 'react'

import type { NotOnboardedUserStore } from './store'

export const NotOnboardedUserStoreContext = createContext<NotOnboardedUserStore | undefined>(undefined)

export const useNotOnboardedUserStore = (): NotOnboardedUserStore => {
	const user = useContext(NotOnboardedUserStoreContext)
	if (user === undefined) {
		throw Error('useNotOnboardedUserStore can be used only within NotOnboardedUserStoreContext')
	}

	return user
}
