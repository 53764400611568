import { notReachable } from '@prostpost/utils'

import { BetaInfoPlain } from './Plain'
import { BetaInfoTile } from './Tile'

type Props = { variant: 'plain' } | { variant: 'tile' }

export const BetaInfoVariants = (props: Props) => {
	switch (props.variant) {
		case 'plain':
			return <BetaInfoPlain />
		case 'tile':
			return <BetaInfoTile />
		default:
			return notReachable(props)
	}
}
