import { useEffect } from 'react'
import { runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import * as Icons from 'react-feather'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { Box, Tooltip, ContextMenu } from '@prostpost/uikit'

import { useUpdateScheduledDraft } from 'app/domains/Draft/api'
import type { DraftScheduled } from 'app/domains/Draft'

type Msg =
	| { type: 'on_click_toggle_silent'; isSilent: boolean }
	| { type: 'on_toggle_silent_failed'; post: DraftScheduled; prevIsSilent: boolean }

type Props = {
	variant: 'icon' | 'context'
	post: DraftScheduled
	isDisabled?: boolean
	isPostPublishingFailed: boolean
	onMsg: (msg: Msg) => void
}

export const ToggleSilentMode = observer(function ToggleSilentMode({
	post,
	variant,
	isDisabled,
	isPostPublishingFailed,
	onMsg,
}: Props) {
	const theme = useTheme()
	const { t } = useTranslation()

	const scheduleFailedToast = useToast({
		type: 'error',
		text: t('notify:draft.toggleSilentMode.failure', 'Unable to toggle silent mode'),
	})

	const { updateScheduledDraftMutation } = useUpdateScheduledDraft()

	const iconProps = {
		size: 14,
		strokeWidth: 2,
		color: isPostPublishingFailed ? theme.colors.red_100 : theme.colors.blue_40,
	}

	useEffect(() => {
		switch (updateScheduledDraftMutation.status) {
			case 'idle':
			case 'loading':
			case 'success':
				break
			case 'error': {
				console.error(updateScheduledDraftMutation.error)
				onMsg({
					type: 'on_toggle_silent_failed',
					post,
					prevIsSilent: !updateScheduledDraftMutation.variables?.silent,
				})
				scheduleFailedToast.show()
				break
			}
			default:
				notReachable(updateScheduledDraftMutation)
		}
	}, [updateScheduledDraftMutation.status])

	const onClick = () => {
		runInAction(() => {
			onMsg({ type: 'on_click_toggle_silent', isSilent: !post.silent })
			updateScheduledDraftMutation.mutate({ uuid: toJS(post).uuid, silent: !post.silent })
		})
	}

	switch (variant) {
		case 'icon':
			return (
				<Tooltip
					placement="bottom-end"
					showDelay={1200}
					content={
						post.silent
							? t('content:post.silentToggle.tooltipOff', 'Publish without notification')
							: t('content:post.silentToggle.tooltipOn', 'Publish with notification')
					}
				>
					<Box
						position="relative"
						onClick={e => {
							e.preventDefault()
							e.stopPropagation()
							onClick()
						}}
					>
						{post.silent ? <Icons.BellOff {...iconProps} /> : <Icons.Bell {...iconProps} />}
					</Box>
				</Tooltip>
			)
		case 'context':
			return (
				<ContextMenu.Item
					variant={isDisabled ? 'disabled' : 'normal'}
					icon={post.silent ? Icons.Bell : Icons.BellOff}
					onClick={onClick}
				>
					{post.silent ? t('labels.silentOn', 'Enable sound') : t('labels.silentOff', 'Disable sound')}
				</ContextMenu.Item>
			)
		default:
			return notReachable(variant)
	}
})
