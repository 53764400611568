import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Button, Layout, Nav } from '@prostpost/uikit'

import { config } from 'app/config'

import { routesUrls } from '../../urls'
import { WithFooter, WithAuthNav } from '../../Wrappers'

export const WithPolicy = () => {
	const { t } = useTranslation()

	return (
		<Layout.WithCustomScroll>
			{() => (
				<Layout variant="article">
					<WithAuthNav
						routes={{
							signIn: routesUrls.auth.signin,
							signUp: routesUrls.auth.signup,
							redirectKnownUserTo: `${config.constants.DOMAIN}${routesUrls.inbox}`,
						}}
					>
						<WithFooter
							// TODO: Add contact us functionality
							contactUsRouteUrl=""
							policyRouteUrl={routesUrls.policy.pp}
						>
							<Layout.Content variant="public" py={[8, 8, 8, 8]}>
								<Nav mediaVerticalOnRes="not-desktop">
									<Nav.Item variant="nav">
										<Button.Nav
											to={routesUrls.policy.pp}
											title={t('pp.nav.pp', 'Privacy Policy')}
										/>
									</Nav.Item>
									<Nav.Item variant="nav">
										<Button.Nav
											to={routesUrls.policy.terms}
											title={t('pp.nav.tc', 'Terms and Conditions')}
										/>
									</Nav.Item>
									<Nav.Item variant="nav">
										<Button.Nav
											to={routesUrls.policy.cookie}
											title={t('pp.nav.cp', 'Cookie Policy')}
										/>
									</Nav.Item>
									<Nav.Item variant="nav">
										<Button.Nav
											to={routesUrls.policy.refund}
											title={t('pp.nav.rp', 'Refund Policy')}
										/>
									</Nav.Item>
									<Nav.Item variant="nav">
										<Button.Nav to={routesUrls.policy.use} title={t('pp.nav.user', 'Use policy')} />
									</Nav.Item>
									<Nav.Item variant="nav">
										<Button.Nav
											to={routesUrls.policy.disclaimer}
											title={t('pp.nav.disc', 'Disclaimer')}
										/>
									</Nav.Item>
								</Nav>
							</Layout.Content>

							{/* Box with min height to keep Footer stick to the bottom if content takes less than full window height */}
							{/* where 350px is space taken by header and navigation */}
							<Box minH="calc(100vh - 350px)">
								<Outlet />
							</Box>
						</WithFooter>
					</WithAuthNav>
				</Layout>
			)}
		</Layout.WithCustomScroll>
	)
}
