import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { ParsedError, JsonApiErrorResponse, JsonApiResponseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { BotLink } from '..'

import { BotLinkSchema } from './schemas'

const knownErrors = ['CHAT_NOT_FOUND'] as const

const fetchBotLink = (): Promise<BotLink> =>
	axiosInstance
		.get<JsonApiResponseSingle>('/bot/link')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return BotLinkSchema.parse(result.data)
		})

export const useBotLink = () => {
	return {
		botLinkQuery: useQuery<BotLink, ParsedError<(typeof knownErrors)[number]>, BotLink>('bot_link', fetchBotLink),
	}
}
