import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { User } from '..'

import { UserSchema } from './schemas'

const knownErrors = ['USER_NOT_FOUND', 'USER_INACTIVE', 'USER_BANNED', 'USER_ARCHIVED'] as const

const fetchUser = (): Promise<User> =>
	axiosInstance
		.get<JsonApiResponseSingle>('/auth/me')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return UserSchema.parse(result.data)
		})

export const useUser = (
	options: Omit<UseQueryOptions<User, ParsedError<(typeof knownErrors)[number]>, User>, 'queryKey' | 'queryFn'> = {},
) => {
	return {
		userQuery: useQuery<User, ParsedError<(typeof knownErrors)[number]>, User>('user', fetchUser, options),
	}
}
