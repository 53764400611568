import { useTranslation } from 'react-i18next'
import { useEditor, mergeAttributes } from '@tiptap/react'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Code from '@tiptap/extension-code'
import Link from '@tiptap/extension-link'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Underline from '@tiptap/extension-underline'
import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Paragraph from '@tiptap/extension-paragraph'
import BubbleMenu from '@tiptap/extension-bubble-menu'
import Placeholder from '@tiptap/extension-placeholder'
import CharactersCounter from '@tiptap/extension-character-count'
import type { SuggestionOptions } from '@tiptap/suggestion'
import type { EditorOptions } from '@tiptap/react'

import { Spoiler } from '../features/DraftEditor/extensions/Spoiler'
import { Hashtags } from '../features/DraftEditor/extensions/Hashtags'

// Wait for that issue to solve so we can set exitable option via configure method
// before that issue is solved configure overrides all options from extend so we can't call configure as we
// do for other marks below
// https://github.com/ueberdosis/tiptap/issues/3813
const CodeExitable = Code.extend({
	exitable: true,
	renderHTML({ HTMLAttributes }) {
		return [
			'code',
			mergeAttributes(
				this.options.HTMLAttributes,
				{
					class: 'editor-text-code',
				},
				HTMLAttributes,
			),
			0,
		]
	},
})

const LinkExitable = Link.extend({
	exitable: true,
	renderHTML({ HTMLAttributes }) {
		return [
			'a',
			mergeAttributes(
				this.options.HTMLAttributes,
				{
					class: 'editor-text-link',
				},
				HTMLAttributes,
			),
			0,
		]
	},
	addOptions() {
		return {
			...Link.options,
			// doesn't work if placed directly in extend above
			openOnClick: false,
		}
	},
})

export const usePredefinedEditor = ({
	content,
	recentTags,
}: {
	content: EditorOptions['content']
	recentTags: Omit<SuggestionOptions, 'editor'>
}) => {
	const { t } = useTranslation()
	return useEditor({
		content,
		extensions: [
			// These are mandatory
			Text,
			Document,
			Paragraph.configure({
				HTMLAttributes: {
					class: 'editor-paragraph',
				},
			}),
			// Marks
			Italic,
			Strike,
			Underline,
			Bold.configure({
				HTMLAttributes: {
					class: 'editor-text-bold',
				},
			}),
			Hashtags.configure({
				suggestion: recentTags,
				HTMLAttributes: {
					class: 'editor-text-tag',
				},
			}),
			CodeExitable,
			LinkExitable,
			Spoiler.configure({
				HTMLAttributes: {
					class: 'editor-text-spoiler',
				},
			}),
			// Non text extensions
			History,
			BubbleMenu,
			CharactersCounter,
			Placeholder.configure({ placeholder: t('content:editor.placeholder', 'Start your awesome post...') }),
		],
	})
}
