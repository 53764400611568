import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { DraftNotScheduled } from '..'

import { DraftNotScheduledSchema } from './schemas'

const knownErrors = ['UNABLE_TO_GET_DRAFT', 'DRAFT_NOT_FOUND'] as const

type Data = { uuid: string }

export const fetchDraft = ({ uuid }: Data): Promise<DraftNotScheduled> =>
	axiosInstance
		.get<JsonApiResponseSingle>(`/drafts/draft/${uuid}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return DraftNotScheduledSchema.parse(result.data)
		})

export const useDraft = (
	uuid: string,
	options: Omit<
		UseQueryOptions<DraftNotScheduled, ParsedError<(typeof knownErrors)[number]>, DraftNotScheduled>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		draftQuery: useQuery<DraftNotScheduled, ParsedError<(typeof knownErrors)[number]>, DraftNotScheduled>(
			['draft', uuid],
			() => fetchDraft({ uuid }),
			{
				enabled: false,
				...options,
			},
		),
	}
}
