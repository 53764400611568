type LocalStorageItems = {
	isSigningIn: 'true'
	'umami.disabled': '0' | '1'
	imagesProcessingWarning: 'visible' | 'hidden'
	showTrialSticker: string
}

const getItem = <K extends keyof LocalStorageItems>(key: K): LocalStorageItems[K] | null => {
	return localStorage.getItem(key) as LocalStorageItems[K] | null
}

const setItem = <K extends keyof LocalStorageItems>(key: K, value: LocalStorageItems[K]) => {
	localStorage.setItem(key, value)
}

const removeItem = (key: keyof LocalStorageItems) => {
	localStorage.removeItem(key)
}

export const localStorageTyped = {
	getItem,
	setItem,
	removeItem,
}
