import { notReachable } from '@prostpost/utils'

import { NoPostsPlaceholderMultiChannel, NoPostsPlaceholderSingleChannel } from './NoPostsPlaceholder'
import type { Props as PropsSingleChannel, PropsMsg as PropsMultiChannel } from './NoPostsPlaceholder'

export * from './ErrorWidget'

type Props = ({ type: 'MULTI_CHANNEL' } & PropsMultiChannel) | ({ type: 'SINGLE_CHANNEL' } & PropsSingleChannel)

export const NoPostsPlaceholder = (props: Props) => {
	switch (props.type) {
		case 'MULTI_CHANNEL':
			return <NoPostsPlaceholderMultiChannel {...props} />
		case 'SINGLE_CHANNEL':
			return <NoPostsPlaceholderSingleChannel {...props} />
		default:
			return notReachable(props)
	}
}
