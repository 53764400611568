import type { StoreUserPreferences } from 'app/domains/UserPreferences/store'

import { useTimezones } from './useTimezones'
import { useDefaultTimezone } from './useDefaultTimezone'

export const useUserTimezone = (preferences: StoreUserPreferences) => {
	const timezones = useTimezones()
	const defaultTimezone = useDefaultTimezone()
	return timezones.find(tz => tz.value == preferences.timeZone) || defaultTimezone
}
