import { useTranslation } from 'react-i18next'
import { Maximize } from 'lucide-react'

import { ContextMenu } from '@prostpost/uikit'

type Msg = { type: 'on_click_open_modal_editor' }

type Props = {
	onMsg: (msg: Msg) => void
}

export const Open = ({ onMsg }: Props) => {
	const { t } = useTranslation()

	return (
		<ContextMenu.Item icon={Maximize} onClick={() => onMsg({ type: 'on_click_open_modal_editor' })}>
			{t('labels:openPost', 'Open')}
		</ContextMenu.Item>
	)
}
