import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'

import { getAnimation } from '@prostpost/css'
import { Paragraph } from '@prostpost/uikit'

import { Container, toggleBgOnSelection } from './Container'

type Props = {
	isSelected: boolean
	hoverVariant: 'shadow' | 'bg'
}

const PlaceholderContainer = styled.div<Props>`
	position: absolute;
	top: 17px;

	& + p {
		transition:
			transform ${getAnimation('tt_tf')},
			background ${getAnimation('tt_tf')},
			box-shadow ${getAnimation('tt_tf')};

		${props => {
			switch (props.hoverVariant) {
				case 'bg':
					return toggleBgOnSelection(props)
				case 'shadow':
				default:
					return css`
						background: ${props.theme.colors.white_100};
					`
			}
		}}

		${Container}:hover & {
			${({ theme, hoverVariant }) => {
				switch (hoverVariant) {
					case 'bg':
						return css`
							background: ${theme.colors.blue_10};
						`
					case 'shadow':
					default:
						return css`
							background: ${theme.colors.white_100};
						`
				}
			}}
		}

		& + ul {
			transition:
				transform ${getAnimation('tt_tf')},
				background ${getAnimation('tt_tf')},
				box-shadow ${getAnimation('tt_tf')};

			${props => {
				switch (props.hoverVariant) {
					case 'bg':
						return toggleBgOnSelection(props)
					case 'shadow':
					default:
						return css`
							background: ${props.theme.colors.white_100};
						`
				}
			}}

			${Container}:hover & {
				${({ theme, hoverVariant }) => {
					switch (hoverVariant) {
						case 'bg':
							return css`
								background: ${theme.colors.blue_10};
							`
						case 'shadow':
						default:
							return css`
								background: ${theme.colors.white_100};
							`
					}
				}}
			}
		}
	}
`

export const Placeholder = (props: Props) => {
	const { t } = useTranslation()
	return (
		<PlaceholderContainer {...props}>
			<Paragraph fontStyle="italic" size={14} color="blue_40">
				{t('draft.preview.oops', 'Oops \n This one feels empty...')}
			</Paragraph>
		</PlaceholderContainer>
	)
}
