import React, { useContext, createContext, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import type { MotionProps } from 'framer-motion'
import type { Scrollbar } from 'react-scrollbars-custom'

import { useCurrentMedia } from '@prostpost/css'
import { Box, Scrollbars } from '@prostpost/uikit'

import { useShowTopSticker } from 'app/shared/hooks'
import { HEADER_HEIGHT, HEADER_HEIGHT_WITH_STICKER } from 'app/shared/constants'

type Context = {
	isOpen: boolean
}

const Context = createContext<Context>({ isOpen: true })

const getAnimation = ({
	showSticker,
	isMobile,
	isTablet,
}: {
	showSticker: boolean
	isMobile: boolean
	isTablet: boolean
}): MotionProps => ({
	layout: true,
	exit: 'collapsed',
	initial: 'collapsed',
	transition: { ease: 'easeInOut', duration: 0.4 },
	style: {
		width: '324px',
		// 56px is the height of a page header
		height: `calc(100vh - ${showSticker ? HEADER_HEIGHT_WITH_STICKER : HEADER_HEIGHT}px)`,
		position: 'relative',
	},
	variants: {
		open: { marginLeft: isMobile ? -12 : -28 }, // to compensate draft tile bg and align right Main padding
		collapsed: { marginLeft: isTablet ? -358 : isMobile ? -346 : -352 },
	},
})

const animationContent: MotionProps = {
	layout: true,
	exit: 'collapsed',
	initial: 'collapsed',
	transition: { ease: 'easeInOut', duration: 0.4 },
	style: {
		width: '340px',
		height: '100%',
		paddingBottom: '20px',
		position: 'relative',
	},
	variants: {
		open: { opacity: 1 },
		collapsed: { opacity: 0 },
	},
}

const Container = ({
	children,
	isOpen,
	isExpandable,
}: {
	isOpen: boolean
	isExpandable: boolean
	children: React.ReactNode | React.ReactNode[]
}) => {
	const MEDIA = useCurrentMedia()
	const { topSticker } = useShowTopSticker()

	return (
		<Context.Provider value={{ isOpen }}>
			<AnimatePresence initial={false}>
				<motion.div
					{...getAnimation({
						showSticker: topSticker.isVisible,
						isMobile: MEDIA === 'MOBILE',
						isTablet: MEDIA === 'TABLET',
					})}
					animate={isOpen ? 'open' : 'collapsed'}
				>
					{/*
						it should be blue_20 and browser shows the same color in dev panel
						but in fact it looks different from other borders so here we must set it manually to a close enough color
					*/}
					<Box h="100%" py={3} css={{ borderRight: isExpandable ? '1px solid #f1f4f9' : '' }}>
						{children}
					</Box>
				</motion.div>
			</AnimatePresence>
		</Context.Provider>
	)
}

const Content = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
	const { isOpen } = useContext(Context)
	const [isAnimationEnded, setIsAnimationEnded] = useState<boolean>()
	return (
		<AnimatePresence initial={false}>
			<motion.div
				{...animationContent}
				animate={isOpen ? 'open' : 'collapsed'}
				onAnimationStart={() => setIsAnimationEnded(false)}
				onAnimationComplete={() => setIsAnimationEnded(true)}
			>
				<Box
					mt="9px"
					h="100%"
					w="calc(100% - 24px)"
					visibility={!isOpen && isAnimationEnded ? 'hidden' : 'visible'}
				>
					{children}
				</Box>
			</motion.div>
		</AnimatePresence>
	)
}

const Scrollable = ({ children }: { children: (scroller: Scrollbar) => React.ReactNode | React.ReactNode[] }) => (
	<Scrollbars getScroller position="relative" trackYStyles={{ left: '-20px' }}>
		{scroller => (
			<Box ml={1} h="100%" w="calc(100% - 16px)">
				{children(scroller)}
			</Box>
		)}
	</Scrollbars>
)

export const Panel = Object.assign(Container, {
	Content,
	Scrollable,
})
