import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Trans, useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { RefreshCcw } from 'lucide-react'
import * as Icons from 'react-feather'

import { notReachable } from '@prostpost/utils'
import { VStack, Text, Paragraph, Image, TextLink, Button, HStack } from '@prostpost/uikit'

import { useChannelsStore } from 'app/domains/Channel/store'

import { Images } from 'app/resources'

type Msg = { type: 'on_open_editor'; channelName: string }
export type PropsMsg = {
	onMsg: (msg: Msg) => void
}

export type Props = PropsMsg & {
	isEditorOpen: boolean
	historyStatus: 'LOADED' | 'NOT_LOADED'
}

export const NoPostsPlaceholderMultiChannel = observer(({ onMsg }: PropsMsg) => {
	const theme = useTheme()
	const { t } = useTranslation()

	const channels = useChannelsStore()
	const onCreatePostClick = () =>
		runInAction(() => onMsg({ type: 'on_open_editor', channelName: channels.activeList[0].name }))

	return (
		<VStack space={5} py={8} align="center" just="center" w="100%">
			<VStack align="center" just="center" space={1} w="100%">
				<Text size={16}>
					{t('feed.posts.noScheduledPostsTitle.noPosts', "You've no scheduled posts for these dates")}
				</Text>

				{channels.activeList.length ? (
					<Paragraph centered size={14} color="blue_40">
						<Trans i18nKey="feed.posts.noScheduledPostsDescription.noPosts">
							{'Use '}
							<TextLink variant="plain" onClick={onCreatePostClick}>
								{'Editor'}
							</TextLink>
							{' to create and schedule a post'}
						</Trans>
					</Paragraph>
				) : (
					<Paragraph centered size={14} color="blue_40">
						{t('feed.posts.noChannelToScheduledPosts', 'Add your first Telegram channel to create posts')}
					</Paragraph>
				)}
			</VStack>
			<Image
				width="200px"
				loaderSize={['96px', '200px']}
				src={Images.NoChannelsPlaceholder}
				alt={t('dashboard.channels.noChannelsAlt', 'No channels')}
			/>
			{channels.activeList.length ? (
				<Button.Action
					size="mid"
					variant="primary"
					icon={<Icons.Edit3 size={18} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={onCreatePostClick}
				>
					{t('feed.posts.createFirstPost.label', 'New post')}
				</Button.Action>
			) : null}
		</VStack>
	)
})

export const NoPostsPlaceholderSingleChannel = ({ isEditorOpen, historyStatus, onMsg }: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()

	const channels = useChannelsStore()
	const onCreatePostClick = () =>
		!isEditorOpen && runInAction(() => onMsg({ type: 'on_open_editor', channelName: channels.activeList[0].name }))

	return (
		<VStack space={5} py={8} align="center" just="center" w="100%">
			{(() => {
				switch (historyStatus) {
					case 'LOADED':
						return (
							<VStack align="center" just="center" space={1} w="100%">
								<Text size={16}>
									{t('feed.posts.noScheduledPostsTitle.editor', 'Your channel has no posts yet')}
								</Text>
								<Paragraph centered size={14} color="blue_40">
									{isEditorOpen ? (
										<Trans i18nKey="feed.posts.noPostsDescription.editor">
											{'Create your first post using an Editor on the left'}
										</Trans>
									) : (
										<Trans i18nKey="feed.posts.noScheduledPostsDescription.noPosts">
											{'Use '}
											<TextLink variant="plain" onClick={onCreatePostClick}>
												{'Editor'}
											</TextLink>
											{' to create and schedule a post'}
										</Trans>
									)}
								</Paragraph>
							</VStack>
						)
					case 'NOT_LOADED':
						return (
							<VStack align="center" just="center" space={1} w="100%">
								<HStack space={2} align="center">
									<RefreshCcw size={16} strokeWidth={2.5} color={theme.colors.black_80} />
									<Text size={16}>
										{t('feed.posts.noSyncedPostsTitle.editor', 'Syncing posts from Telegram...')}
									</Text>
								</HStack>
								<Paragraph centered size={14} color="blue_40">
									{t(
										'feed.posts.noSyncedPostsDesc.editor',
										'It may take some time, your posts will appear here soon',
									)}
								</Paragraph>
							</VStack>
						)
					default:
						return notReachable(historyStatus)
				}
			})()}
			<Image
				width="200px"
				loaderSize={['96px', '200px']}
				src={Images.NoChannelsPlaceholder}
				alt={t('dashboard.channels.noChannelsAlt', 'No channels')}
			/>
			{!isEditorOpen ? (
				<Button.Action
					size="mid"
					variant="primary"
					icon={<Icons.Edit3 size={18} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={onCreatePostClick}
				>
					{t('feed.posts.createFirstPost.label', 'New post')}
				</Button.Action>
			) : null}
		</VStack>
	)
}
