import { createContext, useContext } from 'react'

import type { StoreTemplates } from './store'

export const TemplatesStoreContext = createContext<StoreTemplates | undefined>(undefined)

export const useTemplatesStore = (): StoreTemplates => {
	const store = useContext(TemplatesStoreContext)
	if (store === undefined) {
		throw Error('useTemplatesStore can be used only within TemplatesStoreContext')
	}
	return store
}
