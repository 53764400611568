import { useTranslation } from 'react-i18next'

import type { StoreUserPreferences } from 'app/domains/UserPreferences/store'

import { useLanguages } from './useLanguages'

const DEFAULT_LANG = 'en'

export const useUserLanguage = (preferences?: StoreUserPreferences) => {
	const { i18n } = useTranslation()
	const languages = useLanguages()
	return languages.filter(language => language.value === preferences?.lang || i18n.language || DEFAULT_LANG)[0]
}
