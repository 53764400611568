import { notReachable } from '@prostpost/utils'

import { UnlinkBotFromUserModal } from './Modal'
import type { Msg as MsgModal, Interface as InterfaceModal } from './Modal'

type Props = { variant: 'modal' } & InterfaceModal & { onMsg: (msg: MsgModal) => void }

export const UnlinkBotFromUserVariants = (props: Props) => {
	switch (props.variant) {
		case 'modal':
			return <UnlinkBotFromUserModal {...props} />
		default:
			return notReachable(props.variant)
	}
}
