import type { Currencies, Currency } from '.'

export const currencies: { [key in Currencies]: Currency } = {
	EUR: {
		sign: '€',
		name: 'Euro',
	},
	USD: {
		sign: '$',
		name: 'Dollar',
	},
}
