import { useMutation } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseMutationOptions } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { DraftScheduled } from '..'

import { DraftScheduledSchema } from './schemas'

const knownErrors = [
	'UNABLE_TO_UPDATE_DRAFT',
	'UNABLE_TO_GET_DRAFT',
	'UNABLE_TO_SCHEDULE_DRAFT',
	'UNABLE_TO_SCHEDULE_DRAFT_IN_THE_PAST',
	'DRAFT_NOT_FOUND',
] as const

type Data = {
	uuid: string
	// these are properties we can update directly from post card
	// all other properties are updated over a backup (see useUpdateBackup)
	silent?: boolean
}

const updateScheduledDraft = ({ uuid, ...data }: Data): Promise<DraftScheduled> =>
	!uuid
		? Promise.reject({ message: 'No draft UUID is given to update a draft' })
		: axiosInstance
				.patch<JsonApiResponseSingle>(`/drafts/draft/${uuid}`, data)
				.catch((e: AxiosError<JsonApiErrorResponse>) => {
					throw parseFirstError(e, knownErrors)
				})
				.then(response => {
					const result = parseSingle(response.data)
					return DraftScheduledSchema.parse(result.data)
				})

export const useUpdateScheduledDraft = (
	options: Omit<
		UseMutationOptions<DraftScheduled, ParsedError<(typeof knownErrors)[number]>, Data>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		updateScheduledDraftMutation: useMutation<DraftScheduled, ParsedError<(typeof knownErrors)[number]>, Data>({
			mutationKey: 'draft',
			mutationFn: (data: Data) => updateScheduledDraft(data),
			...options,
		}),
	}
}
