import { makeLoggable } from 'mobx-log'
import { observable, action, makeObservable } from 'mobx'
import type { User as SupabaseUser } from '@supabase/supabase-js'
import type { Moment } from 'moment'

import { StoreUserPreferences } from 'app/domains/UserPreferences/store'
import type { UserPreferences } from 'app/domains/UserPreferences'

import type { Common as UserCommon } from '..'

type InitArgs = UserCommon & {
	supabaseUser: SupabaseUser
	preferences: Omit<UserPreferences, 'nextSubscriptionPeriod'> & {
		nextSubscriptionPeriod: string | Moment
	}
}

export class UserStore {
	declare uuid: string

	declare preferences: StoreUserPreferences
	declare supabaseUser: SupabaseUser
	declare email: string

	declare ava?: string
	declare firstName?: string
	declare lastName?: string
	declare fullName?: string

	init({ preferences, supabaseUser, ...user }: InitArgs) {
		this.uuid = user.uuid
		this.email = user.email
		this.supabaseUser = supabaseUser
		this.preferences = new StoreUserPreferences(preferences)

		this.ava = user.ava
		this.lastName = user.lastName
		this.firstName = user.firstName
		this.fullName = user.fullName
	}

	constructor(args: InitArgs) {
		this.init(args)

		makeObservable(this, {
			ava: observable,
			uuid: observable,
			lastName: observable,
			fullName: observable,
			firstName: observable,

			init: action,
			setCurrent: action,
		})

		makeLoggable(this)
	}

	// copied from init on purpose so init is triggered only on store initialization once
	// make sense and also not confusing during debugging
	setCurrent(args: InitArgs) {
		this.init(args)
	}
}
