import styled from '@emotion/styled'

export const ContextMenuBadge = styled.div`
	position: absolute;
	top: 6px;
	right: 6px;

	width: 8px;
	height: 8px;

	background: ${({ theme }) => theme.colors.red_100};
	border-radius: 8px;
`
