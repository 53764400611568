import { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'

import { useUserHasRole } from 'app/domains/User/hooks'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'

import { routesUrls } from 'app/routes/urls'

export const WithPrivateAccess = () => {
	const navigate = useNavigate()
	const user = useOnboardedUserStore()
	const hasSupportRole = useUserHasRole('SUPPORT')

	useEffect(() => {
		if (!hasSupportRole) {
			navigate(routesUrls.inbox)
		}
	}, [user])

	return <Outlet />
}
