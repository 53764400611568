import { Flex, VStack, Skeleton as UISkeleton } from '@prostpost/uikit'

export const Skeleton = () => (
	<Flex w="100%" h="70px" align="center">
		<UISkeleton width="120px" height={64} borderRadius="mid" />
		<VStack space={2} ml={2} w="100%">
			<UISkeleton height={16} width="70%" borderRadius="mid" />
			<UISkeleton height={40} width="100%" borderRadius="mid" />
		</VStack>
	</Flex>
)
