import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { HStack } from '@prostpost/uikit'
import { notReachable, datetime } from '@prostpost/utils'
import { useToast } from '@prostpost/toast'

import { useBotStore } from 'app/domains/Bot/store'
import { useSchedulePost } from 'app/domains/Post/api'
import { useIsFailedToPublish } from 'app/domains/Post/hooks'
import { CancelPostEditing } from 'app/domains/Post/features/CancelPostEditing'
import type { DraftScheduled, DraftNotScheduled } from 'app/domains/Draft'
import type { ChannelActive } from 'app/domains/Channel'
import type { PostInternal } from 'app/domains/Post'

import { Wrapper, Header, Overlay, AdTag, TextAndImages, ContextMenuBadge } from '../../components'

import { Time } from './Time'
import { ContextMenu } from './ContextMenu'
import { ToggleSilentMode } from './ToggleSilentMode'
import { usePublishNowClick } from './hooks'

type Msg =
	| { type: 'on_unschedule' }
	| { type: 'on_click_unschedule' }
	| { type: 'on_published_now'; post: PostInternal }
	| { type: 'on_click_publish_now' }
	| { type: 'on_publish_now_failed' }
	| { type: 'on_publish_now_canceled' }
	| { type: 'on_click_edit' }
	| { type: 'on_click_copy_as_draft'; draft: DraftNotScheduled }
	| { type: 'on_start_cleaning_backup' }
	| { type: 'on_backup_cleaned'; postUuid: string }
	| { type: 'on_set_post_loading'; isLoading: boolean }
	| { type: 'on_click_toggle_silent'; isSilent: boolean }
	| { type: 'on_toggle_silent_failed'; prevIsSilent: boolean }
	| { type: 'on_rescheduled'; publishAt: string }
	| { type: 'on_click_open_modal_editor'; draftUuid: string }
	| { type: 'on_click_load_unpublished_version'; backupUuid: string }

type Props = {
	post: DraftScheduled
	channel: ChannelActive
	timeFormat: '12h' | '24h'
	selection: 'editing' | 'none' | 'readonly'
	isLoading: boolean
	isDisabled: boolean
	isImageVisible: boolean
	channelsCount: number
	onMsg: (msg: Msg) => void
}

const useToasts = () => {
	const { t } = useTranslation()

	const scheduleFailed = useToast({
		type: 'error',
		text: t('notify:draft.reschedule.failure', 'Unable to reschedule draft'),
	})

	const scheduleSucceed = useToast({
		type: 'success',
		text: t('notify:draft.reschedule.success', 'Post was rescheduled'),
	})

	const scheduleEmptyData = useToast({
		type: 'error',
		text: t('notify:draft.reschedule.emptyDate', 'Time is not selected to schedule'),
	})

	return { scheduleFailed, scheduleSucceed, scheduleEmptyData }
}

export const Scheduled = observer(function PostPreview({
	post,
	channel,
	timeFormat,
	channelsCount,
	selection = 'none',
	isDisabled = false,
	isLoading = false,
	isImageVisible = true,
	onMsg,
}: Props) {
	const toasts = useToasts()
	const botStore = useBotStore()

	const isEditing = selection === 'editing'
	const isFailedToPublish = useIsFailedToPublish(post)
	const [isContextOpen, setIsContextOpen] = useState(false)
	const [isCancelEditingModalOpen, setIsCancelEditingModalOpen] = useState(false)

	// we should keep it on a post tile level rather than inside sliding popup or context menu
	// because these are async operations and when result is ready context menu is closed and unmounted
	// so no async callbacks will be called
	const { schedulePostMutation } = useSchedulePost()
	const onPublishNowClick = usePublishNowClick({ post, onMsg })

	useEffect(() => {
		switch (schedulePostMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'error': {
				console.error(schedulePostMutation.error)
				toasts.scheduleFailed.show()
				break
			}
			case 'success':
				if (!schedulePostMutation.variables?.publishAt) {
					console.error('Empty publishAt date on rescheduling')
					toasts.scheduleEmptyData.show()
				} else {
					const publishAt = schedulePostMutation.variables.publishAt
					onMsg({ type: 'on_rescheduled', publishAt })
					toasts.scheduleSucceed.show()
				}
				break
			default:
				notReachable(schedulePostMutation)
		}
	}, [schedulePostMutation.status])

	useEffect(() => {
		onMsg({ type: 'on_set_post_loading', isLoading: schedulePostMutation.isLoading })
	}, [schedulePostMutation.isLoading])

	return (
		<Wrapper
			isSelectable={!isDisabled || isEditing}
			isHover={isContextOpen}
			isFailedToPublish={isFailedToPublish || !botStore.link}
			isSelected={isEditing}
			onClick={() => {
				if (isEditing || isDisabled || isLoading) return
				runInAction(() => {
					onMsg({ type: 'on_click_open_modal_editor', draftUuid: post.uuid })
				})
			}}
		>
			{isLoading ? <Overlay isLoading /> : null}
			{isEditing || isDisabled ? <Overlay isLoading={false} /> : null}

			<Header channel={channel} channelsCount={channelsCount}>
				<Header.Time post={post} timeFormat={timeFormat}>
					{({ time, formattedTime }) => (
						<Time
							time={time}
							timeFormat={timeFormat}
							formattedTime={formattedTime}
							isPostPublishingFailed={isFailedToPublish || !botStore.link}
							onMsg={msg => {
								runInAction(() => {
									switch (msg.type) {
										case 'on_confirm_reschedule':
											schedulePostMutation.mutate({
												uuid: post.uuid,
												publishAt: datetime(msg.date, true, { to: 'utc' }),
											})
											break
										case 'on_open_reschedule_calendar':
											setIsContextOpen(true)
											break
										case 'on_close_reschedule_calendar':
											setIsContextOpen(false)
											break
										case 'on_click_publish_now':
											setIsContextOpen(false)
											onPublishNowClick()
											onMsg(msg)
											break
										default:
											notReachable(msg)
									}
								})
							}}
						/>
					)}
				</Header.Time>

				{isEditing || isDisabled || isLoading || !post.unpublishedVersion ? null : <ContextMenuBadge />}

				{isEditing ? (
					<CancelPostEditing.Trigger
						variant="inline"
						isPostPublishingFailed={isFailedToPublish || !botStore.link}
						onClick={() => setIsCancelEditingModalOpen(true)}
					/>
				) : (
					<>
						<HStack space={2} mr="auto" ml={2}>
							{post.ad ? <AdTag /> : null}
							{post.silent ? (
								<ToggleSilentMode
									variant="icon"
									post={post}
									isPostPublishingFailed={isFailedToPublish || !botStore.link}
									onMsg={msg => {
										switch (msg.type) {
											case 'on_click_toggle_silent':
											case 'on_toggle_silent_failed':
												onMsg(msg)
												break
											default:
												notReachable(msg)
										}
									}}
								/>
							) : null}
						</HStack>

						<ContextMenu
							post={post}
							isPostPublishingFailed={isFailedToPublish || !botStore.link}
							onMsg={msg => {
								switch (msg.type) {
									case 'on_click_edit':
									case 'on_unschedule':
									case 'on_published_now':
									case 'on_click_unschedule':
									case 'on_publish_now_failed':
									case 'on_publish_now_canceled':
									case 'on_click_toggle_silent':
									case 'on_toggle_silent_failed':
									case 'on_click_load_unpublished_version':
									case 'on_click_copy_as_draft':
										onMsg(msg)
										break
									case 'on_click_publish_now':
										onPublishNowClick()
										onMsg(msg)
										break
									case 'on_context_menu_toggle':
										setIsContextOpen(msg.isOpen)
										break
									case 'on_click_open_modal_editor':
										runInAction(() => {
											onMsg({ type: 'on_click_open_modal_editor', draftUuid: post.uuid })
										})
										break
									default:
										notReachable(msg)
								}
							}}
						/>
					</>
				)}
			</Header>

			<TextAndImages post={post} isImageVisible={isImageVisible} />

			<CancelPostEditing
				draftUuid={post.uuid}
				isOpen={isCancelEditingModalOpen}
				onMsg={msg => {
					switch (msg.type) {
						case 'on_backup_cleaned':
							onMsg(msg)
							break
						case 'on_start_cleaning_backup':
							setIsCancelEditingModalOpen(false)
							onMsg(msg)
							break
						case 'on_cancel_editing_modal_closed':
							setIsCancelEditingModalOpen(false)
							break
						default:
							notReachable(msg)
					}
				}}
			/>
		</Wrapper>
	)
})
