import { makeLoggable } from 'mobx-log'
import { observable, action, makeObservable } from 'mobx'

import type { Template, TemplateShortInfo } from '..'

type InitArgs = {
	templates: TemplateShortInfo[]
}

export class StoreTemplates {
	declare templates: TemplateShortInfo[]

	init({ templates }: InitArgs) {
		this.templates = templates
	}

	constructor(args: InitArgs) {
		this.init(args)

		makeObservable(this, {
			templates: observable,

			init: action,
			addNewTemplate: action,
			deleteTemplate: action,
			setTemplatesList: action,
		})

		makeLoggable(this)
	}

	setTemplatesList(templates: TemplateShortInfo[]): void {
		this.templates = templates
	}

	addNewTemplate(template: Template) {
		this.setTemplatesList([...this.templates, template])
	}

	deleteTemplate(uuid: string) {
		this.setTemplatesList(this.templates.filter(template => template.uuid !== uuid))
	}
}
