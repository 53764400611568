import { toJS, runInAction } from 'mobx'

import { useHasFeature } from 'app/domains/Feature/hooks'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { BETA_CHANNELS_LIMIT } from 'app/domains/Feature/constants'

type Return = {
	channelsLimitUsed: number
	channelsLimitTotal: number | null
	channelsLimitReached: boolean
}

export const useUserChannelsLimit = (): Return => {
	const channels = useChannelsStore()
	const user = useOnboardedUserStore()
	const isBetaFeatureEnabled = useHasFeature('BETA_ACCESS')

	const { channelsLimitUsed, channelsLimitTotal } = runInAction(() => {
		const channelsLimitUsed = channels.notArchivedList.length
		const channelsLimitTotal = user.preferences.limits.channels
		return toJS({ channelsLimitUsed, channelsLimitTotal })
	})

	const channelsLimitReached = isBetaFeatureEnabled
		? channelsLimitUsed >= BETA_CHANNELS_LIMIT
		: channelsLimitTotal && channelsLimitUsed >= channelsLimitTotal

	return {
		channelsLimitReached: !!channelsLimitReached,
		channelsLimitUsed,
		channelsLimitTotal: isBetaFeatureEnabled ? BETA_CHANNELS_LIMIT : channelsLimitTotal || 1,
	}
}
