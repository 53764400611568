import { notReachable } from '@prostpost/utils'

import { useUpdateDraft } from 'app/domains/Draft/api'
import { useUpdateBackup } from 'app/domains/Post/api'
import type { EditorStore } from 'app/domains/Draft/store'

type Args = {
	editorStore: EditorStore
}

// we use this hook to sync a specific draft before an action that requires a draft to be synced with the latest changes
// now it does only update so if draft has not been added to remote yet we can't sync it here
// TODO: Ideally we should handle not only "update" action here but "create" as well
export const useSyncContentByUuid = ({ editorStore }: Args) => {
	const { updateDraftMutation } = useUpdateDraft({
		onSuccess: data => {
			editorStore.contentSyncSuccess({ action: 'update', draft: data })
		},
		onError: (error, { uuid }) => {
			switch (error.code) {
				// it can happen that draft was removed but was kept in local storage
				// so just remove it from there if it can't be found on BE
				case 'DRAFT_NOT_FOUND':
					uuid && editorStore.removeFromOfflineStorage(uuid)
					break
				case 'NOT_VALID_ERROR':
				case 'UNKNOWN_ERROR':
				case 'UNABLE_TO_GET_DRAFT':
				case 'UNABLE_TO_UPDATE_DRAFT':
				case 'UNABLE_TO_SCHEDULE_DRAFT':
					break
				case 'UNABLE_TO_SCHEDULE_DRAFT_IN_THE_PAST':
					break
				default:
					notReachable(error.code)
			}
		},
	})

	const { updatePostBackupMutation } = useUpdateBackup({
		onSuccess: data => {
			editorStore.contentSyncSuccess({ action: 'update', draft: data })
		},
		onError: (error, { uuid }) => {
			switch (error.code) {
				// it can happen that draft was removed but was kept in local storage
				// so just remove it from there if it can't be found on BE
				case 'DRAFT_BACKUP_NOT_FOUND':
					uuid && editorStore.removeFromOfflineStorage(uuid)
					break
				case 'UNKNOWN_ERROR':
				case 'DRAFT_NOT_FOUND':
				case 'NOT_VALID_ERROR':
				case 'UNABLE_TO_GET_DRAFT_BACKUP':
					break
				default:
					notReachable(error.code)
			}
		},
	})

	const syncContentToRemoteFromOfflineStorage = (uuid: string) => {
		return editorStore
			.syncContentToRemoteFromOfflineStorageByUuid(
				uuid,
				({ content, isSyncedWithRemote, channelUuid, isBackup }) => {
					const mutate = isBackup ? updatePostBackupMutation.mutateAsync : updateDraftMutation.mutateAsync

					const draftContent = {
						...content,
						...(channelUuid !== undefined ? { channelUuid } : {}),
					}

					return isSyncedWithRemote
						? Promise.resolve({ uuid, channelUuid, ...draftContent })
						: mutate({ uuid, channelUuid, ...draftContent })
				},
			)
			.then(() => editorStore.setSyncedWithRemoteStatus(uuid, true))
	}

	return {
		syncContentToRemoteFromOfflineStorage,
		isLoading: updateDraftMutation.isLoading || updatePostBackupMutation.isLoading,
	}
}
