import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Button, Item, Group } from '@prostpost/uikit'

type Props = {
	onClick: () => void
}

export const ChangePasswordTrigger = ({ onClick }: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()

	return (
		<Group.Item>
			<Group.ItemContent>
				<Item variant="labeled">
					<Item.Title>{t('userSettings.security.passwordLabel', 'Password')}</Item.Title>
					<Item.Description>{'*********'}</Item.Description>
				</Item>
			</Group.ItemContent>
			<Group.ItemSide>
				<Button.Action
					size="mid"
					variant="secondary"
					icon={<Icons.KeyRound size={16} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={onClick}
				>
					{t('userSettings.security.passwordUpdateAction', 'Change password')}
				</Button.Action>
			</Group.ItemSide>
		</Group.Item>
	)
}
