import { useInfiniteQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseInfiniteQueryOptions } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseList, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseList, stringifyQueryParams } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { TemplateShortInfo } from '..'

import { TemplateListSchema } from './schemas'

const knownErrors = ['UNABLE_TO_GET_TEMPLATES'] as const

type Params = {
	limit: number
	channelUuid?: string
	showShared?: boolean
	pageParam: number
}

type Result = {
	data: TemplateShortInfo[]
	count: number
	page: number
}

const fetchDrafts = ({ limit = 25, channelUuid, showShared, pageParam }: Params): Promise<Result> => {
	return axiosInstance
		.get<JsonApiResponseList>(
			`/drafts/template?${stringifyQueryParams({
				show_shared: showShared,
				channel_uuid: channelUuid,
				page: { size: limit, number: pageParam },
				sort: ['updatedAt', 'createdAt'],
			})}`,
		)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseList(response.data)
			const meta = result.meta
			return {
				count: meta?.page?.items || 0,
				page: result.meta?.page?.number || 1,
				data: (result.data || []).map(ch => TemplateListSchema.parse(ch)),
			}
		})
}

export const useTemplatesList = (
	params: Params,
	options: Omit<
		UseInfiniteQueryOptions<Result, ParsedError<(typeof knownErrors)[number]>, Result>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		templatesQuery: useInfiniteQuery<Result, ParsedError<(typeof knownErrors)[number]>, Result>({
			queryKey: ['infinite_templates', params.channelUuid],
			queryFn: ({ pageParam }: { pageParam?: number }) =>
				fetchDrafts({ ...params, pageParam: pageParam || params.pageParam }),
			getNextPageParam: lastResult => (lastResult.data.length === 0 ? undefined : lastResult.page + 1),
			...options,
		}),
	}
}
