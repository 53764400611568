import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { notReachable } from '@prostpost/utils'
import { Box, Button, ContextMenu } from '@prostpost/uikit'

import { CancelEditingModal } from './ConfirmationModal'

type Msg =
	| { type: 'on_backup_cleaned'; postUuid: string }
	| { type: 'on_start_cleaning_backup' }
	| { type: 'on_cancel_editing_modal_closed' }

type Props = {
	isOpen: boolean
	draftUuid: string
	onMsg: (msg: Msg) => void
}

type TriggerProps = { onClick: () => void } & (
	| { variant: 'context-menu-item' }
	| { variant: 'inline'; isPostPublishingFailed: boolean }
)

const ButtonInlineNoBg = styled(Button.Inline)`
	&:hover {
		background: none;
	}
`

const Trigger = ({ onClick, ...props }: TriggerProps) => {
	const { t } = useTranslation()
	switch (props.variant) {
		case 'inline':
			return props.isPostPublishingFailed ? (
				<Box zIndex={1}>
					<ButtonInlineNoBg size="small" color="red_100" onClick={onClick}>
						{t('content:post.editing.cancelLabel', 'Cancel editing')}
					</ButtonInlineNoBg>
				</Box>
			) : (
				<Box zIndex={1}>
					<ButtonInlineNoBg size="small" color="blue_100" onClick={onClick}>
						{t('content:post.editing.cancelLabel', 'Cancel editing')}
					</ButtonInlineNoBg>
				</Box>
			)
		case 'context-menu-item':
			return (
				<ContextMenu.Item icon={Icons.X} onClick={onClick}>
					{t('editor.context.cancelEditing', 'Cancel editing')}
				</ContextMenu.Item>
			)
		default:
			return notReachable(props)
	}
}

const CancelPostEditingModal = ({ draftUuid, isOpen, onMsg }: Props) => (
	<CancelEditingModal
		isOpen={isOpen}
		draftUuid={draftUuid}
		onMsg={msg => {
			switch (msg.type) {
				case 'on_start_cleaning_backup':
				case 'on_cancel_editing_modal_closed':
					onMsg(msg)
					break
				case 'on_backup_cleaned':
					onMsg({ type: 'on_backup_cleaned', postUuid: draftUuid })
					break
				default:
					notReachable(msg)
			}
		}}
	/>
)

export const CancelPostEditing = Object.assign(CancelPostEditingModal, {
	Trigger,
})
