import { useTranslation } from 'react-i18next'

import { GroupList, HStack, Group } from '@prostpost/uikit'

import { UserAvaUploader } from 'app/domains/User/features/UserAvaUploader'
import { UserBasicInformation } from 'app/domains/User/features/UserBasicInformation'

import {
	DangerZone,
	ChangeEmailTrigger,
	LinkBotToUserTrigger,
	ChangePasswordTrigger,
	UnlinkBotFromUserTrigger,
} from './components'

type Msg =
	| { type: 'on_trigger_change_email' }
	| { type: 'on_trigger_change_password' }
	| { type: 'on_trigger_connect_bot' }
	| { type: 'on_trigger_disconnect_bot' }
	| { type: 'on_trigger_delete_account' }

type Props = {
	onMsg: (msg: Msg) => void
}

export const Account = ({ onMsg }: Props) => {
	const { t } = useTranslation()

	return (
		<HStack.Responsive space={7} w="100%" responsive={{ MOBILE: { rule: 'full-width', vSpace: 4 } }}>
			<UserAvaUploader />
			<GroupList variant="item" pl={2} pr={1}>
				<UserBasicInformation variant="plain" />
				<Group>
					<Group.Title>{t('userSettings.security.title', 'Security')}</Group.Title>
					<ChangeEmailTrigger onClick={() => onMsg({ type: 'on_trigger_change_email' })} />
					<ChangePasswordTrigger onClick={() => onMsg({ type: 'on_trigger_change_password' })} />
				</Group>
				<Group>
					<Group.Title>{t('userSettings.bot.title', 'Telegram')}</Group.Title>
					<LinkBotToUserTrigger onClick={() => onMsg({ type: 'on_trigger_connect_bot' })} />
					<UnlinkBotFromUserTrigger onClick={() => onMsg({ type: 'on_trigger_disconnect_bot' })} />
				</Group>
				<Group>
					<Group.Title>{t('userSettings.dangerZone.title', 'Danger zone')}</Group.Title>
					<DangerZone onClick={() => onMsg({ type: 'on_trigger_delete_account' })} />
				</Group>
			</GroupList>
		</HStack.Responsive>
	)
}
