import { useState, useEffect } from 'react'
import type { User } from '@supabase/supabase-js'

import { useSupabaseAuth } from 'app/domains/User/hooks'

// call it only once to get user data and set it to user store
// since supabase getUser() method makes a request to the server every time
export const useAuthUser = () => {
	const auth = useSupabaseAuth()

	const [isLoading, setIsLoading] = useState(false)
	const [user, setUser] = useState<User | null>(null)

	const fetchUser = async () => {
		try {
			setIsLoading(true)
			const { error, data } = await auth.getUser()
			if (!data.user && !error) {
				console.error(data)
				throw Error('User not found or not authenticated yet')
			}
			if (error) {
				console.error(error, data)
				throw Error('Unable to fetch user')
			}

			setUser(data.user)
			setIsLoading(false)
		} catch (error) {
			console.error(error)
			setIsLoading(false)
			throw Error('Unable to fetch user')
		}
	}

	useEffect(() => {
		void fetchUser()
	}, [auth])

	return { user, isUserLoading: isLoading }
}
