import { useTheme } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Button, Item, Group } from '@prostpost/uikit'

import { useBotStore } from 'app/domains/Bot/store'

type Props = {
	onClick: () => void
}

export const LinkBotToUserTrigger = observer(function LinkBotToUserTrigger({ onClick }: Props) {
	const theme = useTheme()
	const { t } = useTranslation()
	const bot = useBotStore()

	return bot.link ? (
		<Group.Item>
			<Group.ItemContent>
				<Item variant="labeled">
					<Item.Title>{t('botLink.accountConnected', 'Connected account')}</Item.Title>
					<Item.Description>
						{`${bot.link.name ?? ''} ${bot.link.lastName ?? ''}`.trim() ??
							bot.link.username ??
							t('botLink.unnamedAccount', 'Unnamed account')}
					</Item.Description>
				</Item>
			</Group.ItemContent>
			<Group.ItemSide>
				<Button.Action
					size="mid"
					variant="secondary"
					icon={<Icons.ChevronsLeftRightEllipsis size={16} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={onClick}
				>
					{t('botLink.reconnectAccount2', 'Reconnect account')}
				</Button.Action>
			</Group.ItemSide>
		</Group.Item>
	) : (
		<Group.Item>
			<Group.ItemContent>
				<Item variant="labeled">
					<Item.Title>
						{t('botLink.accountNotConnected', 'Bot is not connected to your personal Telegram account')}
					</Item.Title>
				</Item>
			</Group.ItemContent>
			<Group.ItemSide>
				<Button.Action
					size="mid"
					variant="primary"
					icon={<Icons.ChevronsLeftRightEllipsis size={16} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={onClick}
				>
					{t('botLink.connectAccount', 'Connect account')}
				</Button.Action>
			</Group.ItemSide>
		</Group.Item>
	)
})
