import styled from '@emotion/styled'

import { getBorderRadius } from '@prostpost/css'
import { Skeleton } from '@prostpost/uikit'

const OverlayContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	width: 100%;
	height: 100%;

	cursor: default;
	background: transparent;
	border-radius: ${getBorderRadius('small')};
`

type Props = {
	isLoading: boolean
}

export const Overlay = ({ isLoading }: Props) => {
	return (
		<OverlayContainer
			onClick={e => {
				e.stopPropagation()
				e.preventDefault()
			}}
		>
			{isLoading ? <Skeleton isAnimated width="120%" height="full" borderRadius="none" /> : null}
		</OverlayContainer>
	)
}
