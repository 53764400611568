import { VStack } from '@prostpost/uikit'

import { LinkBotToUserForm, LinkBotToUserInstruction } from './components'
import type { Msg as MsgComponents } from './components'

export type Msg = MsgComponents
type MsgProp = {
	onMsg: (msg: Msg) => void
}

export type Interface = Omit<React.ComponentProps<typeof LinkBotToUserForm>, 'onMsg'>

export const LinkBotToUserPlain = ({ size, submitButton, onMsg }: Interface & MsgProp) => {
	return (
		<VStack space={4}>
			<LinkBotToUserInstruction />
			<LinkBotToUserForm size={size} submitButton={submitButton} onMsg={onMsg} />
		</VStack>
	)
}
