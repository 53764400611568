import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import * as Icons from 'react-feather'

import { useToast } from '@prostpost/toast'
import { ContextMenu } from '@prostpost/uikit'

import { useUnpublishPost } from 'app/domains/Post/api'
import type { PostInternal } from 'app/domains/Post'

type Msg = { type: 'on_unpublished' } | { type: 'on_click_unpublish' } | { type: 'on_unpublish_failed' }

type Props = {
	post: PostInternal
	isDisabled: boolean
	onMsg: (msg: Msg) => void
}

const useToasts = () => {
	const { t } = useTranslation()

	const unpublishFailed = useToast({
		type: 'error',
		text: t('notify:post.unpublished.error', 'Failed to unpublish post'),
	})

	const unpublishSucceed = useToast({
		type: 'success',
		text: t('notify:post.unpublished.success', 'Post successfully unpublished'),
	})

	return { unpublishFailed, unpublishSucceed }
}

export const Unpublish = observer(function Unpublish({ post, isDisabled, onMsg }: Props) {
	const { t } = useTranslation()

	const toasts = useToasts()
	const { unpublishPostMutation } = useUnpublishPost({ uuid: post.uuid })

	return (
		<ContextMenu.Item
			variant={isDisabled ? 'disabled' : 'danger'}
			icon={Icons.CornerUpLeft}
			onClick={() => {
				// we must use mutateAsync and not useEffect for ContextMenu.Item actions
				// because ContextMenu will be closed and unmount so useEffect won't trigger
				unpublishPostMutation
					.mutateAsync()
					.then(() => {
						onMsg({ type: 'on_unpublished' })
						toasts.unpublishSucceed.show()
						return null
					})
					.catch(error => {
						console.error(error)
						onMsg({ type: 'on_unpublish_failed' })
						toasts.unpublishFailed.show()
					})
				onMsg({ type: 'on_click_unpublish' })
			}}
		>
			{t('labels.unpublish', 'Unpublish')}
		</ContextMenu.Item>
	)
})
