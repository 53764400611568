import tippy from 'tippy.js'
import { ReactRenderer } from '@tiptap/react'
import type { SuggestionOptions, SuggestionProps, SuggestionKeyDownProps } from '@tiptap/suggestion'
import type { Instance as TippyInstance } from 'tippy.js'

import { RecentTagsDropdown } from './RecentTagsDropdown'
import type { Props as RecentTagsDropdownProps } from './RecentTagsDropdown'

export const getHashtagSuggestions = (suggestions: string[]): Omit<SuggestionOptions<string>, 'editor'> => ({
	items: ({ query }: { query: string }) => {
		return suggestions.filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
	},

	render: () => {
		let component: ReactRenderer<unknown, RecentTagsDropdownProps>
		let popup: TippyInstance[]

		return {
			onStart: (props: SuggestionProps<string>) => {
				component = new ReactRenderer<unknown, RecentTagsDropdownProps>(RecentTagsDropdown, {
					props,
					editor: props.editor,
				})

				if (!props.clientRect || !component) {
					return
				}

				const clientRectResult = props.clientRect()
				const getReferenceClientRect = clientRectResult ? () => clientRectResult : null

				popup = tippy('body', {
					getReferenceClientRect,
					appendTo: () => document.body,
					content: component.element,
					showOnCreate: true,
					interactive: true,
					trigger: 'manual',
					placement: 'bottom-start',
				})
			},

			onUpdate(props: SuggestionProps<string>) {
				if (!component || !popup) return

				component.updateProps(props)

				if (!props.clientRect) {
					return
				}

				const clientRectResult = props.clientRect()
				const getReferenceClientRect = clientRectResult ? () => clientRectResult : null

				popup?.[0].setProps({ getReferenceClientRect })
			},

			onKeyDown(props: SuggestionKeyDownProps) {
				if (!component || !popup) return

				if (props.event.key === 'Escape') {
					popup[0].hide()
					return true
				}

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore

				return component.ref?.onKeyDown(props)
			},

			onExit() {
				if (!component || !popup) return

				popup[0].destroy()
				component.destroy()
			},
		}
	},
})
