import { toJS } from 'mobx'
import styled from '@emotion/styled'

import { isUrl } from '@prostpost/utils'
import { HStack, Box } from '@prostpost/uikit'

import { MEDIA_URL } from 'app/domains/Media/constants'

type Props = {
	images: string[]
}

const Image = styled.div<{
	image: string
	single: boolean
	more?: number
}>`
	position: relative;

	width: 48px;
	height: 48px;

	background: url(${({ image }) => image});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	border-radius: ${({ theme }) => theme.radii.small};
`

export const Gallery = ({ images = [] }: Props) => {
	const imageUrls = toJS(images).map(image => (isUrl(image) ? image : MEDIA_URL(image)))
	return (
		<HStack space={2} align="center" position="relative">
			<Box position="absolute" left="-10px" h="100%" w="2px" bg="black_80" />
			{imageUrls.length === 1 ? (
				<Image single image={imageUrls[0]} />
			) : (
				imageUrls
					.slice(0, 7)
					.map((image, index) => (
						<Image
							key={image}
							single={false}
							more={index === 6 && imageUrls.length > 6 ? imageUrls.length - 7 : 0}
							image={image}
						/>
					))
			)}
		</HStack>
	)
}
