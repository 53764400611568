import { runInAction } from 'mobx'
import { useRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'

import { useCurrentMedia } from '@prostpost/css'
import { ToastProvider } from '@prostpost/toast'
import { notReachable, useOpenWindow } from '@prostpost/utils'
import { Main, Skeleton } from '@prostpost/uikit'

import { config } from 'app/config'
import { useChannelAnalyticsEvents } from 'app/config/analytics/events'

import { ErrorWidget } from 'app/domains/Error/components'
import { PostsList } from 'app/domains/Post/features/PostsList'
import { useChannelsStore, CurrentActiveChannelContext } from 'app/domains/Channel/store'
import { useReloadChannel, useChannelFromUrlExistsAndActive } from 'app/domains/Channel/hooks'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useUpdateUserPreferences, useUserTimeFormat } from 'app/domains/UserPreferences/hooks'
import { usePredefinedEditor, useResetEditorState } from 'app/domains/Draft/hooks'
import { getHashtagSuggestions } from 'app/domains/Draft/features/DraftEditor/extensions'
import { StoreTemplates, TemplatesStoreContext } from 'app/domains/Template/store'
import { usePostsStore } from 'app/domains/Post/store'
import type { ChannelRecentTags } from 'app/domains/Channel'

import { UserSettings } from 'app/modals/UserSettings'
import { TopSticker } from 'app/shared/components'
import { useShowTopSticker } from 'app/shared/hooks'
import { HEADER_HEIGHT, HEADER_HEIGHT_WITH_STICKER } from 'app/shared/constants'

import { routesUrls } from 'app/routes/urls'
import { Loadings } from 'app/routes/Loadings'

import { WithAside, WithSidebar } from '../../Wrappers'
import { useFloatingAside } from '../../Wrappers/WithAside/hooks'

import { usePostStateContext, useIsEditorRouteOpen, useDefineEditorStore } from './hooks'
import { ChannelScreenHeader } from './components'
import type { PostState } from './context'

type Props = {
	recentTags: ChannelRecentTags
}

export const WithChannel = observer(function WithChannel({ recentTags = [] }: Props) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const openNewWindow = useOpenWindow()
	const location = useLocation() as Omit<ReturnType<typeof useLocation>, 'state'> & { state: PostState }

	// Aside
	const MEDIA = useCurrentMedia()
	const hasFloatingAside = MEDIA === 'MOBILE' || MEDIA === 'TABLET'
	const { topSticker } = useShowTopSticker()
	const [isVisibleFloatingAside, onToggleFloatingAside] = useFloatingAside()

	// hide sidebar by default if resolution changed
	const [isVisibleFloatingDrafts, setVisibleFloatingDrafts] = useState(false)
	const onToggleFloatingDrafts = () => setVisibleFloatingDrafts(p => !p)
	useEffect(() => {
		setVisibleFloatingDrafts(false)
	}, [hasFloatingAside])

	// User
	const timeFormat = useUserTimeFormat()
	const userStore = useOnboardedUserStore()
	const { mutate: mutatePreferences } = useUpdateUserPreferences()

	// UI state
	const [search, setSearch] = useState('')
	const [isUserSettingsOpen, setIsUserSettingsOpen] = useState(false)
	const isFeedOpen = userStore.preferences.more.feedIsOpen
	const isDraftsOpen = userStore.preferences.more.sidebarIsOpen

	// Channel
	const channels = useChannelsStore()
	const channelName = useChannelFromUrlExistsAndActive()
	const { activeChannelQuery } = useReloadChannel(channelName)

	// Analytics
	const track = useChannelAnalyticsEvents(channelName || '')

	// Posts
	const postsStore = usePostsStore()
	const templatesStore = useRef(new StoreTemplates({ templates: [] }))

	// Editor
	const isEditorOpen = useIsEditorRouteOpen()
	const editorStore = useDefineEditorStore()
	const editor = usePredefinedEditor({
		content: '',
		recentTags: getHashtagSuggestions(recentTags.map(({ tag }) => tag.replace('#', ''))),
	})
	const resetEditorState = useResetEditorState(editor)

	// Keep state of a post to display for PostList
	const { postState, outletContext, clearPostState, setPostState, setOutletContext } = usePostStateContext(
		editorStore,
		editor,
	)

	// 6. If channel not valid/active - redirect to Dashboard
	if (!channelName) {
		return <Navigate to={routesUrls.inbox} />
	}

	// 7. Display channel route after loading
	if (channels.current) {
		const channel = channels.current
		return (
			<TemplatesStoreContext.Provider value={templatesStore.current}>
				<WithSidebar>
					<CurrentActiveChannelContext.Provider value={channel}>
						<Main.WithHeader>
							<Main.Header
								headerHeight={topSticker.isVisible ? HEADER_HEIGHT_WITH_STICKER : HEADER_HEIGHT}
							>
								<TopSticker />
								<ChannelScreenHeader
									postState={postState}
									isFeedOpen={hasFloatingAside ? isVisibleFloatingAside : isFeedOpen}
									isDraftsOpen={hasFloatingAside ? isVisibleFloatingDrafts : isDraftsOpen}
									isEditorOpen={isEditorOpen}
									channel={channel}
									editorStore={editorStore}
									resetEditorState={resetEditorState}
									onMsg={msg => {
										runInAction(() => {
											switch (msg.type) {
												case 'on_toggle_feed':
													if (hasFloatingAside) {
														onToggleFloatingAside('toggle')
														// floating Aside is always closed by default
														// don't save it's state to user preferences
														return
													}

													// update before request is made so we have smooth UI
													// we don't care if our request failed in this case
													userStore.preferences.setPreferences({
														...userStore.preferences,
														more: {
															...userStore.preferences.more,
															feedIsOpen: !isFeedOpen,
														},
													})
													mutatePreferences({ more: { feedIsOpen: !isFeedOpen } })
													break
												case 'on_click_new_draft':
													track.clicks.newDraft()
													editorStore.resetContent()
													break
												case 'on_drafts_search_changed':
													setSearch(msg.search || '')
													break
												case 'on_toggle_drafts':
													hasFloatingAside && onToggleFloatingDrafts()
													break
												case 'on_click_inbox':
													navigate(routesUrls.inbox)
													break
												case 'on_click_user_settings':
													setIsUserSettingsOpen(true)
													break
												case 'on_click_channel_overview':
													navigate(routesUrls.channel.getOverviewRoute(channel))
													break
												case 'on_click_channel_settings':
													navigate(routesUrls.channel.getSettingsRoute(channel))
													break
												case 'on_click_channel_editor':
													navigate(routesUrls.channel.getEditorRoute(channel.name))
													break
												default:
													notReachable(msg)
											}
										})
									}}
								/>
							</Main.Header>

							<Main>
								<Main.ContentScrollable maxW={isEditorOpen ? 0 : 1200}>
									<Outlet
										context={{
											...outletContext,
											isVisibleFloatingDrafts,
											draftsSearchText: search,
										}}
									/>
									<UserSettings
										currentTab="plan"
										isOpen={isUserSettingsOpen}
										onClose={() => setIsUserSettingsOpen(false)}
									/>
								</Main.ContentScrollable>

								<WithAside
									withScroller={false}
									isOpen={isFeedOpen}
									isOpenFloating={isVisibleFloatingAside}
									onToggleFloating={onToggleFloatingAside}
								>
									<PostsList
										variant="with-channel"
										postState={postState}
										timeFormat={timeFormat}
										isEditorOpen={isEditorOpen}
										channel={channel}
										onMsg={msg => {
											runInAction(() => {
												switch (msg.type) {
													case 'on_post_removed_from_feed':
														postsStore.removePublishedPost(msg.post.uuid)
														editorStore.addNotScheduledDraft(msg.post)
														break
													case 'on_post_preparing_for_editing': {
														onToggleFloatingAside('hide')

														// clear post state if it contains a command to start editing flow
														if (location.state?.state === 'edit') {
															clearPostState()
															navigate(location.pathname, {
																state: undefined,
																replace: true,
															})
														}

														// reset route state
														editorStore.resetContent()
														editorStore.setDraftLoadingStatus('LOADING')
														const postChannel = channels.activeList.find(
															ch => ch.uuid === msg.channelUuid,
														)

														if (!isEditorOpen && postChannel) {
															navigate(
																routesUrls.channel.getEditorRoute(postChannel.name),
															)
														}
														break
													}
													case 'on_post_backup_ready_to_edit':
														setPostState({
															state: 'edit-existing-backup',
															postType: msg.backup.type,
															postUuid: msg.backup.draftUuid,
															channelUuid: msg.backup.channelUuid,
															backupUuid: msg.backup.uuid,
														})

														// pass text via Outline context to set to Editor component
														// check Editor route to see how it works
														setOutletContext({
															uuid: msg.backup.draftUuid,
															content: msg.backup.text || '',
															postType: msg.backup.type,
														})

														editorStore.setContentFromDraft(msg.backup)
														editorStore.setDraftLoadingStatus('LOADED')
														break
													case 'on_start_cleaning_backup':
														editorStore.setDraftLoadingStatus('LOADING')
														break
													case 'on_backup_cleaned':
														editorStore.resetContent()
														setOutletContext(undefined)
														editorStore.setDraftLoadingStatus('LOADED')
														break
													case 'on_open_editor':
														navigate(routesUrls.channel.getEditorRoute(msg.channelName))
														break
													case 'on_click_open_in_telegram':
														openNewWindow(
															`${config.constants.TELEGRAM_MESSAGE_BASE_URL}/${channel.name}/${msg.post.tgId}`,
														)
														break
													case 'on_click_copy_as_draft':
														editorStore.addNotScheduledDraft(msg.draft)
														break
													default:
														notReachable(msg)
												}
											})
										}}
									/>
								</WithAside>
								<ToastProvider />
							</Main>
						</Main.WithHeader>
					</CurrentActiveChannelContext.Provider>
				</WithSidebar>
			</TemplatesStoreContext.Provider>
		)
	}

	// 8. Display channel request statuses
	switch (activeChannelQuery.status) {
		case 'idle':
		case 'loading':
		case 'success':
			return (
				<WithSidebar>
					<Main.WithHeader>
						<Main.Header headerHeight={topSticker.isVisible ? HEADER_HEIGHT_WITH_STICKER : HEADER_HEIGHT}>
							<ChannelScreenHeader.Skeleton />
						</Main.Header>
						<Main>
							<Main.Content maxW={isEditorOpen ? 0 : 1200}>
								<Loadings.AlmostThere />
							</Main.Content>
							<WithAside
								withScroller={false}
								isOpenFloating={isVisibleFloatingAside}
								onToggleFloating={onToggleFloatingAside}
								isOpen={!!isFeedOpen}
							>
								<Skeleton.List variant="loading" />
							</WithAside>
							<ToastProvider />
						</Main>
					</Main.WithHeader>
				</WithSidebar>
			)
		case 'error':
			return (
				<WithSidebar>
					<Main>
						<Main.Content maxW={isEditorOpen ? 0 : 1200}>
							<ErrorWidget
								isFullScreen
								size="big"
								error={activeChannelQuery.error}
								onTryAgain={() => window.location.reload()}
							>
								<ErrorWidget.Action onClick={() => navigate(routesUrls.inbox)}>
									{t('actions.goToInbox', 'Go to Inbox')}
								</ErrorWidget.Action>
							</ErrorWidget>
						</Main.Content>
						<WithAside
							withScroller={false}
							isOpenFloating={isVisibleFloatingAside}
							onToggleFloating={onToggleFloatingAside}
							isOpen={!!isFeedOpen}
						/>
						<ToastProvider />
					</Main>
				</WithSidebar>
			)
		default:
			return notReachable(activeChannelQuery)
	}
})
